import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { batchItem, batchDefaultState, batchPage } from "../interfaces/BatchInterface";
import { AppState } from "../store";

const initialState: batchDefaultState = {
  batchList: [],
  totalRecords: 0,
  batchDetails: {}
};

export const batchReducer = createSlice({
  name: "driverState",
  initialState,
  reducers: {
    setBatchListData: ( state, action: PayloadAction<batchPage> ) => ({
      ...state,
      batchList: action.payload.rows,
      totalRecords: action.payload.count
    }),
    setBatchDetailsData: ( state, action: PayloadAction<batchItem> ) => ({
      ...state,
      batchDetails: action.payload
    }),
    updateBatchStatus:( state, action: PayloadAction<batchItem> ) => ({
      ...state, 
      batchList: state.batchList.map(item=> item.id === action.payload.id ? 
        { ...item, status: action.payload.status} : item
    ) 
    }),
    deleteBatch:( state, action: PayloadAction<batchItem> ) => ({
      ...state, 
      batchList: state.batchList.filter(item => item.id != action.payload.id) 
    })
  },
});

export const { setBatchListData, updateBatchStatus, deleteBatch, setBatchDetailsData } = batchReducer.actions;

export const getbatchListData = (state: AppState) => {
  return({rows: state.batchReducer.batchList, count: state.batchReducer.totalRecords})
};

export const getDriverDetailsData = (state: AppState) => state.batchReducer.batchDetails

export { initialState as driverState };
export default batchReducer;
