import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import Status from '../../../common/components/Status';
import { Link } from 'react-router-dom'
import { DateTime } from '../../../common/components/Date';
import DataTable from 'react-data-table-component';
import { LoaderBullet } from '../../../common/components/Loader';
import { toast } from 'react-toastify';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { deleteType, updateStatus } from '../../../redux/fetchService/Status.service';
import { deleteDriverAction, DriverListAction, updateDriverStatusAction } from '../../../redux/actions/driver.actions';
import { getDriverListData } from '../../../redux/reducers/driver.reducer';
import { driverItem } from '../../../redux/interfaces/DriverInterface';
import swal from "sweetalert";
import { CSVLink } from 'react-csv';

function DriverList() {

    const dispatch = useDispatch();
    const drivers = useSelector(getDriverListData);
    const breadCrums = [{ link: 'drivers', title: 'Drivers' }];
    const loader = useSelector(getLoading);
    const [driverData, setDriverData] = useState<driverItem[]>([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);

    const columns: any = [
        {
            name: 'S.No.',
            selector: (row: any, id: number) => (page - 1) * perPage + (id + 1),
        },
        {
            name: 'Name',
            selector: (row: any) =>
                <Link to={`details/${row.id}`}
                    state={{ driverInfo: row }}>{row.full_name}</Link>

        }, 
        {
            name: 'Mobile Number',
            selector: (row: any) => row.mobile_number
        },
        {
            name: 'Email',
            selector: (row: any) => row.email
        },
        {
            name: 'Status',
            selector: (row: any) => row.status,
            cell: (row: any) => <Status status={row.status} />
        },
        {
            name: 'Created At',
            selector: (row: any) => row.created_at,
            cell: (row: any) => <DateTime date={row.created_at} />
        },
        {
            name: 'Action',
            cell: (row: any) => {
                let id = row.id;
                return (
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            Action
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link
                                className="dropdown-item" to={`edit/${row.id}`}
                                state={{ driverInfo: row }}
                            >Edit</Link>
                            <Link
                                state={{ driverInfo: row }}
                                className="dropdown-item" to={`details/${row.id}`}
                            >Details</Link>
                            <a
                                className="dropdown-item"
                                onClick={() => DeleteDriver({ id: id, type: "1", delete_type: "1" })}
                            >Delete</a>
                            {
                                row.status === "1" ? <a href={void (0)} className="dropdown-item"
                                    onClick={() => UpdateStatus({ id: id, type: "1", status: "0" })}>Make Inactive</a> : <a href={void (0)} className="dropdown-item"
                                        onClick={() => UpdateStatus({ id: id, type: "1", status: "1" })}>Make Active</a>
                            }

                        </div>
                    </div>
                );
            },
        },
    ];


    useEffect(() => {
        setLoading(loader);
    }, [loader]);

    const fetchData = async (page = 1) => {
        let payload = {
            page: page,
            limit: perPage
        };
        dispatch(DriverListAction(payload));
    };

    const handlePageChange = (page: number) => {
        setPage(page);
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage);
        setPage(page);
        fetchData(page);
    };

    useEffect(() => {
        fetchData();
        return () => { };
    }, [dispatch, perPage]);

    useEffect(() => {
        setDriverData(drivers.rows);
        setTotalRows(drivers.count);
    }, [drivers]);

    /**
     * @description To change country status use this function
     * @param payload 
     * @returns 
     */

    const UpdateStatus = async (payload: {}) => {
        let check = await updateStatus(payload);
        if (check.success === true) {
            dispatch(updateDriverStatusAction(payload));
            toast('Great! Info updated successfully.');
            return;
        } else {
            toast('Oops! Failed to update info.');
        }
    }

    /**
     * @description To delete driver use this function
     * @param payload 
     */
    const DeleteRecord = async (payload: any) => {

        let check = await deleteType(payload);

        if (check) {
            dispatch(deleteDriverAction(payload));
            fetchData()
        } else {
            toast('Oops! Failed to delete, please try again.');
        }
    }
    const DeleteDriver = (payload: {}) => {
        swal("Are you sure?", "You want to delete driver!", "warning", {
            buttons: ["CANCEL", "DELETE"],
            dangerMode: true,
        }).then((okay) => {
            if (okay) {
                DeleteRecord(payload);
            }
        });
    };

    return (
        <div className="container_full tbcont1-main">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid mb-5">
                    <BreadCrum title={'Drivers'} page={breadCrums} />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CSVLink data={driverData} filename={'drivers.csv'}>Download CSV</CSVLink>
                        <div className='text-right mb-2'>
                            <Link className="btn btn-primary" to={'add'} >Add New Driver</Link>
                        </div>
                    </div>
                    <DataTable
                        title="Drivers"
                        columns={columns}
                        data={driverData}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={[10, 50, 100, 500]}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressComponent={<LoaderBullet />}
                    />
                </div>
            </div>
        </div>
    )
}

export default DriverList