import { deleteDriver, setDriverDetailsData, setDriverListData, updateDriverStatus } from "../reducers/driver.reducer";
import { getDriverDetailsApi, updateDriverApi } from "../fetchService/DriverManagement";
import { AppThunk } from "../store";
import { setLoading } from "../reducers/loader.reducer";
import { toast } from "react-toastify";
import { fetchBatchListData, postBatchData } from "../fetchService/Batch.service";
import { deleteTag, setTagListData, updateTagStatus } from "../reducers/tag.reducer";
import { fetchTagListData, postTagData, getTagDetailsApi } from "../fetchService/Tag.service";
import { fetchDispatchListData, postDispatchTagData } from "../fetchService/DispaychTag.service";
import { setDispatchTagListData, updateDispatchTagStatus } from "../reducers/dispatchTag.reducer";

export const DispatchTagListAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  const response: any = await fetchDispatchListData(payload);  
  dispatch(setLoading(false));
  if (response.success) {
    dispatch(setDispatchTagListData(response.data));
  }
};

export const setDispatchTagData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {
  const response: any = await postDispatchTagData(payload);
  toast(response.message);
  if (response && response.success) {   
    navigate('/dispatch-tags');
  }
};

/**
 * @description To update country status 
 * @param payload 
 * @returns 
 */
export const updateDispatchTagStatusAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(updateDispatchTagStatus(payload));
};

/**
 * @description To delete driver status 
 * @param payload 
 * @returns 
 */
export const deleteDispatchTagAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(deleteTag(payload));
};

export const updateDispatchTagData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  let formData = {
    full_name: payload.fullName.value,
    id: payload.id.value,
    email: payload.email.value,
    mobile_number: payload.mobile.value
  }

  const response: any = await  updateDriverApi(formData);
  if (response) {
    toast(response.message);
    navigate('/drivers');
  }
};

/**
 * 
 */

 export const TagDetailAction = (payload: any): AppThunk => async (dispatch: any) => {    
  const response: any = await getTagDetailsApi(payload);  
  dispatch(setDriverDetailsData(response.data));
};
