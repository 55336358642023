import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import Status from '../../../common/components/Status';
import { DateTime } from '../../../common/components/Date';
import DataTable from 'react-data-table-component';
import { LoaderBullet } from '../../../common/components/Loader';
import { toast } from 'react-toastify';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { updateStatus } from '../../../redux/fetchService/Status.service';
import { getTagListData } from '../../../redux/reducers/tag.reducer';
import { tagItem } from '../../../redux/interfaces/TagInterface';
import { TagListAction, updateTagStatusAction } from '../../../redux/actions/tag.action';
import { CSVLink } from "react-csv";

function TagList() {

  const breadCrums = [{ link: 'tags', title: 'Tags' }];

  const dispatch = useDispatch();
  const tags = useSelector(getTagListData);
  const loader = useSelector(getLoading);
  const [tagData, setTagData] = useState<tagItem[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
  ];
  
  const columns: any = [
    {
      name: '#',
      selector: (row: any, id: number) => id + 1,
    },
    {
      name: 'Tag Id',
      selector: (row: any) => row.id
    },    
    {
      name: 'Status',
      selector: (row: any) => row.status,
      cell: (row: any) => <Status status={row.status} />
    },
    {
      name: 'Created At',
      selector: (row: any) => row.createdAt,
      cell: (row: any) => <DateTime date={row.createdAt} />
    },
    {
      name: 'Action',
      cell: (row: any) => {
        let id = row.id;
        return (
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Action
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">            
              {
                row.status === "1" ? <a href={void (0)} className="dropdown-item"
                  onClick={() => UpdateStatus({ id: id, type: "10", status: "0" })}>Make Inactive</a> : <a href={void (0)} className="dropdown-item"
                    onClick={() => UpdateStatus({ id: id, type: "10", status: "1" })}>Make Active</a>
              }

            </div>
          </div>
        );
      },
    },
  ];


  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  const fetchData = async () => {
    let payload = {
      page: page,
      limit: perPage
    };
    dispatch(TagListAction(payload));
  };

  const handlePageChange = (pageNumber: number) => {    
    setPage(pageNumber);
    fetchData();
  };

  const handlePerRowsChange = async (newPerPage: number, pageNumber: number) => {
    setPerPage(newPerPage);
    setPage(pageNumber);
    fetchData();
  };

  useEffect(() => {
    fetchData();
    return () => { };
  }, [dispatch]);

  useEffect(() => {
    setTagData(tags.rows);
    setTotalRows(tags.count);
  }, [tags]);

  /**
   * @description To change country status use this function
   * @param payload 
   * @returns 
   */

  const UpdateStatus = async (payload: {}) => {
    let check = await updateStatus(payload);
    if (check.success === true) {
      dispatch(updateTagStatusAction(payload));
      toast('Great! Info updated successfully.');
      return;
    } else {
      toast('Oops! Failed to update info.');
    }
  }

  return (
    <div className="container_full tbcont1-main">
      <div className="side_bar scroll_auto">
        <SideBar />
      </div>
      <div className="content_wrapper">
        <div className="container-fluid mb-5">
          <BreadCrum title={'Tags'} page={breadCrums} />         
          <CSVLink data={tagData} filename={'tags.csv'}>Download CSV</CSVLink>
          <DataTable
            title="Tags"
            columns={columns}
            data={tagData}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={[10, 50, 100, 500]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<LoaderBullet />}
          />
        </div>
      </div>
    </div>
  )
}

export default TagList;