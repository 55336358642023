import { AppThunk } from "../store";
import { fetchZipcodeListApi, postZipcodeApi, UpdateZipcodeApi } from "../fetchService/ZipcodeManagement";
import { toast } from "react-toastify";
import { setZipcodeListData } from "../reducers/zipcode.reducer";

export const ZipcodeListAction = (payload: any): AppThunk => async (dispatch: any) => {
 
  const response: any = await fetchZipcodeListApi(payload);
  if (response.data) {  
    dispatch(setZipcodeListData(response.data));
  }
};


export const setZipcodeData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {
  
    const response: any = await postZipcodeApi(payload);
    toast(response.message);
    if (response) {
      navigate('/zipcodes');
    }
  };
  
  export const updateZipcodeData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

    const response: any = await UpdateZipcodeApi(payload);
    toast(response.message);
    if (response) {
      navigate('/zipcodes');
    }
  };

