import * as React from 'react';
import Moment from 'react-moment';
import Const from '../../helper/Const';
import moment from 'moment'

const Date = ({date}: {date: any}) => {
    return (
        <>
            {moment(date).format(Const.DATE)}        
        </>
    );
}

function DateTime({date}: {date: any}) {
    return (
        <>
            {moment(date).format(Const.DATETIME)}
        </>
    );
}

// const Date = ({date}: {date: any}) => {
//     return (
//         <Moment format={Const.DATE}>
//             {date}
//         </Moment>
//     );
// }

// const DateTime = ({date}: {date: any}) => {
//     return (
//         <Moment format={Const.DATETIME}>
//             {date}
//         </Moment>
//     );
// }

export { Date, DateTime };