import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import SideBar from "../../layout/sidebar/SideBar";
import BreadCrum from "../../layout";
import TextInput from "../../../common/components/TextInput";
import { OnChangeModel } from "../../../common/types/Form.types";
import { setCityData, setStateData, StateListAction, updateStateData } from "../../../redux/actions/state.actions";
import { CountryListAction } from "../../../redux/actions/country.actions";
import { getCountryListData } from "../../../redux/reducers/country.reducer";
import { countryItem } from "../../../redux/interfaces/CountryInterface";
import { getStateListData } from "../../../redux/reducers/State.reducer";
import { StateItem } from "../../../redux/interfaces/StateInterface";

const EditState = () => {
    
    const breadCrums = [{ link: 'states', title: 'States' }];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [stateName, setStateName] = useState("");
    const countries = useSelector(getCountryListData);
    const states = useSelector(getStateListData);

    const [countryData, setCountryData] = useState<countryItem[]>([]);
    const [countryId, setCountryId] = useState("");
    const [stateId, setStateId] = useState("");
    let location: any = useLocation();
    const { stateInfo } = location.state;

    console.log('stateInfo', stateInfo);
    const [formState, setFormState] = useState({
        stateName: { error: "", value: stateInfo.state_name },
    });


    useEffect(() => {
        setCountryId(stateInfo.country_id);
        setStateId(stateInfo.id);
        dispatch(CountryListAction());
        return () => { };
    }, [dispatch]);


    useEffect(() => {
        let data: any = [];
        countries.map((item: any) => {
            data.push(item);
        });
        setCountryData(data);
    }, [countries]);

    useEffect(() => {
        let payload = {
            country_id: countryId
        }
        dispatch(StateListAction(payload));
        return () => { };
    }, [dispatch]);     

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({
            ...formState,
            [model.field]: { error: model.error, value: model.value },
        });
        setStateName(formState.stateName.value);
    }
    const submit = async () => {
        const payload = {
            id: stateId,
            state_name: formState.stateName.value,
            country_id: countryId
        };

        dispatch(updateStateData(payload, navigate));
    };

    function isFormInvalid() {
        return formState.stateName.error || !formState.stateName.value;
    }

    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }

    const countryOptions = () => {
        return countryData.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.country_name}
                </option>
            );
        });
    };

    const handleSelect = (e: any) => {
        setCountryId(e.target.value);
    };

    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={"Edit State"} page={breadCrums} />
                    <div className="row">
                        <div className=" col-md-8 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">Edit State Info</div>
                                </div>
                                <div className="card-body">
                                    <form
                                        className="user"
                                        action="javascript:void(0)"
                                        onSubmit={submit}
                                    >
                                        <div className="form-group">
                                            <label htmlFor="countrySelect">Select Country</label>
                                            <select
                                                className="form-control"
                                                id="countrySelect"
                                                onClick={(e) => {
                                                    handleSelect(e);
                                                }}
                                                value={countryId}
                                            >
                                                <option value={''} key={''}>Select Country</option>
                                                {countryOptions()}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <TextInput
                                                id="input_state_name"
                                                field="stateName"
                                                value={formState.stateName.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="State Name"
                                                placeholder="State Name"
                                            />
                                        </div>
                                        <button
                                            className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditState;
