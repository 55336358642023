
import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";


export const fetchApartmentListData = async (payload: any) => {    
    return await ApiServices('get', payload, ApiEndPoints.ApartmentList);
};

export const postApartmentData = async (payload: any) => {    
    return await ApiServices('post', payload, ApiEndPoints.ApartmentAdd);    
};

export const updateApartmentApi = async (payload: any) => {
    return await ApiServices('put', payload, ApiEndPoints.ApartmentUpdate);    
};