import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import SideBar from "../../layout/sidebar/SideBar";
import BreadCrum from "../../layout";
import TextInput from "../../../common/components/TextInput";
import { OnChangeModel } from "../../../common/types/Form.types";
import { getAreaListData } from "../../../redux/reducers/area.reducer";
import { areaItem } from "../../../redux/interfaces/AreaInterface";
import { updateApartmentData } from "../../../redux/actions/apartment.actions";
import { AreaListAction } from "../../../redux/actions/area.action";


const EditApartment = () => {

    const breadCrums = [{ link: 'apartments', title: 'Apartments' }];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [instruction, setInstruction] = useState("");
    const areas = useSelector(getAreaListData);
    const [areaData, setAreaData] = useState<areaItem[]>([]);
    const [areaId, setAreaId] = useState("");
    let location: any = useLocation();
    const { apartmentInfo } = location.state;   
    console.log('apartmentInfo', apartmentInfo)
    const [formState, setFormState] = useState({
        name: { error: "", value: apartmentInfo.name },
        instruction: { error: "", value: apartmentInfo.instruction },
    });

    useEffect(() => {
        setAreaId(apartmentInfo.area.id);
    }, [])
    useEffect(() => {
        let payload = {
            page: 1
        }
        dispatch(AreaListAction(payload));
        return () => { };
    }, [dispatch]);


    useEffect(() => {
        let data: any = [];
        areas.map((item: any) => {
            data.push(item);
        });
        setAreaData(data);
    }, [areas]);


    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({
            ...formState,
            [model.field]: { error: model.error, value: model.value },
        });
        setName(formState.name.value);
        setInstruction(formState.instruction.value);
    }
    const submit = async () => {
        const payload = {
            id: apartmentInfo.id,
            name: formState.name.value,
            instruction: formState.instruction.value,
            area_id: areaId
        };
        
        dispatch(updateApartmentData(payload, navigate));
    };

    function isFormInvalid() {
        return formState.name.error || !formState.name.value || formState.instruction.error || !formState.instruction.value;
    }

    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }

    const areaOptions = () => {
        return areaData.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.area_name}
                </option>
            );
        });
    };

    const handleSelect = (e: any) => {
        setAreaId(e.target.value);
    };

    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={"Edit Apartment"} page={breadCrums} />

                    <div className="row">
                        <div className=" col-md-8 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">Edit Apartment Info</div>
                                </div>
                                <div className="card-body">
                                    <form
                                        className="user"
                                        action="javascript:void(0)"
                                        onSubmit={submit}
                                    >
                                        <div className="form-group">
                                            <label htmlFor="countrySelect">Select Area</label>
                                            <select
                                                className="form-control"
                                                id="countrySelect"
                                                onChange={(e) => {
                                                    handleSelect(e);
                                                }}
                                                value={apartmentInfo.area.id}
                                            >
                                                <option value={''} key={''}>Select Area</option>
                                                {areaOptions()}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <TextInput
                                                id="input_name"
                                                field="name"
                                                value={formState.name.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Name"
                                                placeholder="Name"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <TextInput
                                                id="input_instruction"
                                                field="instruction"
                                                value={formState.instruction.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Instruction"
                                                placeholder="Instruction"
                                            />
                                        </div>
                                        <button
                                            className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditApartment;
