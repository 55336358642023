
const APPConfig = {
  API_URL1: "http://54.210.58.131:3001/api",
  API_URL: "http://54.210.58.131:3002/api",  
  // API_URL1: "http://localhost:3001/api",
  // API_URL: "https://4cf2-2401-4900-1c6e-78ff-b5a5-131d-6f8e-7af9.ngrok-free.app/api",  

}
const ApiEndPoints = {
  Login: `${APPConfig.API_URL1}/login`,
  AreaList: `${APPConfig.API_URL}/area/list`,
  TagBatchList: `${APPConfig.API_URL}/tag-batch/list`,
  AddTagBatch: `${APPConfig.API_URL}/tag-batch/add`,
  TagBatchDetails: `${APPConfig.API_URL}/tag-batch/detail`,
  CityList: `${APPConfig.API_URL}/city/list`,
  CityAdd: `${APPConfig.API_URL}/city/add`,
  CountryList: `${APPConfig.API_URL}/country/list`,
  CountryAdd: `${APPConfig.API_URL}/country/add`,
  CountryUpdate: `${APPConfig.API_URL}/country/update`,
  CityUpdate: `${APPConfig.API_URL}/city/update`,
  DriverList: `${APPConfig.API_URL}/driver/list`,
  DriverAdd: `${APPConfig.API_URL}/driver/add`,
  DriverUpdate: `${APPConfig.API_URL}/driver/update`,
  DriverDetails: `${APPConfig.API_URL}/driver/detail`,
  LaundryList: `${APPConfig.API_URL}/laundry/list`,
  LaundryAdd: `${APPConfig.API_URL}/laundry/add`,
  LaundryUpdate: `${APPConfig.API_URL}/laundry/update`,
  StateList: `${APPConfig.API_URL}/state/list`,
  StateAdd: `${APPConfig.API_URL}/state/add`,
  StateUpdate: `${APPConfig.API_URL}/state/update`,
  StatusUpdate: `${APPConfig.API_URL}/status`,
  StatusDelete: `${APPConfig.API_URL}/delete`,
  StripeSubscriptionPlans: `${APPConfig.API_URL}/stripe/subscription-plans`,
  UserList: `${APPConfig.API_URL}/user/list`,
  UserAdd: `${APPConfig.API_URL}/user/add`,
  UserUpdate: `${APPConfig.API_URL}/user/update`,
  UserUpdate: `${APPConfig.API_URL}/user/update`,
  UserDetails: `${APPConfig.API_URL}/user/detail/`,
  AddZipcode: `${APPConfig.API_URL}/locality/add`,
  ListZipcode: `${APPConfig.API_URL}/locality/list`,
  UpdateZipcode: `${APPConfig.API_URL}/locality/update`,
  AddArea: `${APPConfig.API_URL}/area/add`,
  UpdateArea: `${APPConfig.API_URL}/area/update`,
  AreaDetails: `${APPConfig.API_URL}/area/detail`,
  InterestedZipcodeList: `${APPConfig.API_URL}/interested-zipcode/list`,
  ApartmentList: `${APPConfig.API_URL}/apartment/list`,
  ApartmentAdd: `${APPConfig.API_URL}/apartment/add`,
  ApartmentUpdate: `${APPConfig.API_URL}/apartment/update`,
  TagList: `${APPConfig.API_URL}/tag/list`,
  DispatchTagList: `${APPConfig.API_URL}/dispatchTag/list`,
  AddDispatchTag: `${APPConfig.API_URL}/dispatchTag/add`,
  changeDriverpassword: `${APPConfig.API_URL}/driver/updatepassword`
}

export default ApiEndPoints

