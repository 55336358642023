import { deleteDriver, setDriverDetailsData, setDriverListData, updateDriverStatus } from "../reducers/driver.reducer";
import { setAlert } from "../reducers/Alert.reducer";
import { fetchDriverListData, getDriverDetailsApi, postDriverData, updateDriverApi } from "../fetchService/DriverManagement";
import { AppThunk } from "../store";
import { setLoading } from "../reducers/loader.reducer";
import { toast } from "react-toastify";
import { fetchBatchListData, postBatchData } from "../fetchService/Batch.service";
import { setBatchListData } from "../reducers/batch.reducer";

export const BatchListAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  const response: any = await fetchBatchListData(payload);
  dispatch(setLoading(false));
  if (response.success) {
    dispatch(setBatchListData(response.data));
  }
};

export const setBatchData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  const response: any = await postBatchData(payload);

  toast(response.message);
  if (response && response.success) {   
    navigate('/batches');
  } 
};

/**
 * @description To update country status 
 * @param payload 
 * @returns 
 */
export const updateBatchStatusAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(updateDriverStatus(payload));
};

/**
 * @description To delete driver status 
 * @param payload 
 * @returns 
 */
export const deleteDriverAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(deleteDriver(payload));
};

export const updateDriverData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  let formData = {
    full_name: payload.fullName.value,
    id: payload.id.value,
    email: payload.email.value,
    mobile_number: payload.mobile.value
  }

  const response: any = await updateDriverApi(formData);
  if (response) {
    toast(response.message);
    navigate('/drivers');
  }
};

/**
 * 
 */

 export const DriverDetailAction = (payload: any): AppThunk => async (dispatch: any) => {    
  const response: any = await getDriverDetailsApi(payload);  
  dispatch(setDriverDetailsData(response.data));
};
