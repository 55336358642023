import { AppThunk } from "../store";
import { toast } from "react-toastify";
import { fetchInterestedZipcodeListApi } from "../fetchService/InterestedZipcode.service";
import { setInterestedZipcodeListData } from "../reducers/interestedZipcode.reducer";

export const InterestedZipcodeListAction = (payload: any): AppThunk => async (dispatch: any) => {
 
  const response: any = await fetchInterestedZipcodeListApi(payload);
  if (response.data) {  
    dispatch(setInterestedZipcodeListData(response.data));
  }
};


