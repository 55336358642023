import { AppThunk } from "../store";
import { setLoundryListData } from "../reducers/loundry.reducer";
import { fetchLoundryListData, postLoundryData, updateLoundryApi, } from "../fetchService/LoundryManagement";
import { setLoading } from "../reducers/loader.reducer";
import { toast } from "react-toastify";

export const LoundryListAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  const response: any = await fetchLoundryListData(payload);
  if (response.data) {
    dispatch(setLoundryListData(response.data));
  }
};


export const setLoundryData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  let formData = {
    full_name: payload.fullname.value,
    address: payload.address.value,
    email: payload.email.value,
    area: payload.area.value,  
    mobile_number: payload.mobile.value,
    password: "qwerty",//payload.password.value,
    latitude:  payload.latitude.value,
    longitude: payload.longitude.value,
  }


  const response: any = await postLoundryData(formData);
  toast(response.message);
  if (response) {
    navigate('/laundry');
  }
};

export const updateLoundryData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  let formData = {
    id: payload.id.value,
    full_name: payload.fullName.value,
    email: payload.email.value,
    address: payload.address.value,
    mobile_number: payload.mobile.value,
    area_id: payload.area.value
  }

  const response: any = await updateLoundryApi(formData);
  toast(response.message);
  if (response) {
    navigate('/laundry');
  }
};
