import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import SideBar from "../../layout/sidebar/SideBar";
import BreadCrum from "../../layout";
import TextInput from "../../../common/components/TextInput";
import { OnChangeModel } from "../../../common/types/Form.types";
import { StateListAction } from "../../../redux/actions/state.actions";
import { CountryListAction } from "../../../redux/actions/country.actions";
import { getCountryListData } from "../../../redux/reducers/country.reducer";
import { countryItem } from "../../../redux/interfaces/CountryInterface";
import { getStateListData } from "../../../redux/reducers/State.reducer";
import { getCityListData } from "../../../redux/reducers/city.reducer";
import { StateItem } from "../../../redux/interfaces/StateInterface";
import { cityItem } from "../../../redux/interfaces/CityInterface";
import { CityListAction } from "../../../redux/actions/city.actions";
import { updateZipcodeData } from "../../../redux/actions/zipcode.actions";


const Editzipcode = () => {

    const breadCrums = [{ link: 'zipcodes', title: 'Zipcodes' }];

    const dispatch = useDispatch();
    const navigate = useNavigate();    
    const [zipcodeId, setZipcodeId] = useState("");
    const [zipcode, setZipcode] = useState("");
    const countries = useSelector(getCountryListData);
    const states = useSelector(getStateListData);
    const cities = useSelector(getCityListData);    
    const [countryData, setCountryData] = useState<countryItem[]>([]);
    const [state, setState] = useState<StateItem[]>([]);
    const [city, setCity] = useState<cityItem[]>([]);
    const [countryId, setCountryId] = useState("");
    const [stateId, setStateId] = useState("");
    const [cityId, setCityId] = useState("");
    let location: any = useLocation();
    const { zipcodeInfo } = location.state;

    const [formState, setFormState] = useState({
        zipcode: { error: "", value: zipcodeInfo.locality_zipcode },
    });

    // For Edit
    useEffect(() => {
        setZipcodeId(zipcodeInfo.id);
        setCountryId(zipcodeInfo.city.state.country.id);
        setStateId(zipcodeInfo.city.state.id);
        setCityId(zipcodeInfo.city.id);
    }, [])

    useEffect(() => {        
        dispatch(CountryListAction());
        return () => { };
    }, [dispatch]);


    useEffect(() => {
        let data: any = [];
        countries.map((item: any) => {
            data.push(item);
        });
        setCountryData(data);
    }, [countries]);

    useEffect(() => {
        let data: any = [];
        console.log('states', states);
        states.map((item: any) => {
            data.push(item);
        });
        setState(data);
    }, [states, countryId]);

    useEffect(() => {
        let data: any = [];
        cities.map((item: any) => {
            data.push(item);
        });
        setCity(data);
    }, [cities, stateId]);

    useEffect(() => {
        let payload = {
            country_id: countryId
        }
        dispatch(StateListAction(payload));
        return () => { };
    }, [dispatch, countryId]);

    useEffect(() => {
        let payload = {
            state_id: stateId
        }
        dispatch(CityListAction(payload));
        return () => { };
    }, [dispatch, stateId]);

    useEffect(() => {
        let data: any = [];
        cities.map((item: any) => {
            data.push(item);
        });
        setCity(data);
    }, [cities, stateId]);

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({
            ...formState,
            [model.field]: { error: model.error, value: model.value },
        });
        setZipcode(formState.zipcode.value);
    }
    const submit = async () => {
        const payload = {
            id: zipcodeInfo.id,
            locality_zipcode: formState.zipcode.value,
            city_id: cityId,
            locality_name: "sssss"
        };
        
        dispatch(updateZipcodeData(payload, navigate));
    };

    function isFormInvalid() {
        return formState.zipcode.error || !formState.zipcode.value;
    }

    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }

    const countryOptions = () => {
        return countryData.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.country_name}
                </option>
            );
        });
    };

    const handleSelect = (e: any) => {
        setCountryId(e.target.value);
    };

    const handleSelectState = (e: any) => {
        setStateId(e.target.value);
    };

    const handleSelectCity = (e: any) => {
        setCityId(e.target.value);
    };

    const stateOptions = () => {
        return state.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.state_name}
                </option>
            );
        });
    };

    const cityOptions = () => {
        
        return city.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.city_name}
                </option>
            );
        });
    };

    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={"Edit zipcode"} page={breadCrums} />

                    <div className="row">
                        <div className=" col-md-8 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">Edit zipcode Info</div>
                                </div>
                                <div className="card-body">
                                    <form
                                        className="user"
                                        action="javascript:void(0)"
                                        onSubmit={submit}
                                    >
                                        <div className="form-group">
                                            <label htmlFor="countrySelect">Select Country</label>
                                            <select
                                                className="form-control"
                                                id="countrySelect"
                                                onChange={(e) => {
                                                    handleSelect(e);
                                                }}
                                                value={countryId}
                                            >
                                                <option value={''} key={''}>Select Country</option>
                                                {countryOptions()}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="stateSelect">Select State</label>
                                            <select
                                                className="form-control"
                                                id="stateSelect"
                                                onChange={(e) => {
                                                    handleSelectState(e);
                                                }}
                                                value={stateId}
                                            >
                                                <option value={''} key={''}>Select State</option>
                                                {stateOptions()}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="stateSelect">Select City</label>
                                            <select
                                                className="form-control"
                                                id="citySelect"
                                                onChange={(e) => { handleSelectCity(e); }}
                                                value={cityId}
                                            >
                                                <option value={''} key={''}>Select City</option>
                                                {cityOptions()}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <TextInput
                                                id="input_zipcode_name"
                                                field="zipcode"
                                                value={formState.zipcode.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Zipcode"
                                                placeholder="Zipcode"
                                            />
                                        </div>
                                        <button
                                            className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Editzipcode;
