import { createLogger } from "redux-logger";
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userManagementReducer from "./reducers/userManagementReducer";
import notificationManagementReducer from "./reducers/NotificationReducer";
import driverReducer from "./reducers/driver.reducer";
import countryReducer from "./reducers/country.reducer";
import UserReducer from "./reducers/UserReducer";
import detailReducer from "./reducers/details.reducer";
import StateReducer from "./reducers/State.reducer";
import loundryReducer from "./reducers/loundry.reducer";
import loaderReducer from "./reducers/loader.reducer";
import cityReducer from "./reducers/city.reducer";
import alertReducer from "./reducers/Alert.reducer";
import stripeReducer from "./reducers/stripe.reducer";
import userReducer from "./reducers/user.reducer";
import UsersContainreducer from "./reducers/UsersContain.reducer";
import batchReducer from "./reducers/batch.reducer";
import zipcodeReducer from "./reducers/zipcode.reducer";
import areaReducer from "./reducers/area.reducer";
import interestedZipcodeReducer from "./reducers/interestedZipcode.reducer";
import apartmentReducer from "./reducers/apartment.reducer";
import tagReducer from "./reducers/tag.reducer";
import dispatchTagReducer from "./reducers/dispatchTag.reducer";


const logger = createLogger();

export const store = configureStore({
  reducer: {
    notificationManagementReducer: notificationManagementReducer.reducer,
    userManagement: userManagementReducer.reducer,
    UserReducer: UserReducer.reducer,
    driverReducer: driverReducer.reducer,
    detailReducer:detailReducer.reducer,
    countryReducer: countryReducer.reducer,
    UsersContainreducer:UsersContainreducer.reducer,
    loundryReducer: loundryReducer.reducer,
    StateReducer: StateReducer.reducer,
    loaderReducer: loaderReducer.reducer,
    cityReducer: cityReducer.reducer,
    alertReducer: alertReducer.reducer,
    stripeReducer: stripeReducer.reducer,
    userReducer: userReducer.reducer,
    batchReducer: batchReducer.reducer,
    zipcodeReducer: zipcodeReducer.reducer,
    areaReducer: areaReducer.reducer,
    interestedZipcodeReducer: interestedZipcodeReducer.reducer,
    apartmentReducer: apartmentReducer.reducer,
    tagReducer: tagReducer.reducer,
    dispatchTagReducer: dispatchTagReducer.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type AppState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;

export type commonState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch