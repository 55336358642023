import React, { useState } from "react";
import styles from "./styles.module.css";
import SideBar from "../../layout/sidebar/SideBar";
import BreadCrum from "../../layout";
import { useDispatch } from "react-redux";
import { updateDriverPassword } from "../../../redux/actions/driver.actions";
import { useLocation, useNavigate } from "react-router-dom";
import { OnChangeModel } from "../../../common/types/Form.types";
import TextInput from "../../../common/components/TextInput";

const UpdateDriverPassword = () => {
  const breadCrums = [{ link: "drivers", title: "Drivers" }];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location: any = useLocation();
  const { driverInfo } = location.state;

  const [formState, setFormState] = useState<{
    id: string;
    password: any;
    cpassword: any;
  }>({
    id: driverInfo.id,
    password: { error: "", value: '' },
    cpassword: { error: "", value: '' },
  });

  function hasFormValueChanged(model: OnChangeModel): void {
    setFormState({
      ...formState,
      [model.field]: { error: model.error, value: model.value },
    });
  }

  console.log('-0asd-0as-d', driverInfo)

  const submit = async () => {
    dispatch(updateDriverPassword({password: formState.password.value, id: formState.id}, navigate));
  };

  const disableButton = formState.cpassword === "" && formState.password === ""

  return (
    <div className="container_full">
      <div className="side_bar scroll_auto">
        <SideBar />
      </div>
      <div className="content_wrapper">
        <div className="container-fluid">
          <BreadCrum title={"Change password"} page={breadCrums} />

          <div className="row">
            <div className=" col-md-8 offset-2">
              <div className="card card-shadow mb-4">
                <div className="card-header">
                  <div className="card-title">Change password</div>
                </div>
                <div className="card-body">
                  <form
                    className="user"
                    action="javascript:void(0)"
                    onSubmit={submit}
                  >
                    <TextInput
                      id="input_driver_password"
                      field="password"
                      value={formState.password.value}
                      onChange={hasFormValueChanged}
                      required={true}
                      maxLength={100}
                      label="New Password"
                      placeholder="Enter Password"
                      inputClass="mb-2"
                    />

                    <TextInput
                      id="input_driver_password"
                      field="cpassword"
                      value={formState.cpassword.value}
                      onChange={hasFormValueChanged}
                      required={true}
                      maxLength={100}
                      label="Confirm Password"
                      placeholder="Enter Confirm Password"
                      inputClass="mb-3"
                    />

                    <button
                      className={`btn btn-primary btn-user btn-block ${disableButton ? 'disabled' : ''}`}
                      type="submit"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateDriverPassword;
