import React, { useEffect, useState } from 'react';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import { Link } from 'react-router-dom'
import Status from '../../../common/components/Status';
import DataTable from 'react-data-table-component';
import { DateTime } from '../../../common/components/Date';
import { UpdateStatus } from '../../../common/components/UpdateStatus';
import { LoaderBullet } from '../../../common/components/Loader';
import { fetchLoundryListData } from '../../../redux/fetchService/LoundryManagement';
import { CSVLink } from 'react-csv';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { deleteType } from '../../../redux/fetchService/Status.service';
import { deleteDriverAction } from '../../../redux/actions/driver.actions';
import { toast } from 'react-toastify';


const LaundryList = () => {

    const breadCrums = [{ link: 'laundry', title: 'Laundries' }];
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const dispatch = useDispatch()

    const columns: any = [
        {
            name: 'S.No.',
            selector: (row: any, id: number) => (page - 1) * perPage + (id + 1),
        },
        {
            name: 'Name',
            selector: (row: any) => <Link
                to={`details/${row.id}`}
                state={{ loundryInfo: row }}
            >{row.full_name}</Link>
        },
        {
            name: 'Mobile',
            selector: (row: any) => row.mobile_number
        },
        {
            name: 'Email',
            selector: (row: any) => row.email
        },
        {
            name: 'Address',
            selector: (row: any) => row.laundry == null ? null : row.laundry.address
        },
        {
            name: 'Status',
            selector: (row: any) => row.status,
            cell: (row: any) => <Status status={row.status} />
        },
        {
            name: 'Created At',
            selector: (row: any) => row.created_at,
            cell: (row: any) => <DateTime date={row.created_at} />
        },
        {
            name: 'Action',
            cell: (row: any) => {
                let id = row.id;
                return (
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            Action
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link
                                className="dropdown-item" to={`edit/${row.id}`}
                                state={{ loundryInfo: row }}
                            >Edit</Link>
                            <Link
                                className="dropdown-item" to={`details/${row.id}`}
                                state={{ loundryInfo: row }}
                            >Details</Link>
                            {
                                row.status === "1" ? <a href={void (0)} className="dropdown-item"
                                    onClick={() => UpdateStatus({ id: id, type: "8", status: "0" })}>Make Inactive</a> : <a href={void (0)} className="dropdown-item"
                                        onClick={() => UpdateStatus({ id: id, type: "8", status: "1" })}>Make Active</a>
                            }
                            <a
                                className="dropdown-item"
                                onClick={() => DeleteUser({ id: id, type: "1", delete_type: "1" })}
                            >Delete</a>
                        </div>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        fetchUsers();

    }, []);

    useEffect(() => {
        fetchUsers();

    }, [perPage]);

    const fetchUsers = async (page = 1) => {
        setLoading(true);

        let payload = {
            page: page,
            limit: perPage
        };

        let response: any = await fetchLoundryListData(payload)
        setData(response.data.rows);
        setTotalRows(response.data.count);
        setLoading(false);
    };

    const handlePageChange = (page: number) => {
        setPage(page);
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage);
        setPage(page);
        fetchUsers(page);
    };

    const DeleteRecord = async (payload: any) => {

        let check = await deleteType(payload);

        if (check) {
            dispatch(deleteDriverAction(payload));
            fetchUsers();
        } else {
            toast('Oops! Failed to delete, please try again.');
        }
    }

    const DeleteUser = (payload: {}) => {
        swal("Are you sure?", "You want to delete user!", "warning", {
            buttons: ["CANCEL", "DELETE"],
            dangerMode: true,
        }).then((okay) => {
            if (okay) {
                DeleteRecord(payload);
            }
        });
    };

    return (
        <div className="container_full tbcont1-main">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid mb-5">
                    <BreadCrum title={'Laundries'} page={breadCrums} />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CSVLink data={data} filename={'loundries.csv'}>Download CSV</CSVLink>
                        <div className='text-right mb-2'>
                            <Link className="btn btn-primary" to={'add'} >Add New Laundry</Link>
                        </div>
                    </div>
                    {/* <Table data={countryData} columns={columns} /> */}

                    <DataTable
                        title="Laundries"
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={[10, 50, 100, 500]}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressComponent={<LoaderBullet />}
                    />
                </div>
            </div>
        </div>
    )
}

export default LaundryList