import React from 'react'
import Select, { StylesConfig } from 'react-select';

interface Props {
    labelledBy: string,
    options: Array<{value:string, label:string}>
    selectStyle: StylesConfig
    value: any
    onChange: (val:any) => void
}

const SingleSelect = (props:Props) => {
    const {labelledBy, options, selectStyle, value, onChange} = props
  return (
    <React.Fragment>
        <label htmlFor={labelledBy}>{labelledBy}</label>
        <Select
            defaultValue={options[0]}
            options={options}
            styles={selectStyle}
            value={value}
            onChange={onChange}
        />
    </React.Fragment>
  )
}

export default SingleSelect