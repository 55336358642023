import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import Status from '../../../common/components/Status';
import { Link } from 'react-router-dom'
import { DateTime } from '../../../common/components/Date';
import DataTable from 'react-data-table-component';
import { LoaderBullet } from '../../../common/components/Loader';
import { toast } from 'react-toastify';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { deleteType, updateStatus } from '../../../redux/fetchService/Status.service';
import { getStateListData } from '../../../redux/reducers/State.reducer';
import { StateItem } from '../../../redux/interfaces/StateInterface';
import { deleteStateAction, StateListAction, updateStateStatusAction } from '../../../redux/actions/state.actions';
import swal from "sweetalert";
import { CSVLink } from 'react-csv';

function CountryList() {
  const dispatch = useDispatch();
  const states = useSelector(getStateListData);
  const loader = useSelector(getLoading);
  const breadCrums = [{ link: 'states', title: 'States' }];

  const [stateData, setStateData] = useState<StateItem[]>([]);
  const [page,setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const columns: any = [
    {
      name: 'S.No.',
      selector: (row: any, id: number) => (page - 1) * 10 + (id + 1),
    },
    {
      name: 'State Name',
      selector: (row: any) => row.state_name
    },
    {
      name: 'Country Name',
      selector: (row: any) => row.country.country_name
    },
    {
      name: 'Status',
      selector: (row: any) => row.status,
      cell: (row: any) => <Status status={row.status} />
    },
    {
      name: 'Created At',
      selector: (row: any) => row.created_at,
      cell: (row: any) => <DateTime date={row.created_at} />
    },
    {
      name: 'Action',
      cell: (row: any) => {
        let id = row.id;
        return (
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Action
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link
                className="dropdown-item" to={`edit/${row.id}`}
                state={{ stateInfo: row }}
              >Edit</Link>
              <a
                className="dropdown-item"                
                onClick={() => DeleteState({id: id, type: "3", delete_type: "1"})}
              >Delete</a>
              {
                row.status === "1" ? <a href={void (0)} className="dropdown-item"
                  onClick={() => UpdateStatus({ id: id, type: "3", status: "0" })}>Make Inactive</a> : <a href={void (0)} className="dropdown-item"
                    onClick={() => UpdateStatus({ id: id, type: "3", status: "1" })}>Make Active</a>
              }

            </div>
          </div>
        );
      },
    },
  ];


  useEffect(() => {
    setLoading(loader);
  }, [loader]);
  
  const fetchData = async () => {
    let payload = {
      country_id: ""
    }
    dispatch(StateListAction(payload));
  };

  const handlePageChange = (page: number) => {
    //fetchUsers(page);
    setPage(page)
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
    return () => { };
  }, [dispatch]);

  useEffect(() => {
    setStateData(states)    
  }, [states]);

  /**
   * @description To change country status use this function
   * @param payload 
   * @returns 
   */

  const UpdateStatus = async (payload: {}) => {
    let check = await updateStatus(payload);
    if (check.success === true) {
      dispatch(updateStateStatusAction(payload));
      toast('Great! State is updated successfully.');
      return;
    }
  }

  const DeleteRecord = async (payload: any) => {
    let check = await deleteType(payload);
    
    if (check) {
      dispatch(deleteStateAction(payload));
    } else {
      toast('Oops! Failed to delete, please try again.');
    }
  }
  const DeleteState = (payload: {}) =>{
    swal ("Are you sure?", "You want to delete state!", "warning", {
        buttons: ["CANCEL", "DELETE"],
        dangerMode: true,
    }).then((okay)=>{
        if (okay){
        DeleteRecord(payload);
        }
    });
};

  return (
    <div className="container_full tbcont1-main">
      <div className="side_bar scroll_auto">
        <SideBar />
      </div>
      <div className="content_wrapper">
        <div className="container-fluid mb-5">
          <BreadCrum title={'States'} page={breadCrums} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CSVLink data={stateData} filename={'states.csv'}>Download CSV</CSVLink>
            <div className='text-right mb-2'>
              <Link className="btn btn-primary" to={'add'} >Add New State</Link>
            </div>         
          </div>         
          <DataTable
            title="States"
            columns={columns}
            data={stateData}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<LoaderBullet />}
          />
        </div>
      </div>
    </div>
  )
}

export default CountryList;