import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { detailItem, detailsDefaultState } from "../interfaces/DetailInterface";
import { AppState } from "../store";

const initialState: detailsDefaultState = {
  detailList: [],
  totalRecords: 0
};

export const detailReducer = createSlice({
  name: "detailState",
  initialState,
  reducers: {
    setDetailsListData: ( state, action: PayloadAction<detailItem[]> ) => ({
      ...state,
      detailList: action.payload,
    }),
  },
});

export const { setDetailsListData } = detailReducer.actions;

export const getDetailListData = (state: AppState) => state.detailReducer.detailList;

export { initialState as detailState };
export default detailReducer;
