
import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";

export const fetchZipcodeListApi = async (payload: any) => {
    return await ApiServices('get', payload, ApiEndPoints.ListZipcode);    
};

export const postZipcodeApi = async (payload: any) => {
    return await ApiServices('post', payload, ApiEndPoints.AddZipcode);    
};

export const UpdateZipcodeApi = async (payload: any) => {
    return await ApiServices('put', payload, ApiEndPoints.UpdateZipcode);    
};

