import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import TextInput from '../../../common/components/TextInput';
import { OnChangeModel } from "../../../common/types/Form.types";
import { updateDriverData } from '../../../redux/actions/driver.actions';
import { fetchLoundryListData } from '../../../redux/fetchService/LoundryManagement'
import SelectMulti from '../../../common/components/MultiSelect';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';



const EditDriver = () => {

    const breadCrums = [{ link: 'drivers', title: 'Drivers' }];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let location: any = useLocation();
    const { driverInfo } = location.state;
    const [laundryOptions, setLaundryOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    console.log('pppp',driverInfo);
    

    useEffect(() => {
        getList();
    }, []);


    useEffect(() => {        
        let laundryOptions: any = [];
        console.log("driverInfo", driverInfo);
        driverInfo.laundry_allocate?.map((item: any) => {    
            console.log(driverInfo,"ooooooooop");
                    
            laundryOptions.push({ label: item.laundryInfo.full_name, value: item.laundryInfo.id })
        })
        setSelected(laundryOptions);        
    }, []);

    useEffect(() => {
        let laundryOptions: any = [];
        selected.map((item: any) => {
            laundryOptions.push({ laundry_id: item.value })
        })
        setFormState({ ...formState, laundryAllocate: { error: "", value: laundryOptions } });
    }, [selected]);

    const [formState, setFormState] = useState({
        fullName: { error: "", value: driverInfo.full_name },
        id: { error: "", value: driverInfo.id },
        email: { error: "", value: driverInfo.email },
        mobile: { error: "", value: driverInfo.mobile_number },
        password: { error: "", value: "" },
        // laundry: { error: "", value: driverInfo.laundry },
        // address:{error:"",value:driverInfo.user_address},
        laundryAllocate: { error: "", value: driverInfo.laundry_allocate }
    });
    
    

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } });
    }

    async function getList() {
        let payload = {
            page: 1
        }
        const laundryResponse = await fetchLoundryListData(payload);
        let options: any = [];
        laundryResponse.data.rows.map(async (item: any) => {
            await options.push({ label: item.full_name, value: item.id })
        })

        setLaundryOptions(options);
        return {};
    }

    const submit = async () => {
        dispatch(updateDriverData(formState, navigate))
    }


    function isFormInvalid() {
        return (formState.fullName.error || !formState.fullName.value);
        return (formState.id.error || !formState.id.value);
        return (formState.email.error || !formState.email.value);
        return (formState.mobile.error || !formState.mobile.value);
        return (formState.password.error || !formState.password.value);
    }

    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }


    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={'Edit Driver'} page={breadCrums} />

                    <div className="row">
                        <div className=" col-md-8 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Edit Driver Info
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form className="user" action='javascript:void(0)' onSubmit={submit}>
                                        <div className="form-group">
                                            <TextInput id="input_country_name"
                                                field="fullName"
                                                value={formState.fullName.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label=" Name"
                                                placeholder="Full Name" />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="input_email"
                                                field="email"
                                                value={formState.email.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Email"
                                                placeholder="Email" />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="input_country_name"
                                                field="mobile"
                                                value={formState.mobile.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Mobile"
                                                placeholder="Mobile Number" />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="input_password"
                                                field="password"
                                                value={formState.password.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Password"
                                                placeholder="Password" />
                                        </div>
                                       
                                        {/* <p>Address</p>
                                    <PlacesAutocomplete
                                        value={formState.address.value}
                                        onChange={
                                            (e) => setFormState({...formState, address: {error: "", value: e}})
                                        }
                                        onSelect={handleGoogleAddress}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                            {...getInputProps({
                                                placeholder: 'Search Address ...',
                                                className: 'location-search-input',
                                             })}
                                             style={{width:"100%",height:'35px'}}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                                );
                                            })}
                                            </div>
                                        </div>
                                        )}
                                    </PlacesAutocomplete>
 */}


                                        <div className="form-group">
                                            <SelectMulti
                                                options={laundryOptions}
                                                value={selected}
                                                onChange={setSelected}
                                                labelledBy="Select Laundry"
                                            />
                                        </div>
                                        <button
                                            className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                            type="submit" >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default EditDriver;