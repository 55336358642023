import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setUserManagementDataReducer } from '../../../redux/actions/userManagementActions';
import { useNavigate } from "react-router-dom";
import TextInput from '../../../common/components/TextInput';
import { OnChangeModel } from "../../../common/types/Form.types";

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formState, setFormState] = useState({
        email: { error: "", value: "" },
        password: { error: "", value: "" }
    });

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } });
    }
    const submit = async () => {
        dispatch(setUserManagementDataReducer(formState, navigate))
    }

    function isFormInvalid() {
        return (formState.email.error || formState.password.error
            || !formState.email.value || !formState.password.value);
    }

    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }

    return (
        <>
            <div className="container">
                <div className="login-content">
                    <div className="logo">
                        <strong className="logo_icon">
                            <img alt="" src="assets/images/small-logo.png" />
                        </strong>
                        <span className="logo-default">
                            <img alt="" src="assets/images/logo.png" />
                        </span>
                    </div>
                    <div className="login-form">
                        <form className="user" action="javascript:void(0)" onSubmit={submit}>
                            <div className="form-group">
                                <TextInput id="input_email"
                                    field="email"
                                    value={formState.email.value}
                                    onChange={hasFormValueChanged}
                                    required={true}
                                    maxLength={100}
                                    label="Email"
                                    placeholder="Email" />
                            </div>
                            <div className="form-group">
                                <TextInput id="input_password"
                                    field="password"
                                    value={formState.password.value}
                                    onChange={hasFormValueChanged}
                                    required={true}
                                    maxLength={100}
                                    type="password"
                                    label="Password"
                                    placeholder="Password" />
                            </div>
                            <div className="form-group">
                                <div className="custom-control custom-checkbox small">
                                    <input type="checkbox" className="custom-control-input" id="customCheck" />
                                    <label className="custom-control-label"
                                        htmlFor="customCheck">Remember Me</label>
                                </div>
                            </div>
                            <button
                                className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                type="submit" >
                                Login
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
