
import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";

// export const fetchUserManagementData = async (payload: any) => {
//     // const data = await ApiServices('post', payload, ApiEndPoints.Login);

//     return {};
// };


export const fetchUserManagementData = async (payload: any) => {
    return await ApiServices('post', payload, ApiEndPoints.Login);
};