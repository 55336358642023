import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import TextInput from '../../../common/components/TextInput';
import { OnChangeModel } from "../../../common/types/Form.types";
import { setLoundryData } from '../../../redux/actions/loundry.actions';
import { CountryListAction } from "../../../redux/actions/country.actions";
import { getCountryListData } from "../../../redux/reducers/country.reducer";
import { countryItem } from "../../../redux/interfaces/CountryInterface";
import { getAreas } from '../../../redux/fetchService/Area.service';
import SelectMulti from '../../../common/components/MultiSelect';
import "../../area/add/add.css";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import SingleSelect from '../../../common/components/SingleSelect';

const AddLoundry = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const breadCrums = [{ link: 'laundry', title: 'Laundries' }];
    const countries = useSelector(getCountryListData);
    const [areaOptions, setAreaOptions] = useState([]);
    const [selected, setSelected] = useState<{label:string, value:string}>();
    const [countryData, setCountryData] = useState<countryItem[]>([]);
    const [countryId, setCountryId] = useState("");
    const [addressError, setAddressError] = useState("");
    const [address, setAddress] = useState("");
    const [selectedCity, setSelectedCity] = useState("")

    
     const [formState, setFormState] = useState({
        fullname: { error: "", value: "" },
        address: { error: "", value: "" },
        email: { error: "", value: "" },
        area: { error: "", value: "" },
        mobile: { error: "", value: "" },
        password: { error: "", value: "qwerty" },
        latitude: { error: "", value: "" },
        longitude: { error: "", value: "" }
  });
  console.log('ppp',formState.area);
  
 
    useEffect(() => {
        dispatch(CountryListAction());
        return () => { };
    }, [dispatch]);
    
    useEffect(() => {
        console.log('-0-as0d-0asd-0awe-20-203-20321212')
        getServiceAreas(); 
        return () => { };
    }, [selectedCity]);

    useEffect(() => {
       
        setFormState({ ...formState, area: { error: "", value: selected?.value || '' } });
        
    }, [selected]);



    async function getServiceAreas() {
        let payload:any = {
            page: 1,
            limit: 1000,
        }
        payload.city = selectedCity

        let areasResponse = await getAreas(payload);
        if (areasResponse.success) {
            let options: any = [];
            areasResponse?.data?.map(async (item: any) => {
                await options.push({ label: item.area_name, value: item.id })
            });
            setAreaOptions(options);
            console.log("dddareas", areasResponse);
            
        }
        return {};
    }

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({ ...formState, [model.field]: { error: model.error, value: model.value }, 
        });
        setAddressError("");
        setAddress(formState.address.value);
    }
 
       const submit = async () => {

        if (formState.address.value.length == 0) {
            setAddressError("Please Enter Address Field");
            return false;
        }
        dispatch(setLoundryData(formState, navigate));
        console.log(formState,"formis");

    }

    useEffect(() => {
        let data: any = [];
        countries.map((item: any) => {
            data.push(item);
        });
        setCountryData(data);
    }, [countries]);

  
    function isFormInvalid() {

        return (formState.fullname.error || !formState.fullname.value);
        return (formState.address.error || !formState.address.value);
        return (formState.email.error || !formState.email.value);
        return (formState.mobile.error || !formState.mobile.value);
    }

    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }

    const countryOptions = () => {
        return countryData.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.country_name}
                </option>
            );
        });
    };

    const handleSelect = (e: any) => {
        setCountryId(e.target.value);
    };

    const handle =  async(val:any) => {
        const getSelectedCity = val.split(',')[0]
        setSelectedCity(getSelectedCity)
        await  geocodeByAddress(val)
          .then(results => getLatLng(results[0]))
          .then((latLng:any) => {
            setFormState({ ...formState, latitude: { error: "", value:latLng.lat}, longitude: { error: "", value:latLng.lng}, address: {error: "", value: val}})
            
        }).catch(error => console.error('Error', error));
    }

  const handleGoogleAddress = (e: any) => {
    console.log('=-as=da=-d=a-sd', e)
    handle(e)
  }

 return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={'Add New Laundry'} page={breadCrums} />
                    <div className="row">
                        <div className=" col-md-8 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Add Laundry Info
                                    </div>
                                </div>
                                
                                <div className="card-body">
                                    <form className="user" action='javascript:void(0)' onSubmit={submit}>
                                        <div className="form-group">
                                            <TextInput id="fullname"
                                                field="fullname"
                                                value={formState.fullname.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Name"
                                                placeholder="Enter Name " />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="email"
                                                field="email"
                                                value={formState.email.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Email"
                                                placeholder="Enter Email " />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="mobile"
                                                field="mobile"
                                                value={formState.mobile.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={10}
                                                label="Mobile"
                                                placeholder="Enter Mobile " />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="countrySelect">Country select</label>
                                            <select
                                                className="form-control"
                                                id="countrySelect"
                                                onClick={(e) => {
                                                    handleSelect(e);
                                                }}
                                            >
                                                <option value="" key={''}>
                                                    Select Country
                                                </option>
                                                {countryOptions()}
                                            </select>

                                        </div>
                                        <div className="form-group">
                                            {/* <TextInput id="address"
                                                field="address"
                                                value={formState.address.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Address"
                                                placeholder="Enter Address " /> */}
                                                 <div>
                                
                                    <p>Address</p>
                                    <PlacesAutocomplete
                                        value={formState.address.value}
                                        onChange={
                                            (e) => setFormState({...formState, address: {error: "", value: e}})
                                        }
                                        onSelect={handleGoogleAddress}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                            {...getInputProps({
                                                placeholder: 'Search Address ...',
                                                className: 'location-search-input',
                                             })}
                                             style={{width:"100%",height:'35px'}}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                                );
                                            })}
                                            </div>
                                        </div>
                                        )}
                                    </PlacesAutocomplete>
                                    {addressError && (
                                                <div className="error-msg">{addressError}</div>
                                            )}
                                </div>
                                </div>
                                {formState.address.value.length > 0 ?
                            <>
                                        <div className="form-group">
                                            <TextInput id="latitude"
                                                field="latitude"
                                                value={formState.latitude.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Latitude"
                                                placeholder="Enter Latitude " />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="longitude"
                                                field="longitude"
                                                value={formState.longitude.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Longitude"
                                                placeholder="Enter Longitude " />
                                        </div>
                            </>    
                                        :null
                            }
                                       
                                       
                                        
                                        <div className="form-group">
                                            <SingleSelect
                                                options={areaOptions}
                                                selectStyle={{}}
                                                value={selected}
                                                onChange={setSelected}
                                                labelledBy="Select Service Areas"
                                            />
                                        </div>
                                        <button
                                            className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                            type="submit" >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default AddLoundry;



// import React, { useState, useEffect } from 'react'
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import SideBar from '../../layout/sidebar/SideBar';
// import BreadCrum from '../../layout';
// import TextInput from '../../../common/components/TextInput';
// import { OnChangeModel } from "../../../common/types/Form.types";
// import { setLoundryData } from '../../../redux/actions/loundry.actions';
// import { CountryListAction } from "../../../redux/actions/country.actions";
// import { getCountryListData } from "../../../redux/reducers/country.reducer";
// import { countryItem } from "../../../redux/interfaces/CountryInterface";
// import { getAreas } from '../../../redux/fetchService/Area.service';
// import SelectMulti from '../../../common/components/MultiSelect';
// import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';



// const AddLoundry = () => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const breadCrums = [{ link: 'laundry', title: 'Laundries' }];
//     const countries = useSelector(getCountryListData);
//     const [areaOptions, setAreaOptions] = useState([]);
//     const [selected, setSelected] = useState([]);
//     const [countryData, setCountryData] = useState<countryItem[]>([]);
//     const [countryId, setCountryId] = useState("");
//     const [address, setAddress] = useState("");
//     const [currentLongitude, setCurrentLongitude]= useState("")
//     const [currentLatitude, setCurrentLatitude]= useState("")
//     const [locationStatus, setLocationStatus]= useState("")

//     // const [coordinates, setCoordinates] = useState({
//     //     lat:null, lng: null
//     // });
// //     const [lat, setLat]=useState("");
// //     const [lng, setLng]=useState("");
// // console.log('lat lat',lat);
// // console.log('log log',lng);



//     const [formState, setFormState] = useState({
//         fullname: { error: "", value: "" },
//         address: { error: "", value: "" },
//         latitude: { error: "", value: "" },
//         longitude: { error: "", value: "" },
//         email: { error: "", value: "" },
//         laundryArea: { error: "", value: "" },
//         mobile: { error: "", value: "" },
//     });

    // const handle = async (value:any) => {
    //     const results = await geocodeByAddress(value);
    //     results.map((val:any)=>{
    //         console.log(val);     
    //     })

    //     // const l = await getLatLng(results[0])
    //     // console.log ( l.lat,"lat")
    //     // setAddress(value)
    //     // setLat()
    //     // setLng(l.lng)
    //     // setCoordinates(l)
    // }
    // const getOneTimeLocation = async () => {
    //     setLocationStatus('Getting Location ...');
    //     await Geolocation.getCurrentPosition(
    //         (position:any) => {
    //             setLocationStatus('You are Here');
    //             const currentLongitude = JSON.stringify(position.coords.longitude);
    //             const currentLatitude = JSON.stringify(position.coords.latitude);
    //             setCurrentLongitude(currentLongitude);
    //             setCurrentLatitude(currentLatitude);
    //             // currentLocationAddress(currentLatitude, currentLongitude)
    //         },
    //         (error:any) => {
    //             setLocationStatus(error.message);
    //             alert('You location is still disable')
    //         },
    //         {
    //             enableHighAccuracy: false,
    //             timeout: 30000,
    //             maximumAge: 1000
    //         },
    //     )
    // };



//     useEffect(() => {
//         dispatch(CountryListAction());
//         return () => { };
//     }, [dispatch]);

//     useEffect(() => {
//         getServiceAreas();
//         return () => { };
//     }, []);

//     useEffect(() => {
//         let areaOptions: any = [];
//         selected.map((item: any) => {
//             areaOptions.push({ area_id: item.value })
//         })
//         setFormState({ ...formState, laundryArea: { error: "", value: "" } });
//     }, [selected]);

//     async function getServiceAreas() {

//         let payload = {
//             page: 1
//         }
//         let areasResponse = await getAreas(payload);
//         if (areasResponse.success) {
//             let options: any = [];
//             areasResponse.data.map(async (item: any) => {
//                 await options.push({ label: item.zipcodes.locality_name, value: item.id })
//             });
//             setAreaOptions(options);
//         }
//         return {};
//     }

//     useEffect(() => {
//         let data: any = [];
//         countries.map((item: any) => {
//             data.push(item);
//         });
//         setCountryData(data);
//     }, [countries]);





  



 



//     return (

//         <div className="container_full">

//             <div className="side_bar scroll_auto">
//                 <SideBar />
//             </div>
//             <div className="content_wrapper">
//                 <div className="container-fluid">
//                     <BreadCrum title={'Add New Laundry'} page={breadCrums} />

//                     <div className="row">
//                         <div className=" col-md-8 offset-2">
//                             <div className="card card-shadow mb-4">
//                                 <div className="card-header">
//                                     <div className="card-title">
//                                         Add Laundry Info
//                                     </div>
//                                 </div>
    //                             {/* <div>
    //                                 <p>lat: {lat}</p>
    //                                 <p>lng: {lng}</p>
    //                                 <p>Address: {address}</p>
    //                                 <PlacesAutocomplete
    //                                     value={address}
    //                                     onChange={setAddress}
    //                                     onSelect={handle}>
    //                                     {({ getInputProps, suggestions, loading, getSuggestionItemProps }) => (
    //                                         <div>
    //                                         <input
    //                                             {...getInputProps({
    //                                                 placeholder: 'Search',
    //                                                 className: 'location-search-input',
    //                                             })}
    //                                         />
                                       
    //                                     <div className="autocomplete-dropdown-container">
    //                                         {loading && <div>Loading...</div>}
    //                                         {suggestions.map(suggestion => {
    //                                             const className = suggestion.active
    //                                                 ? 'suggestion-item--active'
    //                                                 : 'suggestion-item';
    //                                             // inline style for demonstration purpose
    //                                             const style = suggestion.active
    //                                                 ? { backgroundColor: '#fafafa', cursor: 'pointer' }
    //                                                 : { backgroundColor: '#ffffff', cursor: 'pointer' };
    //                                             return (
    //                                                 <div
    //                                                     {...getSuggestionItemProps(suggestion, {
    //                                                         className,
    //                                                         style,
    //                                                     })}
    //                                                 >
    //                                                     <span>{suggestion.description}</span>
    //                                                 </div>
    //                                             );
    //                                         })}
    //                                     </div>
    //                                     </div>
    // )}
    //                                 </PlacesAutocomplete>

    //                             </div> */}
//                                 <div className="card-body">
//                                     <form className="user" action='javascript:void(0)' onSubmit={submit}>
//                                         <div className="form-group">
//                                             <TextInput id="fullname"
//                                                 field="fullname"
//                                                 value={formState.fullname.value}
//                                                 onChange={hasFormValueChanged}
//                                                 required={true}
//                                                 maxLength={100}
//                                                 label="Name"
//                                                 placeholder="Enter Name " />
//                                         </div>
//                                         <div className="form-group">
//                                             <TextInput id="mobile"
//                                                 field="mobile"
//                                                 value={formState.mobile.value}
//                                                 onChange={hasFormValueChanged}
//                                                 required={true}
//                                                 maxLength={100}
//                                                 label="Mobile"
//                                                 placeholder="Enter Mobile " />
//                                         </div>
//                                         <div className="form-group">
//                                             <label htmlFor="countrySelect">Country select</label>
//                                             <select
//                                                 className="form-control"
//                                                 id="countrySelect"
//                                                 onClick={(e) => {
//                                                     handleSelect(e);
//                                                 }}
//                                             >
//                                                 <option value="" key={''}>
//                                                     Select Country
//                                                 </option>
//                                                 {countryOptions()}
//                                             </select>
//                                         </div>
//                                         <div className="form-group">
//                                             <TextInput id="address"
//                                                 field="address"
//                                                 value={formState.address.value}
//                                                 onChange={hasFormValueChanged}
//                                                 required={true}
//                                                 maxLength={100}
//                                                 label="Address"
//                                                 placeholder="Enter Address " />
//                                         </div>
//                                         <div className="form-group">
//                                             <TextInput id="latitude"
//                                                 field="latitude"
//                                                 value={formState.latitude.value}
//                                                 onChange={hasFormValueChanged}
//                                                 required={true}
//                                                 maxLength={100}
//                                                 label="Latitude"
//                                                 placeholder="Latitude " />
//                                         </div>
//                                         <div className="form-group">
//                                             <TextInput id="longitude"
//                                                 field="longitude"
//                                                 value={formState.longitude.value}
//                                                 onChange={hasFormValueChanged}
//                                                 required={true}
//                                                 maxLength={100}
//                                                 label="Longitude"
//                                                 placeholder="Longitude " />
//                                         </div>
//                                         <div className="form-group">
//                                             <TextInput id="email"
//                                                 field="email"
//                                                 value={formState.email.value}
//                                                 onChange={hasFormValueChanged}
//                                                 required={true}
//                                                 maxLength={100}
//                                                 label="Email"
//                                                 placeholder="Enter Email " />
//                                         </div>
//                                         <div className="form-group">
//                                             <SelectMulti
//                                                 options={areaOptions}
//                                                 value={selected}
//                                                 onChange={setSelected}
//                                                 labelledBy="Select Service Areas"
//                                             />
//                                         </div>
//                                         <button
//                                             className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
//                                             type="submit" >
//                                             Submit
//                                         </button>
//                                     </form>
//                                 </div> */}
//                             </div>
//                         </div>
//                     </div>

//                 </div>
//             </div>
//         </div>
//     );
// }

// export default AddLoundry;