import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import Status from '../../../common/components/Status';
import { Link } from 'react-router-dom'
import { DateTime } from '../../../common/components/Date';
import DataTable from 'react-data-table-component';
import { LoaderBullet } from '../../../common/components/Loader';
import { toast } from 'react-toastify';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { deleteType, updateStatus } from '../../../redux/fetchService/Status.service';
import { AreaListAction } from '../../../redux/actions/area.action';
import { areaItem } from '../../../redux/interfaces/AreaInterface';
import { deleteArea, getAreaListData, updateAreaStatus } from '../../../redux/reducers/area.reducer';
import { CSVLink } from 'react-csv';

function AreaList() {

  const dispatch = useDispatch();
  const areas = useSelector(getAreaListData);
  const loader = useSelector(getLoading);
  const breadCrums = [{ link: 'areas', title: 'Areas' }];

  const [areaData, setAreaData] = useState<areaItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const columns: any = [
    {
      name: 'S.No.',
      selector: (row: any, id: number) => id + 1,
    },
    {
      name: 'Area',
      selector: (row: any) => row.area_name
    },
    {
      name: 'Status',
      selector: (row: any) => row.status,
      cell: (row: any) => <Status status={row.status} />
    },
    {
      name: 'Created At',
      selector: (row: any) => row.created_at,
      cell: (row: any) => <DateTime date={row.created_at} />
    },
    {
      name: 'Action',
      cell: (row: any) => {
        let id = row.id;
        return (
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Action
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link
                className="dropdown-item" to={`edit/${row.id}`}
                state={{ areaInfo: row }}
              >Edit</Link>
              <a
                className="dropdown-item"
                onClick={() => DeleteRecord({ id: id, type: "6", delete_type: "1" })}
              >Delete</a>
              {
                row.status === "1" ? <a href={void (0)} className="dropdown-item"
                  onClick={() => UpdateStatus({ id: id, type: "6", status: "0" })}>Make Inactive</a> : <a href={void (0)} className="dropdown-item"
                    onClick={() => UpdateStatus({ id: id, type: "6", status: "1" })}>Make Active</a>
              }

            </div>
          </div>
        );
      },
    },
  ];


  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  const fetchData = async () => {
    let payload = {
      page: 1
    }
    dispatch(AreaListAction(payload));
  };

  const handlePageChange = (page: number) => {
    //fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
    return () => { };
  }, [dispatch]);

  useEffect(() => {
    const areaList = areas
    setAreaData(areaList)
  }, [areas]);

  /**
   * @description To change country status use this function
   * @param payload 
   * @returns 
   */

  const UpdateStatus = async (payload: any) => {
    let check = await updateStatus(payload);
    if (check.success === true) {
      dispatch(updateAreaStatus(payload));
      toast('Great! Info updated successfully.');
      return;
    }
  }

  const DeleteRecord = async (payload: any) => {
    let check = await deleteType(payload);

    if (check) {
      toast('Great! Record is deleted successfully.');
      dispatch(deleteArea(payload));
    } else {
      toast('Oops! Failed to delete, please try again.');
    }
  }

  return (
    <div className="container_full tbcont1-main">
      <div className="side_bar scroll_auto">
        <SideBar />
      </div>
      <div className="content_wrapper">
        <div className="container-fluid mb-5">
          <BreadCrum title={'Areas'} page={breadCrums} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CSVLink data={areaData} filename={'areas.csv'}>Download CSV</CSVLink>
            <div className='text-right mb-2'>
              <Link className="btn btn-primary" to={'add'} >Add New Area</Link>
            </div>
          </div>
          <DataTable
            title="Areas"
            columns={columns}
            data={areaData}
            progressPending={loading}
            pagination
            //paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<LoaderBullet />}
          />
        </div>
      </div>
    </div>
  )
}

export default AreaList;