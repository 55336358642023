import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";

export const fetchDriverListData = async (payload: any) => {
    return await ApiServices('get', payload, ApiEndPoints.DriverList);    
};

export const postDriverData = async (payload: any) => {
    const data = await ApiServices('post', payload, ApiEndPoints.DriverAdd);
    return data;
};

export const changeDriverPassword = async (payload: any) => {
    const data = await ApiServices('put', payload, ApiEndPoints.changeDriverpassword);
    return data;
};

export const updateDriverApi = async (payload: any) => {
    const data = await ApiServices('put', payload, ApiEndPoints.DriverUpdate);
    return data;
};

export const getDriverDetailsApi = async (payload: any) => {
    const data = await ApiServices('get', payload, ApiEndPoints.DriverDetails);
    return data;
};