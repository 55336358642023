
import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";


export const fetchCountryListData = async () => {    
    return await ApiServices('get', {}, ApiEndPoints.CountryList);
};

export const postCountryData = async (payload: any) => {
    return await ApiServices('post', payload, ApiEndPoints.CountryAdd);    
};

export const updateCountryApi = async (payload: any) => {
    return await ApiServices('put', payload, ApiEndPoints.CountryUpdate);    
};