import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { interestedZipcodeDefaultState, interestedZipcodeItem } from "../interfaces/InterestedZipcodeInterface";
import { AppState } from "../store";

const initialState: interestedZipcodeDefaultState = {
    interestedZipcodeList: [],
    totalRecords: 0
};

export const interestedZipcodeReducer = createSlice({
  name: "interestedZipcodeState",
  initialState,
  reducers: {
    setInterestedZipcodeListData: ( state, action: PayloadAction<interestedZipcodeItem[]> ) => ({
      ...state,
      interestedZipcodeList: action.payload,
    })         
  },
});

export const { setInterestedZipcodeListData } = interestedZipcodeReducer.actions;

export const getInterestedZipcodeListData = (state: AppState) => state.interestedZipcodeReducer.interestedZipcodeList;

export { initialState as interestedZipcodeState };
export default interestedZipcodeReducer;
