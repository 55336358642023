import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { zipcodeDefaultState, zipcodeItem } from "../interfaces/ZipcodeInterface";
import { AppState } from "../store";

const initialState: zipcodeDefaultState = {
    zipcodeList: [],
    totalRecords: 0
};

export const zipcodeReducer = createSlice({
  name: "zipcodeState",
  initialState,
  reducers: {
    setZipcodeListData: ( state, action: PayloadAction<zipcodeItem[]> ) => ({
      ...state,
      zipcodeList: action.payload,
    }),
    
    updateZipcodeStatus:( state, action: PayloadAction<zipcodeItem> ) => ({
      ...state, 
      zipcodeList: state.zipcodeList.map(zipcode=> zipcode.id === action.payload.id ? 
        { ...zipcode, status: action.payload.status} : zipcode
    ) 
    }),
    deleteZipcode:( state, action: PayloadAction<zipcodeItem> ) => ({
      ...state, 
      zipcodeList: state.zipcodeList.filter(zipcode => zipcode.id != action.payload.id) 
    }) 
  },
});

export const { setZipcodeListData, updateZipcodeStatus, deleteZipcode } = zipcodeReducer.actions;

export const getZipcodeListData = (state: AppState) => state.zipcodeReducer.zipcodeList;

export { initialState as zipcodeState };
export default zipcodeReducer;
