import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { count } from "console";

import { userItem, userDefaultState, userPage } from "../interfaces/UserInterface";
import { AppState } from "../store";

const initialState: userDefaultState = {
  userdata: [],
  userList: [],
  totalRecords: 0,
  userDetails: {},
  isUserLogin: false
};

export const userReducer = createSlice({
  name: "userState",
  initialState,
  reducers: {
    setUserListData: ( state, action: PayloadAction<userPage> ) => ({
      ...state,
      userList: action.payload.rows,
      totalRecords: action.payload.count
    }),
    setUserDetailsData: ( state, action: PayloadAction<userItem> ) => ({
      ...state,
      userDetails: action.payload
    }),
    updateUserStatus:( state, action: PayloadAction<userItem> ) => ({
      ...state, 
      userList: state.userList.map(item=> item.id === action.payload.id ? 
        { ...item, status: action.payload.status} : item
    ) 
    }),
    deleteUser:( state, action: PayloadAction<userItem> ) => ({
      ...state, 
      userList: state.userList.filter(item => item.id != action.payload.id) 
    })
  },
});

export const { setUserListData, setUserDetailsData, deleteUser, updateUserStatus } = userReducer.actions;

export const getUserListData = (state: AppState) => {
  return({rows: state.userReducer.userList, count: state.userReducer.totalRecords})
};

export const getUsersDetailsData = (state: AppState) => state.userReducer.userDetails

export { initialState as userState };
export default userReducer;
