import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { planItem, stripeDefaultState } from "../interfaces/StripeInterface";
import { AppState } from "../store";

const initialState: stripeDefaultState = {
    subscriptionPlansList: [],
    totalRecords: 0
};

export const stripeReducer = createSlice({
  name: "stripeState",
  initialState,
  reducers: {
    setSubscriptionPlansData: ( state, action: PayloadAction<planItem[]> ) => ({
      ...state,
      subscriptionPlansList: action.payload,
    }) 
  },
});

export const { setSubscriptionPlansData } = stripeReducer.actions;

export const getSubscriptionPlanListData = (state: AppState) => state.stripeReducer.subscriptionPlansList;

export { initialState as stripeState };
export default stripeReducer;
