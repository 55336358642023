import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import Status from '../../../common/components/Status';
import { Link } from 'react-router-dom'
import { DateTime } from '../../../common/components/Date';
import DataTable from 'react-data-table-component';
import { LoaderBullet } from '../../../common/components/Loader';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { getbatchListData, } from '../../../redux/reducers/batch.reducer';
import { batchItem } from '../../../redux/interfaces/BatchInterface';
import { BatchListAction } from '../../../redux/actions/batch.action';
import { CSVLink } from 'react-csv';
import { Button } from 'react-bootstrap';
import { deleteType } from '../../../redux/fetchService/Status.service';
import { deleteDriverAction } from '../../../redux/actions/driver.actions';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

function BatchList() {

  const dispatch = useDispatch();
  const batches = useSelector(getbatchListData);
  const breadCrums = [{ link: 'tag batches', title: 'Tag Batches' }];
  const loader = useSelector(getLoading);

  const [batchData, setBatchData] = useState<batchItem[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const filterRow = (row:any) => {
    const copyrow = {...row}
    delete copyrow.tag
    delete copyrow.deletedAt
    return [copyrow]
  }

  const DeleteRecord = async (payload: any) => {

    let check = await deleteType(payload);

    if (check) {
        dispatch(deleteDriverAction(payload));
        fetchData()
    } else {
        toast('Oops! Failed to delete, please try again.');
    }
  }
  const DeleteTagBatch = (payload: {}) => {
      swal("Are you sure?", "You want to delete driver!", "warning", {
          buttons: ["CANCEL", "DELETE"],
          dangerMode: true,
      }).then((okay) => {
          if (okay) {
            DeleteRecord(payload);
          }
      });
  };

  const columns: any = [
    {
      name: 'S.No.',
      selector: (row: any, id: number) => id + 1,
    },
    {
      name: 'Number of Tags',
      selector: (row: any) => row.number_of_tags
    },
    // {
    //   name: 'Type of Tag',
    //   selector: (row: any) => row.type_of_tag
    // },
    // {
    //   name: 'Tag For',
    //   selector: (row: any) => row.tag_for
    // },
    {
      name: 'Status',
      selector: (row: any) => row.status,
      cell: (row: any) => <Status status={row.status} />
    },
    {
      name: 'Created At',
      selector: (row: any) => row.created_at,
      cell: (row: any) => <DateTime date={row.created_at} />
    },
    {
      name: 'Get CSV',
      cell: (row: any) =>  <CSVLink data={filterRow(row)} filename={'batches.csv'}>Download CSV</CSVLink>
    },
    {
      name: 'Action',
      cell: (row: any) =>  {
        let id = row.id;
        return (
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    Action
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <Link
                        state={{ driverInfo: row }}
                        className="dropdown-item" to={`details/${row.id}`}
                    >Details</Link>
                    <a
                        className="dropdown-item"
                        onClick={() => DeleteTagBatch({ id: row.id, type: "11", delete_type: "1" })}
                    >Delete</a>
                </div>
            </div>
        );
      },
    }
  ];

// <CSVLink data={filterRow(row)} filename={'batches.csv'}>Download CSV</CSVLink>

  // <Button onClick={()=> console.log('-a0sd-a0sd', row)}>Click</Button>


  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  const fetchData = async (page = 1) => {
    let payload = {
      page: page,
      limit: perPage
    };
    dispatch(BatchListAction(payload));
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setPage(page);
    fetchData(page);
  };

  useEffect(() => {
    fetchData();
    return () => { };
  }, [dispatch]);

  useEffect(() => {
    setBatchData(batches.rows);
    setTotalRows(batches.count);
  }, [batches]);

  /**
   * @description To change country status use this function
   * @param payload 
   * @returns 
   */


  return (
    <div className="container_full tbcont1-main">
      <div className="side_bar scroll_auto">
        <SideBar />
      </div>
      <div className="content_wrapper">
        <div className="container-fluid mb-5">
          <BreadCrum title={'Tag Batches'} page={breadCrums} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CSVLink data={batchData} filename={'batches.csv'}>Download CSV</CSVLink>
            <div className='text-right mb-2'>
              <Link className="btn btn-primary" to={'add'} >Create New Tag Batch</Link>
            </div>
          </div>
          <DataTable
            title="Tag Batches"
            columns={columns}
            data={batchData}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={[10, 50, 100, 500]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<LoaderBullet />}
          />
        </div>
      </div>
    </div>
  )
}

export default BatchList;