import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";

export const fetchLoundryListData = async (payload: any) => {
    return await ApiServices('get', payload, ApiEndPoints.LaundryList);     
};

export const postLoundryData = async (payload: any) => {
    return await ApiServices('post', payload, ApiEndPoints.LaundryAdd);    
};

export const updateLoundryApi = async (payload: any) => {
    return await ApiServices('put', payload, ApiEndPoints.LaundryUpdate);    
};