import { setCountryListData, updateCountryStatus, deleteCountry } from "../reducers/country.reducer";
import { setLoading } from "../reducers/loader.reducer";
import { fetchCountryListData, postCountryData, updateCountryApi } from "../fetchService/CountryManagement";
import { AppThunk } from "../store";
import { toast } from "react-toastify";

export const CountryListAction = (): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  const response: any = await fetchCountryListData();  
  dispatch(setLoading(false));
  if (response.data) {
    dispatch(setCountryListData(response.data));
  }
};


export const setCountryData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  let formData = {
    country_name: payload.countryName.value,
  }

  const response: any = await postCountryData(formData);
  toast(response.message);
  if (response) {
    navigate('/countries');
  } else {
   
  }
};

/**
 * @description To update country status 
 * @param payload 
 * @returns 
 */
export const updateCountryStatusAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(updateCountryStatus(payload));
};

/**
 * @description To delete country status 
 * @param payload 
 * @returns 
 */
 export const deleteCountryAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(deleteCountry(payload));
};


/**
 * 
 * @param payload 
 * @param navigate 
 * @returns 
*/

export const updateCountryData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  let formData = {
    country_name: payload.countryName.value,
    id: payload.countryId.value,
  }
  const response: any = await updateCountryApi(formData);
  if (response) {
    toast(response.message);
   navigate('/countries');
  } else {

    if (response && response[0] && response[0].errorMessage) {
      let payload = {
        status: "error",
        message: response[0].errorMessage,
        display: true,
      };
      //dispatch(setNotification(payload));
    }
  }
};

