import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { updateCountryData } from '../../../redux/actions/country.actions';
import { useNavigate, useLocation } from "react-router-dom";
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import TextInput from '../../../common/components/TextInput';
import { OnChangeModel } from "../../../common/types/Form.types";

const EditBatch = () => {
    
    const breadCrums = [{ link: 'batches', title: 'Batches' }];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    let location: any = useLocation();
    const { countryInfo } = location.state;
    
    const [formState, setFormState] = useState({
        countryName: { error: "", value: countryInfo.country_name },
        countryId: { error: "", value: countryInfo.id }
    });

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } });
    }
    const submit = async () => {
        dispatch(updateCountryData(formState, navigate))
    }

    function isFormInvalid() {
        return (formState.countryName.error || !formState.countryName.value);
    }

    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }
    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={'Edit Country'} page={breadCrums} />

                    <div className="row">
                        <div className=" col-md-8 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Edit Country Info
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form className="user" action="javascript::void(0)" onSubmit={submit}>
                                        <div className="form-group">
                                            <TextInput id="input_country_name"
                                                field="countryName"
                                                value={formState.countryName.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Country Name"
                                                placeholder="Country Name" />
                                        </div>
                                        <button
                                            className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                            type="submit" >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default EditBatch;