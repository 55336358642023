import { deleteDriver, setDriverDetailsData, setDriverListData, updateDriverStatus } from "../reducers/driver.reducer";
import { getDriverDetailsApi, updateDriverApi } from "../fetchService/DriverManagement";
import { AppThunk } from "../store";
import { setLoading } from "../reducers/loader.reducer";
import { toast } from "react-toastify";
import { fetchBatchListData, postBatchData } from "../fetchService/Batch.service";
import { deleteTag, setTagListData, updateTagStatus } from "../reducers/tag.reducer";
import { fetchTagListData, postTagData, getTagDetailsApi } from "../fetchService/Tag.service";

export const TagListAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  const response: any = await fetchTagListData(payload);
  dispatch(setLoading(false));
  if (response.success) {
    dispatch(setTagListData(response.data));
  }
};

export const setTagData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  const response: any = await postTagData(payload);

  toast(response.message);
  if (response && response.success) {   
    navigate('/batches');
  }
};

/**
 * @description To update country status 
 * @param payload 
 * @returns 
 */
export const updateTagStatusAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(updateTagStatus(payload));
};

/**
 * @description To delete driver status 
 * @param payload 
 * @returns 
 */
export const deleteTagAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(deleteTag(payload));
};

export const updateTagData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  let formData = {
    full_name: payload.fullName.value,
    id: payload.id.value,
    email: payload.email.value,
    mobile_number: payload.mobile.value
  }

  const response: any = await  updateDriverApi(formData);
  if (response) {
    toast(response.message);
    navigate('/drivers');
  }
};

/**
 * 
 */

 export const TagDetailAction = (payload: any): AppThunk => async (dispatch: any) => {    
  const response: any = await getTagDetailsApi(payload);  
  dispatch(setDriverDetailsData(response.data));
};
