import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Notification, notificationManagementDefaultState } from "../interfaces/NotificationPayload";

import { AppState } from "../store";

const initialState: notificationManagementDefaultState = {
  notification: {
    status: "",
    message: "",
    display: false,
  },
};

export const addNotification = createSlice({
  name: "notificationManagementState",
  initialState,
  reducers: {
    setNotification: (
      state,
      action: PayloadAction<Notification>
    ) => ({
      ...state,
      notificationlist: action.payload,
    }),
  },
});

export const { setNotification } = addNotification.actions;

export const getAllNotificationList = (state: AppState) =>
  state.notificationManagementReducer.notification;

export { initialState as notificationState };
export default addNotification;
