import { setUserListData, updateUserStatus, deleteUser, setUserDetailsData } from "../reducers/user.reducer";
import { setLoading } from "../reducers/loader.reducer";
import { fetchUserListApi, getUserDetailsApi, postUserApi, updateUserApi } from "../fetchService/User.service";
import { AppThunk } from "../store";
import { toast } from "react-toastify";


export const UserListAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  const response: any = await fetchUserListApi(payload);
  dispatch(setLoading(false));
  if (response.data) {
    dispatch(setUserListData(response.data));
  }
};

// "full_name": "string",
// "email": "string",
// "password": "string",
// "mobile_number": 0

export const setUserData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  const response: any = await postUserApi(payload);
  if (response.status == 409) {
    // navigate('/users');
  } else {
    if (response)
    toast(response.message);
      navigate('/users');

  }
};

/**
 * @description To update country status 
 * @param payload 
 * @returns 
 */
export const updateUserStatusAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(updateUserStatus(payload));
};

/**
 * @description To delete country status 
 * @param payload 
 * @returns 
 */
export const deleteUserAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(deleteUser(payload));
  
};


export const updateUserData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  let formData = {
    full_name: payload.fullName.value,
    id: payload.id.value,
    email: payload.email.value,
    mobile_number: payload.mobile.value,
    address: payload.address.value,
    lat: payload.latitude.value,
    lng: payload.longitude.value,
    isAddressUpdated: payload.isAddressUpdate.value ,
    user_type:3
  }
  const response: any = await updateUserApi(formData);
  if (response) {
    toast(response.message);
    navigate('/users');
  } else {

    if (response && response[0] && response[0].errorMessage) {
      let payload = {
        status: "error",
        message: response[0].errorMessage,
        display: true,
      };
      //dispatch(setNotification(payload));
    }
  }
};

export const UserDetailAction = (payload: any): AppThunk => async (dispatch: any) => {
  const response: any = await getUserDetailsApi(payload);
  dispatch(setUserDetailsData(response.data));
};
