import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";

export const fetchTagListData = async (payload: any) => {
    return await ApiServices('get', payload, ApiEndPoints.TagList);    
};

export const postTagData = async (payload: any) => {
    const data = await ApiServices('post', payload, ApiEndPoints.AddTagBatch);
    return data;
};

export const getTagDetailsApi = async (payload: any) => {
    return await ApiServices('get', payload, ApiEndPoints.TagBatchDetails + payload.id);    
};