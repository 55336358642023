import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";

export const fetchDispatchListData = async (payload: any) => {
    return await ApiServices('get', payload, ApiEndPoints.DispatchTagList);    
};

export const postDispatchTagData = async (payload: any) => {
    const data = await ApiServices('post', payload, ApiEndPoints.AddDispatchTag);
    return data;
};

export const updateDispatchTagApi = async (payload: any) => {
    const data = await ApiServices('put', payload, ApiEndPoints.DriverUpdate);
    return data;
};

export const getDispatchTagApi = async (payload: any) => {
    const data = await ApiServices('get', payload, ApiEndPoints.DriverDetails + '/' + payload.id);
    return data;
};