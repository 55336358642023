import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import TextInput from '../../../common/components/TextInput';
import { OnChangeModel } from "../../../common/types/Form.types";
import { setDriverData } from '../../../redux/actions/driver.actions';
import { fetchLoundryListData } from '../../../redux/fetchService/LoundryManagement'
import SelectMulti from '../../../common/components/MultiSelect';

const Add = () => {

    const breadCrums = [{ link: 'drivers', title: 'Drivers' }];
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [laundryOptions, setLaundryOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [formState, setFormState] = useState({
        fullName: { error: "", value: "" },
        email: { error: "", value: "" },
        mobile: { error: "", value: "" },
        password: { error: "", value: "" },
        laundryAllocate: { error: "", value: "" },
    });

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } });
    }

    useEffect(() => {
        getList();
    }, []);

    useEffect(() => {
        let laundryOptions: any = [];
        selected.map((item: any) => {            
            laundryOptions.push({ laundry_id: item.value })
        })
        setFormState({ ...formState, laundryAllocate: { error: "", value: laundryOptions } });
    }, [selected]);


    async function getList() {
        let payload = {
            page: 1
        }
        const laundryResponse = await fetchLoundryListData(payload);
        console.log('kkkkk',laundryResponse);
        
        let options: any = [];
        laundryResponse?.data?.rows?.map(async (item: any) => {
            await options.push({ label: item.full_name, value: item.id })
        })

        setLaundryOptions(options);
        return {};
    }

    const submit = async () => {
        dispatch(setDriverData(formState, navigate));
    }

    function isFormInvalid() {
        return (formState.fullName.error || !formState.fullName.value);
        return (formState.email.error || !formState.email.value);
        return (formState.mobile.error || !formState.mobile.value);
        return (formState.password.error || !formState.password.value);
        return (formState.laundryAllocate.error || !formState.laundryAllocate.value);
    }

    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }

    const handelLaundySelection = (selectedItems: any) => {
        setSelected(selectedItems);
    }

    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={'Add New Driver'} page={breadCrums} />

                    <div className="row">
                        <div className=" col-md-6 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                       Add Driver Info
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form className="user" action='javascript:void(0)' onSubmit={submit}>
                                        <div className="form-group">
                                            <TextInput id="input_country_name"
                                                field="fullName"
                                                value={formState.fullName.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label=" Name"
                                                placeholder="Enter Name" />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="input_email"
                                                field="email"
                                                value={formState.email.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Email"
                                                placeholder="Enter Email" />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="input_mobile"
                                                field="mobile"
                                            value={formState.mobile.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={10}
                                                label="Mobile"
                                                placeholder="Enter Mobile Number" />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="input_password"
                                                field="password"
                                                value={formState.password.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Password"
                                                placeholder="Enter Password" />
                                        </div>
                                        <div className="form-group">
                                            <SelectMulti
                                                options={laundryOptions}
                                                value={selected}
                                                onChange={handelLaundySelection}
                                                labelledBy="Select Laundry"
                                            />
                                        </div>
                                        <button
                                            className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                            type="submit" >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Add;

