import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import TextInput from '../../../common/components/TextInput';
import { OnChangeModel } from "../../../common/types/Form.types";
import { setDriverData } from '../../../redux/actions/driver.actions';
import { fetchLoundryListData } from '../../../redux/fetchService/LoundryManagement'
import SelectMulti from '../../../common/components/MultiSelect';
import Const from '../../../helper/Const';
import { setBatchData } from '../../../redux/actions/batch.action';


const AddBatch = () => {
    
    const breadCrums = [{ link: 'tag batches', title: 'Tag Batches' }];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [typeOfTag, setTypeOfTag] = useState(['Laundry', 'Dry Cleaning']);
    const [sizeOfTag, setSizeOfTag] = useState([{ key: 30, value: '30 LBS'}, {key: 50,
        value: '50 LBS'}, {key: 80, value: '80 LBS'}]);
    const [tagFor, setTagFor] = useState(['Business', 'Family', 'Employee benefits']);    
    const [typeOfTagValue, setTypeOfTagValue] = useState("");
    const [sizeOfTagValue, setSizeOfTagValue] = useState("");
    const [tagForValue, setTagForValue] = useState("");
    const [formState, setFormState] = useState({
        numberOfTags: { error: "", value: "" },
        sizeOfTags: ""        
    });

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } });
    }

    const submit = async () => {
        let payload = {
            number_of_tags: formState.numberOfTags.value,
            // type_of_tag: typeOfTagValue,
            // tag_for: tagForValue,
            sizeOfTags: sizeOfTagValue,
        };

        dispatch(setBatchData(payload, navigate));
    }

    function isFormInvalid() {
        return (formState.numberOfTags.error || !formState.numberOfTags.value);        
    }
    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }

    const handleSelectTypeOfTag = (e: any) => {        
        setTypeOfTagValue(e.target.value);
    };

    const handleSelectSizeOfTag = (e: any) => {        
        setSizeOfTagValue(e.target.value);
    };

    const handleSelectTagFor = (e: any) => {        
        setTagForValue(e.target.value);
    };

    const typeOfTagOptions = () => {     
        return typeOfTag.map((item: any) => {
            return (
                <option value={item} key={item}>
                    {item}
                </option>
            );
        });
    };

    const sizeOfTagOptions = () => {     
        return sizeOfTag.map((item: any) => {
            return (
                <option value={item.key} key={item.value}>
                    {item.value}
                </option>
            );
        });
    };

    const tagForOptions = () => {     
        return tagFor.map((item: any, key: number) => {            
            return (
                <option value={item} key={item}>
                    {item}
                </option>
            );
        });
    };

    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={'Add New Tag Batch'} page={breadCrums} />

                    <div className="row">
                        <div className=" col-md-6 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                       Tag Batch Info
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form className="user" action='javascript:void(0)' onSubmit={submit}>
                                        <div className="form-group">
                                            <TextInput id="input_number_of_tags"
                                                field="numberOfTags"
                                                value={formState.numberOfTags.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label=" Number of Tag"
                                                placeholder="Number of Tag" />
                                        </div>                                       
                                        <div className="form-group">
                                            <label htmlFor="sizeOfTagSelect">Select Size Of Tag</label>
                                            <select
                                                className="form-control"
                                                id="sizeOfTagSelect"
                                                onChange={(e) => {
                                                    handleSelectSizeOfTag(e);
                                                }}
                                            >
                                                <option value={''} key={''}>Select Size Of Tag</option>
                                                {sizeOfTagOptions()}
                                            </select>
                                        </div>                                       

                                        {/* <div className="form-group">
                                            <label htmlFor="typeOfTagSelect">Select Type Of Tag</label>
                                            <select
                                                className="form-control"
                                                id="typeOfTagSelect"
                                                onChange={(e) => {
                                                    handleSelectTypeOfTag(e);
                                                }}
                                            >
                                                <option value={''} key={''}>Select Type Of Tag</option>
                                                {typeOfTagOptions()}
                                            </select>
                                        </div> */}

                                        {/* <div className="form-group">
                                            <label htmlFor="tagForSelect">Select Tag For</label>
                                            <select
                                                className="form-control"
                                                id="tagForSelect"
                                                onChange={(e) => {
                                                    handleSelectTagFor(e);
                                                }}
                                            >
                                                <option value={''} key={''}>Select Tag For</option>
                                                {tagForOptions()}
                                            </select>
                                        </div> */}

                                        <button
                                            className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                            type="submit" >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default AddBatch;

