
import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";


export const fetchUserListApi = async (payload: any) => {    
    return await ApiServices('get', payload, ApiEndPoints.UserList);
};

export const postUserApi = async (payload: any) => {
    return await ApiServices('post', payload, ApiEndPoints.UserAdd);    
};

export const updateUserApi = async (payload: any) => {
    return await ApiServices('put', payload, ApiEndPoints.UserUpdate);    
};

export const getUserDetailsApi = async (payload: any) => {
    return await ApiServices('get', payload, ApiEndPoints.UserDetails + payload.id);
};