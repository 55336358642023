import { deleteDriver, setDriverDetailsData, setDriverListData, updateDriverStatus } from "../reducers/driver.reducer";
import { setAlert } from "../reducers/Alert.reducer";
import { changeDriverPassword, fetchDriverListData, getDriverDetailsApi, postDriverData, updateDriverApi } from "../fetchService/DriverManagement";
import { AppThunk } from "../store";
import { setLoading } from "../reducers/loader.reducer";
import { toast } from "react-toastify";

export const DriverListAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  const response: any = await fetchDriverListData(payload);
  dispatch(setLoading(false));
  if (response.success) {
    dispatch(setDriverListData(response.data));
  }
};

export const setDriverData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  let formData = {
    full_name: payload.fullName.value,
    email: payload.email.value,
    mobile_number: payload.mobile.value,
    password: "qwerty",//payload.password.value,
    type: "4",
    user_type: "4",
    laundry: payload.laundryAllocate.value,
    // address: payload.address.value
  }

  const response: any = await postDriverData(formData);

  // toast(response.message);
  // if (response && response.success) {   
  //   navigate('/drivers');
  // } 
  if (response.status == 409) {
    // navigate('/users');
  } else {
    if (response)
      toast(response.message);
    navigate('/drivers');

  }
  // else {
  //   let payload = {
  //     title: response.message,
  //     type: "warning",
  //     display: true
  //   };
  //   dispatch(setAlert(payload)); 
  // }
};

/**
 * @description To update country status 
 * @param payload 
 * @returns 
 */
export const updateDriverStatusAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(updateDriverStatus(payload));
};

/**
 * @description To delete driver status 
 * @param payload 
 * @returns 
 */
export const deleteDriverAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(deleteDriver(payload));
};

export const updateDriverData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  let formData = {
    full_name: payload.fullName.value,
    id: payload.id.value,
    email: payload.email.value,
    mobile_number: payload.mobile.value,
    laundry_allocate: payload.laundryAllocate.value,
    password: payload.password.value
  }

  const response: any = await updateDriverApi(formData);
  if (response) {
    toast(response.message);
    navigate('/drivers');
  }
};

/**
 * 
 */

export const DriverDetailAction = (payload: any): AppThunk => async (dispatch: any) => {
  const response: any = await getDriverDetailsApi(payload);
  dispatch(setDriverDetailsData(response.data));
};

export const updateDriverPassword = (payload:{id:string, password: string}, navigate:any):AppThunk => async(dispatch:any) => {
    let formData = {
      id: payload.id,
      password: payload.password
    }

    const response:any = await changeDriverPassword(formData)

    if(response){
      toast(response.message)
      navigate(`/drivers`);
    }
} 
