import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dispatchTagItem, dispatchTagDefaultState, dispatchTagPage } from "../interfaces/DispatchTagInterface";
import { AppState } from "../store";

const initialState: dispatchTagDefaultState = {
    dispatchTagList: [],
    totalRecords: 0,
    dispatchTagDetails: {}
};

export const dispatchTagReducer = createSlice({
    name: "dispatchTagState",
    initialState,
    reducers: {
        setDispatchTagListData: (state, action: PayloadAction<dispatchTagPage>) => ({
            ...state,
            dispatchTagList: action.payload.rows,
            totalRecords: action.payload.count
        }),
        setDispatchTagDetailsData: (state, action: PayloadAction<dispatchTagItem>) => ({
            ...state,
            dispatchTagDetails: action.payload
        }),
        updateDispatchTagStatus: (state, action: PayloadAction<dispatchTagItem>) => ({
            ...state,
            dispatchTagList: state.dispatchTagList.map(item => item.id === action.payload.id ?
                { ...item, status: action.payload.status } : item
            )
        }),
        deleteDispatchTag: (state, action: PayloadAction<dispatchTagItem>) => ({
            ...state,
            dispatchTagList: state.dispatchTagList.filter(item => item.id != action.payload.id)
        })
    },
});

export const { setDispatchTagListData, updateDispatchTagStatus, deleteDispatchTag, setDispatchTagDetailsData } = dispatchTagReducer.actions;

export const getDispatchTagListData = (state: AppState) => {
    return ({ rows: state.dispatchTagReducer.dispatchTagList, count: state.dispatchTagReducer.totalRecords })
};

export const getDispatchTagDetailsData = (state: AppState) => state.dispatchTagReducer.dispatchTagDetails

export { initialState as dispatchTagState };
export default dispatchTagReducer;
