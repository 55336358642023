import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideBar from "../../layout/sidebar/SideBar";
import BreadCrum from "../../layout";
import "../add/add.css";
import TextInput from "../../../common/components/TextInput";
import { OnChangeModel } from "../../../common/types/Form.types";
import { StateListAction } from "../../../redux/actions/state.actions";
import { CountryListAction } from "../../../redux/actions/country.actions";
import { getCountryListData } from "../../../redux/reducers/country.reducer";
import { countryItem } from "../../../redux/interfaces/CountryInterface";
import { getStateListData } from "../../../redux/reducers/State.reducer";
import { getCityListData } from "../../../redux/reducers/city.reducer";
import { StateItem } from "../../../redux/interfaces/StateInterface";
import { cityItem } from "../../../redux/interfaces/CityInterface";
import { CityListAction } from "../../../redux/actions/city.actions";
import { ZipcodeListAction } from "../../../redux/actions/zipcode.actions";
import { getZipcodeListData } from "../../../redux/reducers/zipcode.reducer";
import { zipcodeItem } from "../../../redux/interfaces/ZipcodeInterface";
import { areaItem } from "../../../redux/interfaces/AreaInterface";
import SelectMulti from '../../../common/components/MultiSelect';

import { setAreaData } from "../../../redux/actions/area.action";
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { AnyARecord } from "dns";

const AddArea = () => {

    const breadCrums = [{ link: 'areas', title: 'Areas' }];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [areaName, setAreaName] = useState("");
    const countries = useSelector(getCountryListData);
    const states = useSelector(getStateListData);
    const cities = useSelector(getCityListData);
    const zipcodes = useSelector(getZipcodeListData);
    const [countryData, setCountryData] = useState<countryItem[]>([]);
    const [state, setState] = useState<StateItem[]>([]);
    const [city, setCity] = useState<cityItem[]>([]);
    const [zipcode, setZipcode] = useState<zipcodeItem[]>([]);
    const [selected, setSelected] = useState<any>([]);
    const [zipcodeId, setZipcodeId] = useState<{id:any}[]>([]);
    const [countryId, setCountryId] = useState("");
    const [stateId, setStateId] = useState("");
    const [cityId, setCityId] = useState("");
    const [areaError, setAreaError] = useState("");
    const [formState, setFormState] = useState({
        areaName: { error: "", value: "" },
    });
    const [address, setAddress] = useState("");
    // const [latitudeId, setLatitudeId] = useState({});
    // const [longitudeId, setLongitudeId] = useState({});
    // console.log(latitudeId, "sucessos lat")
    // console.log(longitudeId, "sucessos lng")

    const handlechange = (e: any) => {
        setAddress(e.target.value);
    }

    useEffect(() => {
        dispatch(CountryListAction());
        return () => { };
    }, [dispatch]);

    useEffect(() => {
        let data: any = [];
        countries.map((item: any) => {
            data.push(item);
        });
        setCountryData(data);
    }, [countries]);

    useEffect(() => {
        let data: any = [];
        states.map((item: any) => {
            data.push(item);
        });
        setState(data);
    }, [states]);

    useEffect(() => {
        let data: any = [];
        zipcodes.map((item: any) => {
            // console.log("item -----------------", item)
            data.push(item);
        });
        setZipcode(data);

    }, [zipcodes, cityId]);

    // useEffect(() => {
    //     let zipcodes: any = [];
    //     selected.map((item: any) => {
    //         zipcodes.push({ area_id: item.value })
    //     setFormState({ ...formState, area: { error: "", value: item.value } });
    //     })
    // }, [selected]);

    useEffect(() => {
        let payload = {
            country_id: countryId
        }
        dispatch(StateListAction(payload));
        return () => { };
    }, [dispatch, countryId]);

    useEffect(() => {
        let payload = {
            state_id: stateId
        }
        dispatch(CityListAction(payload));
        return () => { };
    }, [dispatch, stateId]);

    useEffect(() => {
        let payload:any = {
            page: 1,
            limit: 1000
        };
        if(cityId != "") {
            payload.city_id = cityId
        };
        dispatch(ZipcodeListAction(payload));
        return () => { };
    }, [dispatch, address, cityId]);

    useEffect(() => {
        let data: any = [];
        cities.map((item: any) => {
            data.push(item);
        });
        setCity(data);
    }, [cities, stateId]);

    useEffect(() => {
        if(zipcodes.length === 1){
            setZipcodeId([{
                id: zipcodes[0]?.id
            }])
            
            setSelected([{  
                label: zipcodes[0].locality_name, 
                value: zipcodes[0].id
            }])
        }
    }, [zipcodes])

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({
            ...formState,
            [model.field]: { error: model.error, value: model.value },
        });
        setAreaError("");
        setAreaName(formState.areaName.value);
    }
    const submit = async () => {

        const payload = {
            area_name: formState.areaName.value,
            city_id: cityId,
            state_id: stateId,
            zipcode_id: zipcodeId,
            // latitude: latitudeId,
            // longitude: longitudeId,
        };


        if (formState.areaName.value.length == 0) {
            setAreaError("Please Enter Area Field");
            return false;
        }

        dispatch(setAreaData(payload, navigate));
    };
    // 
    function isFormInvalid() {
        return formState.areaName.error || !formState.areaName.value;
    }

    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }

    const countryOptions = () => {
        return countryData.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.country_name}
                </option>
            );
        });
    };

    const handleSelect = (e: any) => {
        setCountryId(e.target.value);
    };

    const handleSelectState = (e: any) => {
        setStateId(e.target.value);
    };

    const handleSelectCity = (e: any) => {
        setCityId(e.target.value);

    };

    const handleSelectZipcode = (e: any) => {
        setZipcodeId(e.target.value);

    };

    // const handleSelectZipcode = (e: any) => {
    //     setZipcodeId(e.target.value)
    //     geocodeByAddress(e.target.value)
    //         .then((result) => getLatLng(result[0]))
    //         .then(({ lat, lng }) => {
    //             setLatitudeId({ lat })
    //             setLongitudeId({ lng })
    //         })
    //         .catch((error) => console.log(error, "latlng Error"))
    //     console.log(e.target.value, "Zip Value")
    // }

    const stateOptions = () => {
        return state.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.state_name}
                </option>
            );
        });
    };

    const cityOptions = () => {

        return city.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.city_name}
                </option>
            );
        });
    };

    const zipcodeOptions = () => {
        // console.log("=-=as-d=a-sd=a-sd=",[{ id: zipcode[0]?.id}])
        return zipcode.map((item: any) => {
            return {
                label: item.locality_zipcode,
                value: item.id
            }
        });
    };

    useEffect(() => {
        console.log("selected -------------------------", selected)
        const selectedItems = selected?.map((item:{label:string, value:string}) => {
            return {id: item.value}
        })
        setZipcodeId(selectedItems)
    }, [selected])

    // const zipcodeOptions = () => {
    //     return zipcode.map((item: any) => {
    //         geocodeByAddress(item.locality_zipcode)
    //             .then((result) => getLatLng(result[0]))
    //             .catch((error) => console.error(error, "Error"))
    //         return (<>
    //             <option value={item.locality_zipcode} key={item.locality_zipcode}>
    //                 {item.locality_zipcode}
    //             </option>
    //             </>
    //         )
    //     })
    // }

    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={"Add New Area"} page={breadCrums} />

                    <div className="row">
                        <div className=" col-md-8 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">Add Area Info</div>
                                </div>
                                <div className="card-body">
                                    <form
                                        className="user"
                                        action="javascript:void(0)"
                                        onSubmit={submit}
                                    >
                                        <div className="form-group">
                                            <label htmlFor="countrySelect">Select Country</label>
                                            <select
                                                className="form-control"
                                                id="countrySelect"
                                                onChange={(e) => {
                                                    handleSelect(e);
                                                }}
                                            >
                                                <option value={''} key={''}>Select Country</option>
                                                {countryOptions()}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="stateSelect">Select State</label>
                                            <select
                                                className="form-control"
                                                id="stateSelect"
                                                onChange={(e) => {
                                                    handleSelectState(e);
                                                }}
                                            >
                                                <option value={''} key={''}>Select State</option>
                                                {stateOptions()}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="citySelect">Select City</label>
                                            <select
                                                className="form-control"
                                                id="citySelect"
                                                onChange={(e) => { handleSelectCity(e); }}
                                            >
                                                <option value={''} key={''}>Select City</option>
                                                {cityOptions()}
                                            </select>
                                        </div>

                                        {/* <div className="form-group">
                                            <label htmlFor="zipcodeSelect">Select Zipcode</label>
                                            <select
                                                className="form-control"
                                                id="zipcodeSelect"
                                                onChange={(e) => { handleSelectZipcode(e); }}
                                                onSelect={handlechange}
                                            >
                                                <option value={''} key={''}>Select Zipcode</option>
                                                {zipcodeOptions()}
                                            </select>
                                        </div> */}

                                        <div className="form-group">
                                            <SelectMulti
                                                options={zipcodeOptions()}
                                                value={selected}
                                                onChange={setSelected}
                                                labelledBy="Select Zipcode"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextInput
                                                id="input_area_name"
                                                field="areaName"
                                                value={formState.areaName.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Area"
                                                placeholder="Area"
                                            />
                                            {areaError && (
                                                <div className="error-msg">{areaError}</div>
                                            )}
                                        </div>
                                        <button
                                            className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddArea;


