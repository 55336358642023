
import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";

export const fetchStateListData = async (payload: any) => {
    return await ApiServices('get', payload, ApiEndPoints.StateList);     
};

export const fetchCountryListData = async (payload: any) => {
    return await ApiServices('get', {}, ApiEndPoints.CountryList);     
};

export const postStateData = async (payload: any) => {
    return await ApiServices('post', payload, ApiEndPoints.StateAdd);    
};

export const editStateData = async (payload: any) => {
    return await ApiServices('put', payload, ApiEndPoints.StateUpdate);
};