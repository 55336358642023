
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import TextInput from '../../../common/components/TextInput';
import { OnChangeModel } from "../../../common/types/Form.types";
import { updateLoundryData } from '../../../redux/actions/loundry.actions';
import SelectMulti from '../../../common/components/MultiSelect';
import { getAreas } from '../../../redux/fetchService/Area.service';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import SingleSelect from '../../../common/components/SingleSelect';


const EditLoundry = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let location: any = useLocation();

    const { loundryInfo } = location.state;
    const breadCrums = [{ link: 'laundry', title: 'Laundries' }];
    const [areaOptions, setAreaOptions] = useState([]);
    const [selected, setSelected] = useState<{label:string, value:string}>();
    
    console.log("=-asd=-s=fd-sdf-s=d-f=s-df=",loundryInfo)
    const [formState, setFormState] = useState({
        id: { error: "", value: loundryInfo.id },
        fullName: { error: "", value: loundryInfo.full_name },
        address: { error: "", value: loundryInfo.laundry.address },
        email: { error: "", value: loundryInfo.email },
        mobile: { error: "", value: loundryInfo.mobile_number },
        area: { error: "", value: "" },
        password: { error: "", value: "qwerty" },
        latitude: { error: "", value: loundryInfo.laundry.latitude },
        longitude: { error: "", value: loundryInfo.laundry.longitude }
    });
    
    
    
     useEffect(() => {
        getServiceAreas();
        return () => { };
    }, []);


    // For auto selected areas
    useEffect(() => {
        let areaOptions: any = [];
        loundryInfo?.laundry?.laundry_area.map((item: any) => {
            areaOptions.push({ label: item.area.area_name, value: item.area.id })
        })
        setSelected(areaOptions);
        setFormState({ ...formState, area: { error: "", value: areaOptions } });
    }, []);

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } });
    }

    const submit = async () => {
        dispatch(updateLoundryData(formState, navigate))
    }

    function isFormInvalid() {
        return (formState.id.error || !formState.id.value);
        return (formState.fullName.error || !formState.fullName.value);
        return (formState.address.error || !formState.address.value);
        return (formState.email.error || !formState.email.value);
        return (formState.mobile.error || !formState.mobile.value);

    }

    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }


    useEffect(() => {
        let areaOptions: any = [];
        console.log("selected ====================", selected);
        // selected.map((item: any) => {
        //     areaOptions.push({ area_id: item.value })
        // })
        setFormState({ ...formState, area: { error: "", value: selected?.value || '' } });
    }, [selected]);

    async function getServiceAreas() {

        let payload = {
            page: 1
        }
        let areasResponse = await getAreas(payload);
        if (areasResponse.success) {

            let options: any = [];
            areasResponse?.data?.map(async (item: any) => {
                await options.push({ label: item.area_name, value: item.id })
            });
            setAreaOptions(options);
        }
        return {};
    }
    // useEffect(() => {
    //     handle(formState.address.value); 
    // }, [formState.address.value]);
    
    const handle =  async(val:any) => {
        await  geocodeByAddress(val)
           .then(results => getLatLng(results[0]))
           .then((latLng:any) => {
            console.log("latLng ----------", latLng)
             setFormState({ ...formState, latitude: { error: "", value:latLng.lat}, longitude: { error: "", value:latLng.lng}, address: {error: "", value: val}})
             
         }).catch(error => console.error('Error', error));
     }
    
    const handleGoogleAddress = (e: any) => {
        // setFormState({...formState, address: {error: "", value: e}})
        handle(e)
     }

    const handleSelection = (val:any) => {
        console.log("-=d-s=d-s=dsd=s-d=-sd-=",val, selected)
        // console.log()
        // setSelected()
    }

    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={'Edit Loundry'} page={breadCrums} />

                    <div className="row">
                        <div className=" col-md-8 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Edit Loundry Info
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form className="user" action='javascript:void(0)' onSubmit={submit}>
                                        <div className="form-group">
                                            <TextInput id="input_country_name"
                                                field="fullName"
                                                value={formState.fullName.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label=" Name"
                                                placeholder="Full Name" />
                                        </div>

                                        <div className="form-group">
                                            <TextInput id="input_email"
                                                field="email"
                                                value={formState.email.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Email"
                                                placeholder="Email" />
                                        </div>
                                        {/* <div className="form-group">
                                            <TextInput id="address"
                                                field="address"
                                                value={formState.address.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Address"
                                                placeholder="Address " />
                                        </div> */}
                                          <p>Address</p>
                                    <PlacesAutocomplete
                                        value={formState.address.value}
                                        onChange={
                                            (e) => setFormState({...formState, address: {error: "", value: e}})
                                        }
                                        onSelect={handleGoogleAddress}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                            {...getInputProps({
                                                placeholder: 'Search Address ...',
                                                className: 'location-search-input',
                                             })}
                                             style={{width:"100%",height:'35px'}}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                                );
                                            })}
                                            </div>
                                        </div>
                                        )}
                                    </PlacesAutocomplete>
                                    {formState.address.value.length > 0 ?
                            <>
                                        <div className="form-group">
                                            <TextInput id="latitude"
                                                field="latitude"
                                                value={formState.latitude.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Latitude"
                                                placeholder="Enter Latitude " />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="longitude"
                                                field="longitude"
                                                value={formState.longitude.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Longitude"
                                                placeholder="Enter Longitude " />
                                        </div>
                            </>    
                                        :null
                            }

                                        <div className="form-group">
                                            <TextInput id="mobile"
                                                field="mobile"
                                                value={formState.mobile.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Mobile"
                                                placeholder="Mobile " />
                                        </div>
                                        <div className="form-group">
                                            <SingleSelect 
                                                labelledBy="Select Service Areas" 
                                                options={areaOptions}
                                                value={selected}
                                                selectStyle={{}}
                                                onChange={setSelected}
                                            />
                                            {/* <SelectMulti
                                                options={areaOptions}
                                                value={selected}
                                                onChange={setSelected}
                                                labelledBy="Select Service Areas"
                                            /> */}
                                        </div>
                                        <button
                                            className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                            type="submit" >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default EditLoundry;

