import * as React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer ptb-20">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="copy_right">
                            <p>
                                2018 © Dashboard Theme By
                                <Link to="/"> Breezer</Link>
                            </p>
                        </div>
                        <Link id="back-to-top" to="/">
                            <i className="ion-android-arrow-up"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;