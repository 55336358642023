import React from 'react';
import { HashRouter, Route, Routes} from 'react-router-dom';
import Login from '../pages/auth/login';

const PublicRouter = () => {
    return (
        <HashRouter>            
            <Routes>
                <Route path="/" element={<Login />} />                                    
                <Route path="/login" element={<Login />} />                
            </Routes>
        </HashRouter>
    );
}

export default PublicRouter;