import * as React from 'react';
import { Link, useParams,useLocation } from "react-router-dom";
import SideBar from '../../layout/sidebar/SideBar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailListData } from '../../../redux/reducers/details.reducer';
import { detailItem } from '../../../redux/interfaces/DetailInterface';
import { getDriverDetailsData, setDriverDetailsData } from '../../../redux/reducers/driver.reducer';
import { DriverDetailAction } from '../../../redux/actions/driver.actions';
import { driverItem } from '../../../redux/interfaces/DriverInterface';
import BreadCrum from '../../layout';

export default function DriverDetails() {
	
	let { id } = useParams();	
	const breadCrums = [{ link: 'drivers', title: 'Drivers' }];
	const dispatch = useDispatch();
	let location: any = useLocation();

    const {driverInfo}  = location.state;

	const driverDetails = useSelector(getDriverDetailsData);	
	const [driverData, setDriverData] = useState<driverItem>();
	const [laundryList, setLaundryList] = useState('')
	
	// 
	useEffect(() => {
		let payload: any = {
			id: id
		}
		dispatch(DriverDetailAction(payload));
		
		return () => { };
	}, [dispatch]);

	useEffect(() => {	
		console.log('as-da-s0d-as0da-sd', driverDetails)	
		const getList = driverDetails?.laundry_allocate?.map((laundry:any) => laundry.laundryInfo.full_name)
		setLaundryList(getList?.toString())
		console.log('-a0sd-0as-d0a-sd0-asd', getList)
		setDriverData(driverDetails);
	}, [driverDetails]);

	return (
		<div className="container_full">
			<div className="side_bar scroll_auto">
				<SideBar /> 
			</div>
			<div className="content_wrapper">
				<div className="container-fluid mb-5">
				<BreadCrum title={''} page={breadCrums} /> 

					<div className="panel">
						<div className="panel-heading"> 
							<h3 className="panel-title">Driver Details</h3>
							<div style={{ float: 'right', display:'flex', gap:'10px'}}>
								<Link
								to={`/drivers/edit/${id}`}
								state={{ driverInfo:driverInfo }}
								className='border p-1 px-2'
								><h3 className="panel-title">Edit</h3></Link>
							<Link
								to={`/drivers/updatepassword/${id}`}
								state={{ driverInfo:driverInfo }}
								className='border p-1 px-2'
								><h3 className="panel-title">Change Password</h3></Link>
							</div>
							
						</div>
						<div className="panel-content panel-about">
							
							<table>
								<tbody>
									<tr>
										<th>
											<i className="fa fa-user"></i>Full Name</th>
										<td>{driverInfo?.full_name ?? '-'}</td>
									</tr>
									<tr>
										<th>
											<i className="fa fa-envelope"></i>Email</th>
										<td>{driverInfo?.email ?? '-'}</td>
									</tr>
							
									<tr>
										<th>
											<i className="fa fa-mobile-phone"></i>Mobile No.</th>
										<td>
											<a href="javascript::void(0)" className="btn-link">{driverInfo?.mobile_number ?? '-'}</a>
										</td>
									</tr>

									<tr>
										<th>
											<i className="fa fa-truck"></i>Laundry Assigned</th>
										<td>
											<a href="javascript::void(0)" className="btn-link">{laundryList}</a>
										</td>
									</tr>
									
								</tbody>
							</table>
						</div>
						
					</div>
				</div>
			</div>
		</div>
	);
}