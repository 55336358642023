
import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";

export const updateStatus = async (payload: any) => {    
    return await ApiServices('put', payload, ApiEndPoints.StatusUpdate);    
};

export const deleteType = async (payload: any) => {    
    return await ApiServices('delete', payload, ApiEndPoints.StatusDelete);    
};
