import * as React from 'react';
import { useLocation, useParams } from "react-router-dom";
import SideBar from '../../layout/sidebar/SideBar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userItem } from '../../../redux/interfaces/UserManagementInterface';
import { getUsersDetailsData } from '../../../redux/reducers/user.reducer';
import { UserDetailAction } from '../../../redux/actions/user.action';
import BreadCrum from '../../layout';
import { Link } from 'react-router-dom';

export default function UsersDetails() {

	let { id } = useParams();
	const dispatch = useDispatch();
	const UsersDetails = useSelector(getUsersDetailsData);
	let location: any = useLocation();
	const { userInfo } = location.state;
	const [userData, setuserData] = useState<userItem>();
	//const [address, setAddress] = useState<any>();	
	let address = UsersDetails.user_address;
	const breadCrums = [{ link: 'users', title: 'Users' }];

	useEffect(() => {
		let payload: any = {
			id: id
		}
		dispatch(UserDetailAction(payload));
		return () => { };
	}, [dispatch]);

	useEffect(() => {
		setuserData(UsersDetails);
	}, [UsersDetails]);

	return (
		<div className="container_full">
			<div className="side_bar scroll_auto">
				<SideBar />
			</div>

			<div className="content_wrapper">
				<div className="container-fluid mb-5">
					<BreadCrum title={''} page={breadCrums} />

					<div className="panel">
						<div className="panel-heading">
							<h3 className="panel-title">Users Details</h3>
							<div style={{ float: 'right' }}>
								<Link
									to={`/Users/edit/${id}`}
									state={{ userInfo: userInfo }}

								><h3 className="panel-title">Edit</h3></Link>
							</div>
						</div>
						<div className="panel-content panel-about">

							<table>
								<tbody>
									<tr>
										<th>
											<i className="fa fa-user"></i>Full Name</th>
										<td>{userData?.full_name ?? '-'}</td>
									</tr>
									<tr>
										<th>
											<i className="fa fa-envelope"></i>Email</th>
										<td>{userData?.email ?? '-'}</td>
									</tr>

									<tr>
										<th>
											<i className="fa fa-mobile-phone"></i>Mobile No.</th>
										<td>
											<a href="javascript::void(0)" className="btn-link">{userData?.mobile_number ?? '-'}</a>
										</td>
									</tr>
									<tr>
										<th>
											<i className="fa fa-mobile-phone"></i>Device Id</th>
										<td>
											<a href="javascript::void(0)" className="btn-link">{userData?.device_id ? userData?.device_id : '-'}</a>
										</td>
									</tr>
									<tr>
										<th>
											<i className="fa fa-address-card-o"></i>Address</th>
										<td>
											<a href="javascript::void(0)" className="btn-link">{address?.address ?? '-'}</a>
										</td>
									</tr>
									<tr>
										<th>
											<i className="fa fa-globe"></i>City</th>
										<td>
											<a href="javascript::void(0)" className="btn-link">{address?.city?.city_name ?? '-'}</a>
										</td>
									</tr>

									<tr>
										<th>
											<i className="fa fa-globe"></i>Zipcode</th>
										<td>
											<a href="javascript::void(0)" className="btn-link">{address?.zipcodes?.locality_zipcode ?? '-'}</a>
										</td>
									</tr>

									<tr>
										<th>
											<i className="fa fa-globe"></i>State</th>
										<td>
											<a href="javascript::void(0)" className="btn-link">{address?.state?.state_name ?? '-'}</a>
										</td>
									</tr>

									<tr>
										<th>
											<i className="fa fa-globe"></i>Country</th>
										<td>
											<a href="javascript::void(0)" className="btn-link">{address?.country?.country_name ?? '-'}</a>
										</td>
									</tr>

								</tbody>
							</table>
						</div>

					</div>
				</div>
			</div>
		</div>
	);
}