import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userDefaultState } from "../interfaces/UserInterface";
import { AppState } from "../store";

const initialState: userDefaultState = {
    userdata: [],
    isUserLogin: false,
    userList: [],
    totalRecords: 0,
    userDetails: undefined
};

export const userManagementReducer = createSlice({
    name: "userState",
    initialState,
    reducers: {
        setUserData: (
            state,
            action: PayloadAction<any>
        ) => ({
            ...state,
            userdata: action.payload,
            isUserLogin: true,
        }),
    },
});

export const {
    setUserData
} = userManagementReducer.actions;

export const getUserData = (state: AppState) =>
    state.UserReducer.userdata;

export const getUserLogin = (state: AppState) =>
    state.UserReducer.isUserLogin;

export { initialState as userManagementState };
export default userManagementReducer;
