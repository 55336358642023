import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tagItem, tagDefaultState, tagPage } from "../interfaces/TagInterface";
import { AppState } from "../store";

const initialState: tagDefaultState = {
    tagList: [],
    totalRecords: 0,
    tagDetails: {}  
};

export const tagReducer = createSlice({
  name: "tagState",
  initialState,
  reducers: {
    setTagListData: ( state, action: PayloadAction<tagPage> ) => ({
      ...state,
      tagList: action.payload.rows,
      totalRecords: action.payload.count
    }),
    setTagDetailsData: ( state, action: PayloadAction<tagItem> ) => ({
      ...state,
      tagDetails: action.payload
    }),
    updateTagStatus:( state, action: PayloadAction<tagItem> ) => ({
      ...state, 
      tagList: state.tagList.map(item=> item.id === action.payload.id ? 
        { ...item, status: action.payload.status} : item
    ) 
    }),
    deleteTag:( state, action: PayloadAction<tagItem> ) => ({
      ...state, 
      tagList: state.tagList.filter(item => item.id != action.payload.id) 
    })
  },
});

export const { setTagListData, updateTagStatus, deleteTag, setTagDetailsData } = tagReducer.actions;

export const getTagListData = (state: AppState) => {
  return({rows: state.tagReducer.tagList, count: state.tagReducer.totalRecords})
};

export const getTagDetailsData = (state: AppState) => state.tagReducer.tagDetails

export { initialState as driverState };
export default tagReducer;
