import React from 'react';
import AppRouter from './router/AppRouter';
import PublicRouter from './router/PublicRouter';

class App extends React.Component {
  
  render () {
    const isLogin = window.sessionStorage.getItem("@userToken")
    return (
      <>
        {isLogin ? <AppRouter  /> : <PublicRouter />}
      </>
    );
  }
}

export default App;
