import { AppThunk } from "../store";
import { fetchStateListData, postStateData, editStateData } from "../fetchService/StateManagement";
import { postCityData, updateCityApi } from "../fetchService/CityManagement ";
import { deleteState, setStateListData, updateStateStatus } from "../reducers/State.reducer";
import { toast } from "react-toastify";

export const StateListAction = (payload: any): AppThunk => async (dispatch: any) => {
  const response: any = await fetchStateListData(payload);
  if (response.data) {  
    dispatch(setStateListData(response.data));
  }else{
    dispatch(setStateListData([]));
  }
};

export const setStateData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {  
  const response: any = await postStateData(payload);
  toast(response.message);
  if (response) {
    navigate('/states');
  }
};

export const setCityData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  const response: any = await postCityData(payload);
  toast(response.message);
  if (response) {
    navigate('/cities');
  }
};

export const updateCityData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  const response: any = await updateCityApi(payload);
  
  console.log('response', response);

  if (response) {
    toast(response.message);
    navigate('/cities');
  }
};

export const updateStateData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  
  const response: any = await editStateData(payload);
  toast(response.message);
  if (response) {
    navigate('/states');
  }
};

export const updateStateStatusAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(updateStateStatus(payload));
};


/**
 * @description To delete country status 
 * @param payload 
 * @returns 
 */
 export const deleteStateAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(deleteState(payload));
};
