
import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";
import { AppThunk } from "../store";

export const fetchCityListData = async (payload: any) => {    
    return await ApiServices('get', payload, ApiEndPoints.CityList);
};

export const postCityData = async (payload: any) => {
    return await ApiServices('post', payload, ApiEndPoints.CityAdd);    
};

export const updateCityApi = async (payload: any) => {
    return await ApiServices('put', payload, ApiEndPoints.CityUpdate);    
};