import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideBar from "../../layout/sidebar/SideBar";
import BreadCrum from "../../layout";
import TextInput from "../../../common/components/TextInput";
import { OnChangeModel } from "../../../common/types/Form.types";
import { setCityData, StateListAction } from "../../../redux/actions/state.actions";
import { CountryListAction } from "../../../redux/actions/country.actions";
import { getCountryListData } from "../../../redux/reducers/country.reducer";
import { countryItem } from "../../../redux/interfaces/CountryInterface";
import { getStateListData } from "../../../redux/reducers/State.reducer";
import { StateItem } from "../../../redux/interfaces/StateInterface";


const AddCity = () => {
    
    const breadCrums = [{ link: 'cities', title: 'Cities' }];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cityName, setCityName] = useState("");
    const countries = useSelector(getCountryListData);
    const states = useSelector(getStateListData);
    
    const [countryData, setCountryData] = useState<countryItem[]>([]);
    const [state, setState] = useState<StateItem[]>([]);
    const [countryId, setCountryId] = useState("");
    const [stateId, setStateId] = useState("");

    const [formState, setFormState] = useState({
        cityName: { error: "", value: "" },
    });

    useEffect(() => {
        dispatch(CountryListAction());
        return () => { };
    }, [dispatch]);


    useEffect(() => {
        let data: any = [];
        countries.map((item: any) => {
            data.push(item);
        });
        setCountryData(data);
    }, [countries]);

    useEffect(() => {
        let data: any = [];
        states.map((item: any) => {
            data.push(item);
        });
        setState(data);
    }, [states]);

    useEffect(() => {
        getStates();
        return () => { };
    }, [countryId]);

    const getStates = () => {
        let payload = {
            country_id: countryId
        };
        dispatch(StateListAction(payload));        
    }

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({
            ...formState,
            [model.field]: { error: model.error, value: model.value },
        });        
    }
    const submit = async () => {
        const payload = {
            city_name: formState.cityName.value,
            state_id: stateId,
            zipcode: 0
        };
        dispatch(setCityData(payload, navigate));
    };

    function isFormInvalid() {
        return formState.cityName.error || !formState.cityName.value;
    }

    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }

    const countryOptions = () => {
        return countryData.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.country_name}
                </option>
            );
        });
    };

    const handleSelect = (e: any) => {
        setCountryId(e.target.value);
        getStates();
    };
    
    const handleSelectState = (e: any) => {
        setStateId(e.target.value);
    };

    const stateOptions = () => {     
        return state.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.state_name}
                </option>
            );
        });
    };

    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={"Add New City"} page={breadCrums} />

                    <div className="row">
                        <div className=" col-md-8 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">Add City Info</div>
                                </div>
                                <div className="card-body">
                                    <form
                                        className="user"
                                        action="javascript:void(0)"
                                        onSubmit={submit}
                                    >
                                        <div className="form-group">
                                            <label htmlFor="countrySelect">Select Country</label>
                                            <select
                                                className="form-control"
                                                id="countrySelect"
                                                onClick={(e) => {
                                                    handleSelect(e);
                                                }}
                                            >
                                                <option value={''} key={''}>Select Country</option>
                                                {countryOptions()}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="stateSelect">Select State</label>
                                            <select
                                                className="form-control"
                                                id="stateSelect"
                                                onClick={(e) => {
                                                    handleSelectState(e);
                                                }}
                                            >
                                                <option value={''} key={''}>Select State</option>
                                                {stateOptions()}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <TextInput
                                                id="input_country_name"
                                                field="cityName"
                                                value={formState.cityName.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="City Name"
                                                placeholder="City Name"
                                            />
                                        </div>
                                        <button
                                            className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCity;
