import { setCountryListData, updateCountryStatus, deleteCountry } from "../reducers/country.reducer";
import { setLoading } from "../reducers/loader.reducer";
import { fetchCountryListData, postCountryData, updateCountryApi } from "../fetchService/CountryManagement";
import { AppThunk } from "../store";
import { toast } from "react-toastify";
import { fetchSubscriptionPlans } from "../fetchService/Stripe.service";
import { setSubscriptionPlansData } from "../reducers/stripe.reducer";

export const PlanListAction = (): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  const response: any = await fetchSubscriptionPlans();  
  dispatch(setLoading(false));  
  if (response.result) {
    dispatch(setSubscriptionPlansData(response.result.data));
  }
};
