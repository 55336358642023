import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import { setBatchData } from '../../../redux/actions/batch.action';
import { fetchUserListApi } from '../../../redux/fetchService/User.service';
import { fetchDriverListData } from '../../../redux/fetchService/DriverManagement';
import { fetchTagListData } from '../../../redux/fetchService/Tag.service';
import { setDispatchTagData } from '../../../redux/actions/dispatchTag.action';


const AssignDispatchTag = () => {

    const breadCrums = [{ link: 'dispatch-tags', title: 'Dispatch Tags' }];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [userValue, setUserValue] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagValue, setTagValue] = useState([]);
    const [formState, setFormState] = useState({
        numberOfTags: { error: "", value: "" }
    });

    useEffect(() => {
        getUsers();
        getTags();
    }, [])
   
    const submit = async () => {
        let payload = {
            tag_id: tagValue,
            user_id: userValue,
        };

        dispatch(setDispatchTagData(payload, navigate));
    }

    function isFormInvalid() {
        return (formState.numberOfTags.error || !formState.numberOfTags.value);
    }
    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }

    const handleSelectUser = (e: any) => {
        console.log(e.target.value);
        setUserValue(e.target.value);
    };

    const handleSelectTag = (e: any) => {
        setTagValue(e.target.value);
    };

    const usersOptions = () => {
        return users.map((item: any, key: number) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.full_name}
                </option>
            );
        });
    };

    const tagOptions = () => {
        return tags.map((item: any, key: number) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.id}
                </option>
            );
        });
    };

    const getUsers = async () => {
        let payload = {
            page: 1,
            limit: 1000
        };

        let response = await fetchDriverListData(payload);
        setUsers(response.data.rows);
    }

    const getTags = async () => {
        let payload = {
            page: 1,
            limit: 1000
        };

        let response = await fetchTagListData(payload);

        setTags(response.data.rows);
    }

    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={'Add New Dispatch Tag'} page={breadCrums} />

                    <div className="row">
                        <div className=" col-md-6 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Dispatch Tag Info
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form className="user" action='javascript:void(0)' onSubmit={submit}>

                                        <div className="form-group">
                                            <label htmlFor="tagSelect">Tags</label>
                                            <select
                                                className="form-control"
                                                id="tagSelect"
                                                onClick={(e) => {
                                                    handleSelectTag(e);
                                                }}
                                            >
                                                <option value={''} key={''}>Tags</option>
                                                {tagOptions()}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="typeOfTagSelect">Users</label>
                                            <select
                                                className="form-control"
                                                id="userSelect"
                                                onClick={(e) => {
                                                    handleSelectUser(e);
                                                }}
                                            >
                                                <option value={''} key={''}>Users</option>
                                                {usersOptions()}
                                            </select>
                                        </div>

                                        <button
                                            className={`btn btn-primary btn-user btn-block`}
                                            type="submit" >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default AssignDispatchTag;

