
import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";

export const getAreas = async (payload: any) => {    
    return await ApiServices('get', payload, ApiEndPoints.AreaList);    
};

export const postAreaApi = async (payload: any) => {
    return await ApiServices('post', payload, ApiEndPoints.AddArea);    
};

export const UpdateAreaApi = async (payload: any) => {
    return await ApiServices('put', payload, ApiEndPoints.UpdateArea);    
};