import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cityDefaultState, cityItem } from "../interfaces/CityInterface";
import { AppState } from "../store";

const initialState: cityDefaultState = {
  cityList: [],
    totalRecords: 0
};

export const cityReducer = createSlice({
  name: "cityState",
  initialState,
  reducers: {
    setCityListData: ( state, action: PayloadAction<cityItem[]> ) => ({
      ...state,
      cityList: action.payload,
    }),

    updateCityStatus:( state, action: PayloadAction<cityItem> ) => ({
      ...state, 
      cityList: state.cityList.map(city=> city.id === action.payload.id ? 
        { ...city, status: action.payload.status} : city
    ) 
    }),
    deleteCity:( state, action: PayloadAction<cityItem> ) => ({
      ...state, 
      cityList: state.cityList.filter(city => city.id != action.payload.id) 
    }) 
  },
});

export const { setCityListData, updateCityStatus, deleteCity } = cityReducer.actions;

export const getCityListData = (state: AppState) => state.cityReducer.cityList;

export { initialState as cityState };
export default cityReducer;
