import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import Status from '../../../common/components/Status';
import { Link } from 'react-router-dom'
import { DateTime } from '../../../common/components/Date';
import DataTable from 'react-data-table-component';
import { LoaderBullet } from '../../../common/components/Loader';
import { toast } from 'react-toastify';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { updateStatus } from '../../../redux/fetchService/Status.service';
import { dispatchTagItem } from '../../../redux/interfaces/DispatchTagInterface';
import { updateTagStatusAction } from '../../../redux/actions/tag.action';
import { getDispatchTagListData, updateDispatchTagStatus } from '../../../redux/reducers/dispatchTag.reducer';
import { DispatchTagListAction, updateDispatchTagStatusAction } from '../../../redux/actions/dispatchTag.action';
import { CSVLink } from 'react-csv';


function DispacthTagList() {

  const breadCrums = [{ link: 'dispatch-tags', title: 'Dispatch Tags' }];

  const dispatch = useDispatch();
  const tags = useSelector(getDispatchTagListData);
  const loader = useSelector(getLoading);
  const [dispatchTagData, setDispatchTagData] = useState<dispatchTagItem[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const columns: any = [
    {
      name: 'S.No.',
      selector: (row: any, id: number) => id + 1,
    },
    {
      name: 'Tag Number',
      selector: (row: any) => row.id
    },
    {
      name: 'User',
      selector: (row: any) => row.userDetail.full_name
    },
    {
      name: 'Status',
      selector: (row: any) => row.status,
      cell: (row: any) => <Status status={row.status} />
    },
    {
      name: 'Created At',
      selector: (row: any) => row.created_at,
      cell: (row: any) => <DateTime date={row.created_at} />
    },
    {
      name: 'Action',
      cell: (row: any) => {
        let id = row.id;
        return (
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Action
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">             
              {
                row.status === "1" ? <a href={void (0)} className="dropdown-item"
                  onClick={() => UpdateStatus({ id: id, type: "12", status: "0" })}>Make Inactive</a> : <a href={void (0)} className="dropdown-item"
                    onClick={() => UpdateStatus({ id: id, type: "12", status: "1" })}>Make Active</a>
              }

            </div>
          </div>
        );
      },
    },
  ];


  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  const fetchData = async (page = 1) => {
    let payload = {
      page: page,
      limit: perPage
    };
    dispatch(DispatchTagListAction(payload));
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setPage(page);
    fetchData(page);
  };

  useEffect(() => {
    fetchData();
    return () => { };
  }, [dispatch]);

  useEffect(() => {

    console.log('tags.rows', tags.rows);

    setDispatchTagData(tags.rows);
    setTotalRows(tags.count);
  }, [tags]);

  /**
   * @description To change country status use this function
   * @param payload 
   * @returns 
   */

  const UpdateStatus = async (payload: {}) => {
    let check = await updateStatus(payload);
    if (check.success === true) {
      dispatch(updateDispatchTagStatusAction(payload));
      toast('Great! Info updated successfully.');
      return;
    } else {
      toast('Oops! Failed to update info.');
    }
  }

  return (
    <div className="container_full tbcont1-main">
      <div className="side_bar scroll_auto">
        <SideBar />
      </div>
      <div className="content_wrapper">
        <div className="container-fluid mb-5">
          <BreadCrum title={'Dispatch Tags'} page={breadCrums} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CSVLink data={dispatchTagData} filename={'dispatchedTags.csv'}>Download CSV</CSVLink>
            <div className='text-right mb-2'>
              <Link className="btn btn-primary" to={'add'} >Assign Tag</Link>
            </div>
          </div>
          <DataTable
            title="Dispatch Tags"
            columns={columns}
            data={dispatchTagData}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={[10, 50, 100, 500]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<LoaderBullet />}
          />
        </div>
      </div>
    </div>
  )
}

export default DispacthTagList;