import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { alertDefaultState, alertItem } from "../interfaces/AlertInterface";
import { AppState } from "../store";

const initialState: alertDefaultState = {
    alert: {
      title: "",
      type: "",    
      display: false
    }
};

export const alertReducer = createSlice({
  name: "alertState",
  initialState,
  reducers: {
    setAlert: ( state, action: PayloadAction<alertItem>) => ({
      ...state,
      alert: action.payload,      
    }),
  },
});

export const { setAlert } = alertReducer.actions;

export const getAlert = (state: AppState) => state.alertReducer.alert;

export { initialState as alertState };
export default alertReducer;
