import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { countryItem, countryDefaultState } from "../interfaces/CountryInterface";
import { UsersDefaultState, UsersItem } from "../interfaces/UsersContainInterface";
import { AppState } from "../store";

const initialState: UsersDefaultState = {
  UsersList: [],
    totalRecords: 0
};

export const UsersContainreducer = createSlice({
  name: "UsersState",
  initialState,
  reducers: {
    setUsersListData: ( state, action: PayloadAction<UsersItem[]> ) => ({
      ...state,
      UsersList: action.payload,
    }),
  },
});

export const { setUsersListData } = UsersContainreducer.actions;

export const getUsersListData = (state: AppState) => state.UsersContainreducer.UsersList;

export { initialState as UsersState };
export default UsersContainreducer;
