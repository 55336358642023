import { AppThunk } from "../store";
import { toast } from "react-toastify";
import { getAreas, postAreaApi, UpdateAreaApi } from "../fetchService/Area.service";
import { setAreaListData } from "../reducers/area.reducer";

export const AreaListAction = (payload: any): AppThunk => async (dispatch: any) => {

    const response: any = await getAreas(payload);
    if (response.data) {
        dispatch(setAreaListData(response.data));
    }
};


export const setAreaData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

    const response: any = await postAreaApi(payload);
    toast(response.message);
    if (response) {
        navigate('/areas');
    }
};

export const updateAreaData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

    const response: any = await UpdateAreaApi(payload);
    toast(response.message);
    if (response) {
        navigate('/areas');
    }
};

