import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import CountryList from '../pages/country/list';
import CountryAdd from '../pages/country/add';
import CountryEdit from '../pages/country/edit';
import DriverList from '../pages/driver/list/index';
import DriverAdd from '../pages/driver/add';
import DriverDetails from '../pages/driver/details';
import Home from '../pages/home';
import Footer from '../pages/layout/footer';
import Header from '../pages/layout/header';
import AddCountry from '../pages/country/add';
import EditDriver from '../pages/driver/edit';
import State from '../pages/state/list';
import AddState from '../pages/state/add';
import LoundryList from '../pages/laundry/list';
import AddLoundry from '../pages/laundry/add';
import EditLoundry from '../pages/laundry/edit';
import CityList from '../pages/city/list';
import UsersList from '../pages/users/list';
import AddUsers from '../pages/users/add';
import AddCity from '../pages/city/add';
import EditCity from '../pages/city/edit';
import EditState from '../pages/state/edit';
import PlanList from '../pages/plan/list';
import UsersDetails from '../pages/users/details';
import EditUser from '../pages/users/edit';
import ZipcodeList from '../pages/zipcode/list';
import AddZipcode from '../pages/zipcode/add';
import EditZipcode from '../pages/zipcode/edit';
import BatchList from '../pages/batch/list';
import AddBatch from '../pages/batch/add';
import EditBatch from '../pages/batch/edit';
import AreaList from '../pages/area/list';
import AddArea from '../pages/area/add';
import EditArea from '../pages/area/edit';
import InterestedZipcodeList from '../pages/interested-zipcode/list';
import ApartmentList from '../pages/apartment/list';
import AddApartment from '../pages/apartment/add';
import EditApartment from '../pages/apartment/edit';
import TagList from '../pages/tags/list';
import DispacthTagList from '../pages/dispatch-tags/list';
import AssignDispatchTag from '../pages/dispatch-tags/add';
import ProductList from '../pages/products/list';
import ProductDetails from '../pages/products/details';
import LaundryDetails from '../pages/laundry/details';
import UpdatePassword from '../pages/driver/updatepassword'
import BatchTagDetails from '../pages/batch/details';

const AppRouter = () => {
    return (
        <HashRouter>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />                
                <Route path="/drivers">
                    <Route index={true} element={<DriverList />} />
                    {/* <Route index={false} path=":driverId" element={<DriverDetails />} /> */}
                    <Route index={false} path="add" element={<DriverAdd />} />
                    <Route index={false} path="edit/:id" element={<EditDriver />} />
                    <Route index={false} path="details/:id" element={<DriverDetails />} />
                    <Route index={false} path="updatepassword/:id" element={<UpdatePassword />} />
                </Route>
                <Route path="/countries">
                    <Route path="add" element={<AddCountry />} />
                    <Route index={true} element={<CountryList />} />
                    <Route index={false} path="add" element={<CountryAdd />} />
                    <Route index={false} path="edit/:id" element={<CountryEdit />} />
                </Route>
                <Route path="/states">
                    <Route index={true} element={<State />} />                    
                    <Route index={false} path="add" element={<AddState />} />                    
                    <Route index={false} path="edit/:id" element={<EditState />} />                    
                </Route>
                <Route path="/laundry">
                    <Route index={true} element={<LoundryList />} />
                    <Route index={false} path="add" element={<AddLoundry />} />
                    <Route index={false} path="edit/:id" element={<EditLoundry />} />
                    <Route index={false} path="details/:id" element={<LaundryDetails />} />

                </Route>
                <Route path="/cities">
                    <Route index={true} element={<CityList />} />
                    <Route index={false} path="add" element={<AddCity />} />
                    <Route index={false} path="edit/:id" element={<EditCity />} />
                </Route>
                <Route path="/zipcodes">
                    <Route index={true} element={<ZipcodeList />} />
                    <Route index={false} path="add" element={<AddZipcode />} />
                    <Route index={false} path="edit/:id" element={<EditZipcode />} />
                </Route>
                <Route path="/intereseted-zipcodes">
                    <Route index={true} element={<InterestedZipcodeList />} />                    
                </Route>
                <Route path="/batches">
                    <Route index={true} element={<BatchList />} />
                    <Route index={false} path="add" element={<AddBatch />} />
                    <Route index={false} path="edit/:id" element={<EditBatch />} />
                    <Route index={false} path="details/:id" element={<BatchTagDetails />} />
                </Route>
                <Route path="/tags">
                    <Route index={true} element={<TagList />} />                    
                </Route>
                <Route path="/dispatch-tags">
                    <Route index={true} element={<DispacthTagList />} />  
                    <Route index={false} path="add" element={<AssignDispatchTag />} />                  
                </Route>
                <Route path="/plans">
                    <Route index={true} element={<PlanList />} /> 
                </Route>                   
                <Route path="/Users">
                    <Route index={true} element={<UsersList />} />
                    <Route index={false} path="add" element={<AddUsers />} />
                    <Route index={false} path="edit/:id" element={<EditUser />} />
                    <Route index={false} path="details/:id" element={<UsersDetails />} />
                </Route>
                <Route path="/products">
                    <Route index={true} element={<ProductList />} />
                    {/* <Route index={false} path="add" element={<AddProduct />} /> */}
                    {/* <Route index={false} path="edit/:id" element={<EditProducts />} /> */}
                    <Route index={false} path="details/:id" element={<ProductDetails />} />
                </Route>
                <Route path="/areas">
                    <Route index={true} element={<AreaList />} />
                    <Route index={false} path="add" element={<AddArea />} />
                    <Route index={false} path="edit/:id" element={<EditArea />} />
                </Route>
                <Route path="/apartments">
                    <Route index={true} element={<ApartmentList />} />
                    <Route index={false} path="add" element={<AddApartment />} />
                    <Route index={false} path="edit/:id" element={<EditApartment />} />
                </Route>
                <Route path="*" element={<Home />} />
            </Routes>
            <Footer />

        </HashRouter>
    );
}

export default AppRouter;