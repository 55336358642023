import { setCountryListData, updateCountryStatus, deleteCountry } from "../reducers/country.reducer";
import { setLoading } from "../reducers/loader.reducer";
import { fetchCountryListData, postCountryData, updateCountryApi } from "../fetchService/CountryManagement";
import { AppThunk } from "../store";
import { toast } from "react-toastify";
import { fetchApartmentListData, postApartmentData, updateApartmentApi } from "../fetchService/Apartment.service";
import { deleteApartment, setApartmentListData, updateApartmentStatus } from "../reducers/apartment.reducer";

export const ApartmentListAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(setLoading(true));
  const response: any = await fetchApartmentListData(payload);  
  dispatch(setLoading(false));
  if (response.data) {
    dispatch(setApartmentListData(response.data));
  }
};


export const setApartmentData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  const response: any = await postApartmentData(payload);
  if (response) {
    navigate('/apartments');
  } else {
   
  }
};

/**
 * @description To update apartment status 
 * @param payload 
 * @returns 
 */
export const updateApartmentStatusAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(updateApartmentStatus(payload));
};

/**
 * @description To delete country status 
 * @param payload 
 * @returns 
 */
 export const deleteApartmentAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(deleteApartment(payload));
};


/**
 * 
 * @param payload 
 * @param navigate 
 * @returns 
*/

export const updateApartmentData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  const response: any = await updateApartmentApi(payload);
  if (response) {
    toast(response.message);
    navigate('/apartments');
  } else {

    if (response && response[0] && response[0].errorMessage) {
      let payload = {
        status: "error",
        message: response[0].errorMessage,
        display: true,
      };
      //dispatch(setNotification(payload));
    }
  }
};

