import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from '../../../redux/actions/user.action';
import { useNavigate } from "react-router-dom";
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import TextInput from '../../../common/components/TextInput';
import { OnChangeModel } from "../../../common/types/Form.types";
import { getCountryListData } from '../../../redux/reducers/country.reducer';
import { getStateListData } from '../../../redux/reducers/State.reducer';
import { CountryListAction, setCountryData } from "../../../redux/actions/country.actions";
import { setStateData, StateListAction } from "../../../redux/actions/state.actions";
import { countryItem } from "../../../redux/interfaces/CountryInterface";
import { StateItem } from "../../../redux/interfaces/StateInterface";
import { getCityListData } from "../../../redux/reducers/city.reducer";
import { CityListAction } from "../../../redux/actions/city.actions";
import { getZipcodeListData } from "../../../redux/reducers/zipcode.reducer";
import { zipcodeItem } from "../../../redux/interfaces/ZipcodeInterface";
import { ZipcodeListAction } from "../../../redux/actions/zipcode.actions";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import "../../area/add/add.css";

// interface FormState {
//     fullName: { value: string; error: string | null };
//     email: { value: string; error: string | null };
//     mobileNumber: { value: string; error: string | null };
//     address: { value: string; error: string | null };
// }

const Add = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const breadCrums = [{ link: 'users', title: 'Users' }];
    const countries = useSelector(getCountryListData);
    const states = useSelector(getStateListData);
    const cities = useSelector(getCityListData);
    const zipcodes = useSelector(getZipcodeListData);

    const [countryArray, setCountryArray] = useState<countryItem[]>([]);
    const [stateArray, setStateArray] = useState<StateItem[]>([]);
    const [cityArray, setCityArray] = useState<StateItem[]>([]);
    const [zipcodeArray, setZipcodeArray] = useState<zipcodeItem[]>([]);
    const [countryId, setCountryId] = useState("");
    const [stateId, setStateId] = useState("");
    const [cityId, setCityId] = useState("");
    const [zipcodeId, setZipcodeId] = useState("");
    const [addressError, setAddressError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [address, setAddress] = useState("");
    const [stateError, setStateError] = useState("");
    const [cityError, setCityError] = useState("");
    const [zipcodeError, setzipcodeError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [formState, setFormState] = useState({
        fullName: { error: "", value: "" },
        email: { error: "", value: "" },
        mobileNumber: { error: "", value: "" },
        address: { error: "", value: "" },
        latitude: { error: "", value: "" },
        longitude: { error: "", value: "" }
    });

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } 
        });
        setAddressError("");
        setMobileError("");
        setMobileNumber(formState.mobileNumber.value);
        setEmail(formState.email.value);
        setAddress(formState.address.value);
    }

    // const handlePlaceSelect = (place: any) => {
    //     const updatedFormState = {
    //       ...formState,
    //       address: { value: place.formatted_address, error: '' },
    //     };
    //     setFormState(updatedFormState);
    // };

    const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);

    const handlePlaceSelect = (place: any) => {
        if (place && place.formatted_address) {
            const updatedFormState = {
                ...formState,
                address: { value: place.formatted_address, error: '' },
            };
            setFormState(updatedFormState);
        }
    };

    const submit = async () => {
        let formData = {
            full_name: formState.fullName.value,
            email: formState.email.value,
            mobile_number: formState.mobileNumber.value,
            user_type: 3,
            address:formState.address.value,
            lat: formState.latitude.value,
            lng: formState.longitude.value,
            isAddressUpdated:false
        }
        if (!formState.address.value && !formState.mobileNumber.value && !formState.email.value) {
            setEmailError("Please Enter Email")
            setAddressError("Please Enter Address");
            setCountryError("Please Select Country");
            setMobileError("Please Enter Mobile Number")
            setStateError("Please Select State")
            setCityError("Please Select City")
            setzipcodeError("Please Select Zipcode")
            return false;
        }
        // if (formState.address.value.length == 0) {
        //     setCountryError("Please Select Country");
        //     return false;
        // }

        dispatch(setUserData(formData, navigate))
    }

    function isFormInvalid() {
        return (formState.fullName.error || !formState.fullName.value);
        return (formState.email.error || !formState.email.value);
        return (formState.mobileNumber.error || !formState.mobileNumber.value);
    }

    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }

    useEffect(() => {
        dispatch(CountryListAction());
        return () => { };
    }, [dispatch]);

    const countryOptions = () => {
        return countryArray.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.country_name}
                </option>
            );
        });
    };

    useEffect(() => {
        let data: any = [];
        countries.map((item: any) => {
            data.push(item);
        });
        setCountryArray(data);
    }, [countries]);

    const handleSelect = (e: any) => {
        console.log('setCountryId', countryId);
        setCountryError("");
        setCountryId(e.target.value);
    };

    useEffect(() => {
        console.log('county updated', countryId);
    }, [countryId]);
    useEffect(() => {
        let data: any = [];
        states.map((item: any) => {
            data.push(item);
        });
        setStateArray(data);
    }, [states]);

    useEffect(() => {
        getStates();
        return () => { };
    }, [countryId]);

    useEffect(() => {
        handle(formState.address.value);

    }, [formState.address.value]);

    const getStates = () => {
        let payload = {
            country_id: countryId
        };
        dispatch(StateListAction(payload));
    }

    const handle = async (val: any) => {
        await geocodeByAddress(val)
            .then(results => getLatLng(results[0]))
            .then((latLng: any) => {
                setFormState({ ...formState, latitude: { error: "", value: latLng.lat }, longitude: { error: "", value: latLng.lng } })

            }).catch(error => console.error('Error', error));
    }

    const handleSelectState = (e: any) => {
        setStateId(e.target.value);
    };

    const stateOptions = () => {
        return stateArray.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.state_name}
                </option>
            );
        });
    };

    // City
    useEffect(() => {
        let data: any = [];
        cities.map((item: any) => {
            data.push(item);
        });
        setCityArray(data);
    }, [cities]);


    const getCities = () => {
        let payload = {
            state_id: stateId
        };
        dispatch(CityListAction(payload));
    }

    useEffect(() => {
        getCities();
        return () => { };
    }, [stateId]);

    const handleSelectCity = (e: any) => {
        setCityId(e.target.value);
    };

    const cityOptions = () => {
        return cityArray.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.city_name}
                </option>
            );
        });
    };

    // Zipcode
    useEffect(() => {
        let data: any = [];
        zipcodes.map((item: any) => {
            data.push(item);
        });
        setZipcodeArray(data);
    }, [cities]);


    const getZipcodes = () => {
        let payload = {
            city_id: cityId,
            page: 1
        };
        dispatch(ZipcodeListAction(payload));
    }

    useEffect(() => {
        getZipcodes();
        return () => { };
    }, [cityId]);

    const handleSelectZipcode = (e: any) => {
        setZipcodeId(e.target.value);
    };

    const zipcodeOptions = () => {
        return zipcodeArray.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.locality_zipcode}
                </option>
            );
        });
    };
    const handleGoogleAddress = (e: any) => {
        console.log('a-=sd=-as=d-asd',e)
        setFormState({ ...formState, address: { error: "", value: e } })
    }

    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={'Add New User'} page={breadCrums} />

                    <div className="row">
                        <div className=" col-md-8 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Add User Info
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form className="user" action="javascript::void(0)" onSubmit={submit}>
                                        <div className="form-group">
                                            <TextInput id="input_full_name"
                                                field="fullName"
                                                value={formState.fullName.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Full Name"
                                                placeholder="Full Name"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="input_email_name"
                                                field="email"
                                                value={formState.email.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Email"
                                                placeholder="Email"
                                            />
                                            {emailError && (
                                                <div className="error-msg">{emailError}</div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="input_mobile_number_name"
                                                field="mobileNumber"
                                                value={formState.mobileNumber.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={10}
                                                label="Mobile Number"
                                                placeholder="Mobile Number"
                                            />
                                             {mobileError && (
                                                <div className="error-msg">{mobileError}</div>
                                            )}
                                        </div>
                                        {/* <div className="form-group">
                                            <label htmlFor="countrySelect">Select Country</label>
                                            <select
                                                className="form-control"
                                                id="countrySelect"
                                                onClick={(e) => {
                                                    handleSelect(e);
                                                }}
                                            >
                                                <option value={''} key={''}>Select Country</option>
                                                {countryOptions()}
                                            </select>
                                            {countryError && (
                                                <div className="error-msg">{countryError}</div>
                                            )}
                                        </div> */}
                                        {/* <div className="form-group">
                                            <label htmlFor="stateSelect">Select State</label>
                                            <select
                                                className="form-control"
                                                id="stateSelect"
                                                onClick={(e) => {
                                                    handleSelectState(e);
                                                }}
                                            >
                                                <option value={''} key={''}>Select State</option>
                                                {stateOptions()}
                                            </select>
                                            {stateError && (
                                                <div className="error-msg">{stateError}</div>
                                            )}
                                        </div> */}
                                        {/* <div className="form-group">
                                            <label htmlFor="citySelect">Select City</label>
                                            <select
                                                className="form-control"
                                                id="citySelect"
                                                onClick={(e) => {
                                                    handleSelectCity(e);
                                                }}
                                            >
                                                <option value={''} key={''}>Select City</option>
                                                {cityOptions()}
                                            </select>
                                            {cityError && (
                                                <div className="error-msg">{cityError}</div>
                                            )}
                                        </div> */}
                                        {/* <div className="form-group">
                                            <label htmlFor="citySelect">Select Zipcode</label>
                                            <select
                                                className="form-control"
                                                id="citySelect"
                                                onClick={(e) => {
                                                    handleSelectZipcode(e);
                                                }}
                                            >
                                                <option value={''} key={''}>Select Zipcode</option>
                                                {zipcodeOptions()}
                                            </select>
                                            {zipcodeError && (
                                                <div className="error-msg">{zipcodeError}</div>
                                            )}
                                        </div> */}
                                        <div className="form-group">
                                            <p>Address</p>
                                            <PlacesAutocomplete
                                                value={formState.address.value}
                                                onChange={
                                                    (e) => setFormState({ ...formState, address: { error: "", value: e } })
                                                }
                                                onSelect={handleGoogleAddress}
                                            >
                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <div>
                                                        <input
                                                            {...getInputProps({
                                                                placeholder: 'Search Address ...',
                                                                className: 'location-search-input',
                                                            })}
                                                            style={{ width: "100%", height: '35px' }}

                                                        />
                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map(suggestion => {
                                                                const className = suggestion.active
                                                                    ? 'suggestion-item--active'
                                                                    : 'suggestion-item';
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                return (
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            className,
                                                                            style,
                                                                        })}
                                                                    >
                                                                        <span>{suggestion.description}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                            {addressError && (
                                                <div className="error-msg">{addressError}</div>
                                            )}
                                            {formState.address.value.length > 0 ?
                                                <>
                                                    <div className="form-group">
                                                        <TextInput id="latitude"
                                                            field="latitude"
                                                            value={formState.latitude.value}
                                                            onChange={hasFormValueChanged}
                                                            required={true}
                                                            maxLength={100}
                                                            label="Latitude"
                                                            placeholder="Enter Latitude " />
                                                    </div>
                                                    <div className="form-group">
                                                        <TextInput id="longitude"
                                                            field="longitude"
                                                            value={formState.longitude.value}
                                                            onChange={hasFormValueChanged}
                                                            required={true}
                                                            maxLength={100}
                                                            label="Longitude"
                                                            placeholder="Enter Longitude " />
                                                    </div>
                                                </>
                                                : null
                                            }
                                        </div>
                                        <button
                                            className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                            type="submit" >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Add;