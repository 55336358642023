import React, { useEffect } from "react";
import SideBar from "../../layout/sidebar/SideBar";
import BreadCrum from "../../layout";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDriverDetailsData } from "../../../redux/reducers/batch.reducer";
import { DriverDetailAction } from "../../../redux/actions/batch.action";

const BatchTagDetails = () => {
  let { id } = useParams();
  const breadCrums = [{ link: "drivers", title: "Drivers" }];
  const dispatch = useDispatch();
  let location: any = useLocation();
  const driverDetails = useSelector(getDriverDetailsData);
  const { driverInfo } = location.state;

  const keyDirectory:any={
    "id":"tagid",
    "is_allocate":"Allocated status"
  }

  const ObjectResponce = (key:any, value:any) => {
    if(key === "size"){
        return `${value} lbs`
    }else if(key === "is_allocate"){
        return value == 0
    }else {
        return value
    }
  }

  const getKeyInList = (obj:any) => {
    let value = ""
    delete obj.qr_code
    delete obj.createdAt
    delete obj.updatedAt
    delete obj.deletedAt
    delete obj.status
    for (let key in obj){
        value += `${keyDirectory[key] ?? key}: \t`+ (ObjectResponce(key, obj[key]) ?? '-')+`${', \t\t'}`
    }
    return value
  }

  console.log("a[psd[pasd", driverInfo);

  return (
    <div className="container_full">
      <div className="side_bar scroll_auto">
        <SideBar />
      </div>
      <div className="content_wrapper">
        <div className="container-fluid mb-5">
          <BreadCrum title={""} page={breadCrums} />

          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Driver Details</h3>
            </div>
            <div className="panel-content panel-about">
              <table className="">
                <tbody>
                  <tr>
                    <th>
                      <i className="fa fa-user"></i>Number of tags:
                    </th>
                    <td>{driverInfo?.number_of_tags ?? "-"}</td>
                  </tr>
                  {driverInfo.tag.map((item:any, index:any) => {
                    return (
                      <tr key={index}>
                        <th>
                          <i className="fa fa-user"></i>Tag{index+1}
                        </th>
                        <td>{getKeyInList(item) ?? "-"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchTagDetails;
