import * as React from 'react';
import { Link } from "react-router-dom";
import { Accordion } from 'react-bootstrap';

const SideBar = () => {
    return (
        <ul id="dc_accordion" className="sidebar-menu sidebar_custome tree">
            <li>
                <Link to="/home">
                    <i className="fa fa-home"></i>
                    <span>Dashboard</span>
                </Link>
            </li>
            <li className="menu_sub">

                <div id='accordion' className='accordion drop_custm'>
                    <div className='card-header collapsed' data-toggle="collapse" data-target="#collapseOne">
                        <div className='nav_itemname'>
                            <i className="fa fa-users catgr_icon"></i>
                            <span className='drop_main_catg'>Users Management</span>
                        </div>
                        <i className="fa fa-caret-down"></i>
                    </div>
                    <div id="collapseOne" className='card-body collapse' data-parent="#accordion">
                        <Link to="/users">Users</Link>
                        <Link to="/drivers">Drivers</Link>
                    </div>
                                      
                    <div className='card-header collapsed' data-toggle="collapse" data-target="#tags">
                        <div className='nav_itemname'>
                            <i className="fa fa-tags catgr_icon"></i>
                            <span className='drop_main_catg'>Tags</span>
                        </div>
                        <i className="fa fa-caret-down"></i>
                    </div>
                    <div id="tags" className='card-body collapse' data-parent="#accordion">
                        <Link to="/batches">Tag Batches</Link>
                        {/* <Link to="/tags">Tags</Link> */}
                        {/* <Link to="/dispatch-tags">Dispatch Tags</Link>                         */}
                    </div>

                    <Link to="/laundry">
                        <i className="fa fa-shopping-basket"></i>
                        <span>Laundry </span>
                    </Link>                                                     
                    <Link to="/intereseted-zipcodes">
                        <i className="fa fa-road"></i>
                        <span>Interested Zipcodes</span>
                    </Link>

                    <Link to="/areas">
                        <i className="fa fa-map-signs"></i>
                        <span>Service Areas</span>
                    </Link>         
                    
                    <div className='card-header collapsed' data-toggle="collapse" data-target="#settings">
                        <div className='nav_itemname'>
                            <i className="fa fa-gear catgr_icon"></i>
                            <span className='drop_main_catg'>Settings</span>
                        </div>
                        <i className="fa fa-caret-down"></i>
                    </div>
                    <div id="settings" className='card-body collapse' data-parent="#accordion">
                        {/* <Link to="/plans">Subscription Plans</Link> */}
                        <Link to="/countries">Countries</Link>
                        <Link to="/states">States</Link>
                        <Link to="/cities">Cities</Link>
                        <Link to="/zipcodes">Zipcodes</Link>
                        {/* <Link to="/apartments">Apartments</Link>                         */}
                    </div>

                </div>

            </li>
        </ul>
    );
}

export default SideBar;