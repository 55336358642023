import { AppThunk } from "../store";
import { fetchCityListData, postCityData, updateCityApi } from "../fetchService/CityManagement ";
import { deleteCity, setCityListData, updateCityStatus } from "../reducers/city.reducer";
import { toast } from "react-toastify";

export const CityListAction = (payload: any): AppThunk => async (dispatch: any) => {
 
  const response: any = await fetchCityListData(payload);
  if (response.data) {  
    dispatch(setCityListData(response.data));
  }else{
    dispatch(setCityListData([]));
  }
};


export const setCityData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  let formData = {
    city_name: payload.cityName.value,
  }

  const response: any = await postCityData(formData);
  if (response) {
    navigate('/cities');
  } else {
   
  }
};

/**
 * @description To update city status 
 * @param payload 
 * @returns 
 */
export const updateCityStatusAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(updateCityStatus(payload));
};

/**
 * @description To delete city status 
 * @param payload 
 * @returns 
 */
 export const deleteCityAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(deleteCity(payload));
};


/**
 * 
 * @param payload 
 * @param navigate 
 * @returns 
*/

export const updateCityData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

  let formData = {
    country_name: payload.countryName.value,
    id: payload.countryId.value,
  }
  const response: any = await updateCityApi(formData);
  if (response) {    
    toast(response.message);
    navigate('/cities');
  } else {

    if (response && response[0] && response[0].errorMessage) {
      let payload = {
        status: "error",
        message: response[0].errorMessage,
        display: true,
      };
      //dispatch(setNotification(payload));
    }
  }
};

