const setUserData = async (userData: any) => {
    try {
        window.sessionStorage.setItem("@userData", userData);
    } catch (e) {
        throw e;
    }
}

const setToken = async (userToken: any) => {
    try {        
        window.sessionStorage.setItem("@userToken", userToken)
    } catch (e) {
        throw e
    }
}

const getToken = async () => {
    try {        
        let value = window.sessionStorage.getItem("@userToken")
        if (value !== null) {
            return value
        }
        return null
    } catch (e) {
        throw e;
    }
}

export { setUserData, setToken, getToken };