import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import Status from '../../../common/components/Status';
import { Link } from 'react-router-dom'
import { CountryListAction, deleteCountryAction } from '../../../redux/actions/country.actions';
import { DateTime } from '../../../common/components/Date';
import DataTable from 'react-data-table-component';
import { LoaderBullet } from '../../../common/components/Loader';
import { toast } from 'react-toastify';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { deleteType, updateStatus } from '../../../redux/fetchService/Status.service';
import { updateCountryStatusAction } from '../../../redux/actions/country.actions';
import { PlanListAction } from '../../../redux/actions/stripe.action';
import { getSubscriptionPlanListData } from '../../../redux/reducers/stripe.reducer';
import { planItem } from '../../../redux/interfaces/StripeInterface';
import { CSVLink } from 'react-csv';

function PlanList() {
    
    const breadCrums = [{ link: 'plans', title: 'Subcription Plans' }];

    const dispatch = useDispatch();
    const plans = useSelector(getSubscriptionPlanListData);
    const loader = useSelector(getLoading);    
    const [planData, setPlanData] = useState<planItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);

    const columns: any = [
        {
            name: '#',
            selector: (row: any, id: number) => id + 1,
        },
        {
            name: 'Product Id',
            selector: (row: any) => row.product
        },
        {
            name: 'Currency',
            selector: (row: any) => row.currency
        }, {
            name: 'Interval',
            selector: (row: any) => row.interval
        },
        {
            name: 'Amount',
            selector: (row: any) => '$' + row.amount / 100
        }       
    ];


    useEffect(() => {
        setLoading(loader);
    }, [loader]);

    const fetchData = async () => {
        dispatch(PlanListAction());
    };

    const handlePageChange = (page: number) => {
        //fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        fetchData();
    };

    useEffect(() => {
        fetchData();
        return () => { };
    }, [dispatch]);

    useEffect(() => {
        setPlanData(plans);
    }, [plans]);

    /**
     * @description To change country status use this function
     * @param payload 
     * @returns 
     */

    const UpdateStatus = async (payload: {}) => {
        let check = await updateStatus(payload);
        if (check.success === true) {
            dispatch(updateCountryStatusAction(payload));
            toast('Great! Info updated successfully.');
            return;
        }
    }

    const DeleteRecord = async (payload: {}) => {
        let check = await deleteType(payload);

        if (check) {
            toast('Great! Info updated successfully.');
            dispatch(deleteCountryAction(payload));
        } else {
            toast('Oops! Failed to delete, please try again.');
        }
    }

    return (
        <div className="container_full tbcont1-main">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid mb-5">
                    <BreadCrum title={'Subscription Plans'} page={breadCrums} />
                    <CSVLink data={planData} filename={'plans.csv'}>Download CSV</CSVLink>
                    <DataTable
                        title="Subscription Plans"
                        columns={columns}
                        data={planData}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressComponent={<LoaderBullet />}
                    />
                </div>
            </div>
        </div>
    )
}

export default PlanList