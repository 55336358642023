import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { countryItem, countryDefaultState } from "../interfaces/CountryInterface";
import { loundryDefaultState, loundryItem } from "../interfaces/LoundryInterface";
import { AppState } from "../store";

const initialState: loundryDefaultState = {
  loundryList: [],
    totalRecords: 0
};

export const loundryReducer = createSlice({
  name: "loundryState",
  initialState,
  reducers: {
    setLoundryListData: ( state, action: PayloadAction<loundryItem[]> ) => ({
      ...state,
      loundryList: action.payload,
    }),
  },
});

export const { setLoundryListData } = loundryReducer.actions;

export const getLoundryListData = (state: AppState) => state.loundryReducer.loundryList;

export { initialState as loundryState };
export default loundryReducer;
