import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { apartmentItem, apartmentDefaultState } from "../interfaces/ApartmentInterface";
import { AppState } from "../store";

const initialState: apartmentDefaultState = {
    apartmentList: [],
    totalRecords: 0
};

export const apartmentReducer = createSlice({
  name: "apartmentState",
  initialState,
  reducers: {
    setApartmentListData: ( state, action: PayloadAction<apartmentItem[]> ) => ({
      ...state,
      apartmentList: action.payload,
    }),
    
    updateApartmentStatus:( state, action: PayloadAction<apartmentItem> ) => ({
      ...state, 
      apartmentList: state.apartmentList.map(apartment=> apartment.id === action.payload.id ? 
        { ...apartment, status: action.payload.status} : apartment
    ) 
    }),
    deleteApartment:( state, action: PayloadAction<apartmentItem> ) => ({
      ...state, 
      apartmentList: state.apartmentList.filter(apartment => apartment.id != action.payload.id) 
    }) 
  },
});

export const { setApartmentListData, updateApartmentStatus, deleteApartment } = apartmentReducer.actions;

export const getApartmentListData = (state: AppState) => state.apartmentReducer.apartmentList;

export { initialState as countryState };
export default apartmentReducer;
