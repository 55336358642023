import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateItem,StateDefaultState } from "../interfaces/StateInterface";
import { AppState } from "../store";

const initialState: StateDefaultState = {
  stateList: [],
  totalRecords: 0,
};

export const StateReducer = createSlice({
  name: "stateDataState",
  initialState,
  reducers: {
    setStateListData: (state, action: PayloadAction<StateItem[]>) => ({
      ...state,
      stateList: action.payload,
    }),
    updateStateStatus:(state, action: PayloadAction<StateItem> ) => ({
      ...state, 
      stateList: state.stateList.map(item=> item.id == action.payload.id ? 
        { ...item, status: action.payload.status} : item
    ) 
    }),
    deleteState:( state, action: PayloadAction<StateItem> ) => ({
      ...state, 
      stateList: state.stateList.filter(state => state.id != action.payload.id) 
    }) 
  },
});

export const { setStateListData, updateStateStatus, deleteState } = StateReducer.actions;

export const getStateListData = (state: AppState) => state.StateReducer.stateList;
  
export { initialState as stateDataState };
export default StateReducer;
