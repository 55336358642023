import * as React from 'react';
import { useLocation, useParams } from "react-router-dom";
import SideBar from '../../layout/sidebar/SideBar';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrum from '../../layout';
import { Link } from 'react-router-dom';
import { getLoundryListData } from '../../../redux/reducers/loundry.reducer';

export default function LaundryDetails() {

	let { id } = useParams();
	const dispatch = useDispatch();
	const UsersDetails = useSelector(getLoundryListData);
	let location: any = useLocation();
    const { loundryInfo } = location.state;
const breadCrums = [{ link: 'laundry', title: 'Laundry' }];
console.log('add====',loundryInfo);


	// useEffect(() => {
	// 	let payload: any = {
	// 		id: id
	// 	}
	// 	dispatch(UserDetailAction(payload));
	// 	return () => { };
	// }, [dispatch]);

	// useEffect(() => {
	// 	setuserData(UsersDetails);
	// }, [UsersDetails]);

	return (
		<div className="container_full">
			<div className="side_bar scroll_auto">
				<SideBar />
			</div>

			<div className="content_wrapper">
				<div className="container-fluid mb-5">
					<BreadCrum title={''} page={breadCrums} />

					<div className="panel">
						<div className="panel-heading"> 
							<h3 className="panel-title">Laundry Details</h3>
							<div style={{ float: 'right' }}>
								<Link
									to={`/laundry/edit/${id}`}
									state={{ loundryInfo:loundryInfo }}
										
								><h3 className="panel-title">Edit</h3></Link>
							</div>
						</div>
						<div className="panel-content panel-about">

							<table>
								<tbody>
									<tr>
										<th>
											<i className="fa fa-user"></i>Full Name</th>
										<td>{loundryInfo?.full_name ?? '-'}</td>
									</tr>
									<tr>
										<th>
											<i className="fa fa-envelope"></i>Email</th>
										<td>{loundryInfo?.email ?? '-'}</td>
									</tr>

									<tr>
										<th>
											<i className="fa fa-mobile-phone"></i>Mobile No.</th>
										<td>
											<a href="javascript::void(0)" className="btn-link">{loundryInfo?.mobile_number ?? '-'}</a>
										</td>
									</tr>
									<tr>
										<th>
											<i className="fa fa-truck"></i>Laundry Area</th>
										<td>
											<a href="javascript::void(0)" className="btn-link">{loundryInfo?.laundry?.laundry_area[0]?.area?.area_name ?? '-'}</a>
										</td>
									</tr>
									<tr>
										<th>
											<i className="fa fa-address-card-o"></i>Address</th>
										<td>
											<a href="javascript::void(0)" className="btn-link">{loundryInfo.laundry?loundryInfo.laundry.address:'-'}</a>
										</td>
									</tr>
									<tr>
										<th>
											<i className="fa fa-globe"></i>City</th>
										<td>
											<a href="javascript::void(0)" className="btn-link">{loundryInfo?.laundry?.laundry_area[0]?.area?.city?.city_name ?? '-'}</a>
										</td>
									</tr>

									<tr>
										<th>
											<i className="fa fa-globe"></i>State</th>
										<td>
											<a href="javascript::void(0)" className="btn-link">{loundryInfo?.laundry?.laundry_area[0]?.area?.state?.state_name ?? '-'}</a>
										</td>
									</tr>

									

								</tbody>
							</table>
						</div>

					</div>
				</div>


			</div>

		
			
		</div>
	);
}