import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { countryItem, countryDefaultState } from "../interfaces/CountryInterface";
import { AppState } from "../store";

const initialState: countryDefaultState = {
    countryList: [],
    totalRecords: 0
};

export const countryReducer = createSlice({
  name: "countryState",
  initialState,
  reducers: {
    setCountryListData: ( state, action: PayloadAction<countryItem[]> ) => ({
      ...state,
      countryList: action.payload,
    }),
    
    updateCountryStatus:( state, action: PayloadAction<countryItem> ) => ({
      ...state, 
      countryList: state.countryList.map(country=> country.id === action.payload.id ? 
        { ...country, status: action.payload.status} : country
    ) 
    }),
    deleteCountry:( state, action: PayloadAction<countryItem> ) => ({
      ...state, 
      countryList: state.countryList.filter(country => country.id != action.payload.id) 
    }) 
  },
});

export const { setCountryListData, updateCountryStatus, deleteCountry } = countryReducer.actions;

export const getCountryListData = (state: AppState) => state.countryReducer.countryList;

export { initialState as countryState };
export default countryReducer;
