import * as React from 'react';
import { MultiSelect } from "react-multi-select-component";

const SelectMulti = ({options, value, onChange, labelledBy }: {options: any, value: any, onChange: any, labelledBy: string}) => {
    return (
        <>
            <label htmlFor={labelledBy}>{labelledBy}</label>
            <MultiSelect
                options={options}
                value={value}
                onChange={onChange}
                labelledBy={labelledBy}
                hasSelectAll={false}
            />
        </>
    );
}

export default SelectMulti;