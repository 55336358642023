import { fetchUserManagementData } from "../fetchService/UserManagement";
import { userItem } from "../interfaces/UserManagementInterface";
import { setNotification } from "../reducers/NotificationReducer";
import {
  setUserManagementData
} from "../reducers/userManagementReducer";
import { AppThunk } from "../store";
import { setUserData, setToken } from '../../helper/Storage';
import { toast } from "react-toastify";

export const setUserManagementDataReducer = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {
  let request = {
    value: payload.email.value,
    password: payload.password.value
  }
  const response: any = await fetchUserManagementData(request);

  if (response != undefined && response.success) {    
    let userObj: any = JSON.stringify(response.data);
    let token = response.data.token;

    setUserData(userObj);
    setToken(token);
    dispatch(setUserManagementData(userObj));
    //navigate('home');
    window.location.href = '/';
  } else {
    dispatch(setUserManagementData([]));

    if (response && response[0] && response[0].errorMessage) {
      let payload = {
        status: "error",
        message: response[0].errorMessage,
        display: true,
      };
      dispatch(setNotification(payload));
    }
  }
};

