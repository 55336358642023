import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import TextInput from '../../../common/components/TextInput';
import { OnChangeModel } from "../../../common/types/Form.types";
import { updateDriverData } from '../../../redux/actions/driver.actions';
import { fetchLoundryListData } from '../../../redux/fetchService/LoundryManagement'
import SelectMulti from '../../../common/components/MultiSelect';
import { updateUserData } from '../../../redux/actions/user.action';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';


const EditUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const breadCrums = [{ link: 'users', title: 'Users' }];
    let location: any = useLocation();
    const { userInfo } = location.state;
    const [laundryOptions, setLaundryOptions] = useState([]);
    
    useEffect(() => {
        getList();
    }, []);


    const [formState, setFormState] = useState({
        fullName: { error: "", value: userInfo.full_name },
        id: { error: "", value: userInfo.id },
        email: { error: "", value: userInfo.email },
        mobile: { error: "", value: userInfo.mobile_number },
        password: { error: "", value: "" }  ,
        address:{error:"",value:userInfo.user_address?.address},   
        city:{error:'',value:userInfo.user_address?.city?.city_name},
        state:{error:'',value:userInfo.user_address?.state?.state_name},
        country:{error:'',value:userInfo.user_address?.country?.country_name},
        latitude: { error: "", value: userInfo.user_address?.lat },
        longitude: { error: "", value: userInfo.user_address?.lng },
        zipcode: {error: "", value: userInfo.user_address?.zipcodes?.locality_zipcode},
        isAddressUpdate : {error: "", value: false}
    });
    
    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } });
    }

    async function getList() {
        let payload = {
            page: 1
        }
        const laundryResponse = await fetchLoundryListData(payload);
        let options: any = [];
        laundryResponse.data.rows.map(async (item: any) => {
            await options.push({ label: item.name, value: item.id })
        })

        setLaundryOptions(options);
        return {};
    }

    const submit = async () => {
        dispatch(updateUserData(formState, navigate))
    }


    function isFormInvalid() {
        return (formState.fullName.error || !formState.fullName.value);
        return (formState.id.error || !formState.id.value);
        return (formState.email.error || !formState.email.value);
        return (formState.mobile.error || !formState.mobile.value);
    }

    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }

    const handle = async (val: any) => {
        setFormState({...formState, isAddressUpdate: {error: "", value:true}})
        await geocodeByAddress(val)
            .then(results => getLatLng(results[0]))
            .then((latLng: any) => {
                setFormState({ ...formState, latitude: { error: "", value: latLng.lat }, longitude: { error: "", value: latLng.lng }, address:{ error: "", value: val } })
            }).catch(error => console.error('Error', error));
    }
    const handleGoogleAddress = (e: any) => {
        // we can remove this function also and directly use the handle()
        handle(e)
    }

    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={'Edit User'} page={breadCrums} />

                    <div className="row">
                        <div className=" col-md-8 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Edit User Info
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form className="user" action='javascript:void(0)' onSubmit={submit}>
                                        <div className="form-group">
                                            <TextInput id="input_full_name"
                                                field="fullName"
                                                value={formState.fullName.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label=" Name"
                                                placeholder="Full Name" />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="input_email"
                                                field="email"
                                                value={formState.email.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Email"
                                                placeholder="Email" />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="input_country_name"
                                                field="mobile"
                                                value={formState.mobile.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Mobile"
                                                placeholder="Mobile Number" />
                                        </div>
                                        {/* <div className="form-group">
                                            <TextInput id="input_address"
                                                field="address"
                                                value={formState.address.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Address"
                                                placeholder="Address" />
                                        </div> */}
                                          <p>Address</p>
                                    <PlacesAutocomplete
                                        value={formState.address.value}
                                        // (e) => setFormState({...formState, address: {error: "", value: e}})
                                        onChange={(e) => setFormState({...formState, address: {error: "", value: e}})}
                                        onSelect={handleGoogleAddress}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                            {...getInputProps({
                                                placeholder: 'Search Address ...',
                                                className: 'location-search-input',
                                             })}
                                             style={{width:"100%",height:'35px'}}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                                );
                                            })}
                                            </div>
                                        </div>
                                        )}
                                    </PlacesAutocomplete>
                                    {formState.address.value.length > 0 ?
                                                <>
                                                    <div className="form-group">
                                                        <TextInput id="latitude"
                                                            field="latitude"
                                                            value={formState.latitude.value}
                                                            onChange={hasFormValueChanged}
                                                            required={true}
                                                            maxLength={100}
                                                            label="Latitude"
                                                            placeholder="Enter Latitude " />
                                                    </div>
                                                    <div className="form-group">
                                                        <TextInput id="longitude"
                                                            field="longitude"
                                                            value={formState.longitude.value}
                                                            onChange={hasFormValueChanged}
                                                            required={true}
                                                            maxLength={100}
                                                            label="Longitude"
                                                            placeholder="Enter Longitude " />
                                                    </div>
                                                </>
                                                : null
                                            }

                                        <div className="form-group">
                                            <TextInput id="input_city"
                                                field="city"
                                                value={formState.city.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="City"
                                                placeholder="City" />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="input_city"
                                                field="zipcode"
                                                value={formState.zipcode.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Zipcode"
                                                placeholder="Zipcode" />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="input_state"
                                                field="state"
                                                value={formState.state.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="State"
                                                placeholder="State" />
                                        </div>
                                        <div className="form-group">
                                            <TextInput id="input_country"
                                                field="country"
                                                value={formState.country.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Country"
                                                placeholder="Country" />
                                        </div>
                                       
                                        <button
                                            className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                            type="submit" >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default EditUser;