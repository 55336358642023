import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { areaDefaultState, areaItem } from "../interfaces/AreaInterface";
import { AppState } from "../store";

const initialState: areaDefaultState = {
  areaList: [],
  totalRecords: 0
};

export const areaReducer = createSlice({
  name: "areaState",
  initialState,
  reducers: {
    setAreaListData: (state, action: PayloadAction<areaItem[]>) => ({
      ...state,
      areaList: action?.payload,
    }),

    updateAreaStatus: (state, action: PayloadAction<areaItem>) => ({
      ...state,
      areaList: state.areaList.map(area => area.id === action.payload.id ?
        { ...area, status: action.payload.status } : area
      )
    }),
    deleteArea: (state, action: PayloadAction<areaItem>) => ({
      ...state,
      areaList: state.areaList.filter(area => area.id != action.payload.id)
    })
  },
});

export const { setAreaListData, updateAreaStatus, deleteArea } = areaReducer.actions;

export const getAreaListData = (state: AppState) => state.areaReducer.areaList;

export { initialState as areaState };
export default areaReducer;
