import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import Status from '../../../common/components/Status';
import { Link } from 'react-router-dom'
import { CountryListAction, deleteCountryAction } from '../../../redux/actions/country.actions';
import { countryItem } from '../../../redux/interfaces/CountryInterface'
import { DateTime } from '../../../common/components/Date';
import DataTable from 'react-data-table-component';
import { LoaderBullet } from '../../../common/components/Loader';
import { toast } from 'react-toastify';
import { getCountryListData } from '../../../redux/reducers/country.reducer'
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { deleteType, updateStatus } from '../../../redux/fetchService/Status.service';
import { updateCountryStatusAction } from '../../../redux/actions/country.actions';
import { deleteZipcode, getZipcodeListData, updateZipcodeStatus, zipcodeState } from '../../../redux/reducers/zipcode.reducer';
import { zipcodeItem } from '../../../redux/interfaces/ZipcodeInterface';
import { ZipcodeListAction } from '../../../redux/actions/zipcode.actions';
import { deleteApartment, getApartmentListData, updateApartmentStatus } from '../../../redux/reducers/apartment.reducer';
import { ApartmentListAction, deleteApartmentAction, updateApartmentStatusAction } from '../../../redux/actions/apartment.actions';
import { apartmentItem } from '../../../redux/interfaces/ApartmentInterface';
import swal from "sweetalert";
import { CSVLink } from 'react-csv';

function ApartmentList() {

  const dispatch = useDispatch();
  const apartments = useSelector(getApartmentListData);
  const loader = useSelector(getLoading);
  const breadCrums = [{ link: 'apartments', title: 'Apartments' }];

  const [apartmentData, setApartmentData] = useState<apartmentItem[]>([]);
  const [page] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const columns: any = [
    {
      name: 'S.No.',
      selector: (row: any, id: number) => id + 1,
    },
    {
      name: 'Name',
      selector: (row: any) => row.name
    },
    {
      name: 'Instruction',
      selector: (row: any) => row.instruction
    },
    {
      name: 'Status',
      selector: (row: any) => row.status,
      cell: (row: any) => <Status status={row.status} />
    },
    {
      name: 'Created At',
      selector: (row: any) => row.created_at,
      cell: (row: any) => <DateTime date={row.created_at} />
    },
    {
      name: 'Action',
      cell: (row: any) => {
        let id = row.id;
        return (
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Action
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link
                className="dropdown-item" to={`edit/${row.id}`}
                state={{ apartmentInfo: row }}
              >Edit</Link>
              <a
                className="dropdown-item"
                onClick={() => DeleteApartment({ id: id, type: "5", delete_type: "1" })}
              >Delete</a>
              {
                row.status === "1" ? <a href={void (0)} className="dropdown-item"
                  onClick={() => UpdateStatus({ id: id, type: "5", status: "0" })}>Make Inactive</a> : <a href={void (0)} className="dropdown-item"
                    onClick={() => UpdateStatus({ id: id, type: "5", status: "1" })}>Make Active</a>
              }

            </div>
          </div>
        );
      },
    },
  ];


  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  const fetchData = async () => {
    let payload = {
      page: 1
    }
    dispatch(ApartmentListAction(payload));
  };

  const handlePageChange = (page: number) => {
    //fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
    return () => { };
  }, [dispatch]);

  useEffect(() => {

    setApartmentData(apartments)
  }, [apartments]);

  /**
   * @description To change country status use this function
   * @param payload 
   * @returns 
   */

  const UpdateStatus = async (payload: any) => {
    let check = await updateStatus(payload);
    if (check.success === true) {
      dispatch(updateApartmentStatusAction(payload));
      toast('Great! Info updated successfully.');
      return;
    }
  }

  const DeleteRecord = async (payload: any) => {
    let check = await deleteType(payload);
    if (check) {
      dispatch(deleteApartmentAction(payload));
    } else {
      toast('Oops! Failed to delete, please try again.');
    }
  }
  const DeleteApartment = (payload: any) =>{
    swal ("Are you sure?", "You want to delete Apartment!", "warning", {
        buttons: ["CANCEL", "DELETE"],
        dangerMode: true,
    }).then((okay)=>{
        if (okay){
        DeleteRecord(payload);
        }
    });
};

  return (
    <div className="container_full tbcont1-main">
      <div className="side_bar scroll_auto">
        <SideBar />
      </div>
      <div className="content_wrapper">
        <div className="container-fluid mb-5">
          <BreadCrum title={'Apartments'} page={breadCrums} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CSVLink data={apartmentData} filename={'apartments.csv'}>Download CSV</CSVLink>
            <div className='text-right mb-2'>
              <Link className="btn btn-primary" to={'add'} >Add New Apartment</Link>
            </div>
          </div>
          <DataTable
            title="Apartments"
            columns={columns}
            data={apartmentData}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<LoaderBullet />}
          />
        </div>
      </div>
    </div>
  )
}

export default ApartmentList