import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import Status from '../../../common/components/Status';
import { deleteCountryAction } from '../../../redux/actions/country.actions';
import { DateTime } from '../../../common/components/Date';
import DataTable from 'react-data-table-component';
import { LoaderBullet } from '../../../common/components/Loader';
import { toast } from 'react-toastify';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { deleteType, updateStatus } from '../../../redux/fetchService/Status.service';
import { updateCountryStatusAction } from '../../../redux/actions/country.actions';
import { InterestedZipcodeListAction } from '../../../redux/actions/interestedZipcode.action';
import { getInterestedZipcodeListData, setInterestedZipcodeListData } from '../../../redux/reducers/interestedZipcode.reducer';
import { interestedZipcodeItem } from '../../../redux/interfaces/InterestedZipcodeInterface';

function InterestedZipcodeList() {

    const dispatch = useDispatch();
    const zipcodes: any = useSelector(getInterestedZipcodeListData);
    const loader = useSelector(getLoading);
    const breadCrums = [{ link: 'intereseted-zipcodes', title: 'Intereseted Zipcodes' }];
    const [zipcodeData, setZipcodeData] = useState<interestedZipcodeItem[]>([]);
    const [page] = useState(1);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);

    const columns: any = [
        {
            name: 'S.No.',
            selector: (row: any, id: number) => id + 1,
        },
        {
            name: 'Zipcode',
            selector: (row: any) => row.zipcode
        },
        {
            name: 'Count',
            selector: (row: any) => row.count
        },
        {
            name: 'Status',
            selector: (row: any) => row.status,
            cell: (row: any) => <Status status={row.status} />
        },
        {
            name: 'Created At',
            selector: (row: any) => row.created_at,
            cell: (row: any) => <DateTime date={row.created_at} />
        }
    ];


    useEffect(() => {
        setLoading(loader);
    }, [loader]);

    const fetchData = async () => {
        let payload = {
            page: 1
        };
        dispatch(InterestedZipcodeListAction(payload));
    };

    const handlePageChange = (page: number) => {
        //fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        fetchData();
    };

    useEffect(() => {
        fetchData();
        return () => { };
    }, [dispatch]);

    useEffect(() => {
        setZipcodeData(zipcodes.rows);
    }, [zipcodes]);

    /**
     * @description To change country status use this function
     * @param payload 
     * @returns 
     */

    const UpdateStatus = async (payload: {}) => {
        let check = await updateStatus(payload);
        if (check.success === true) {
            dispatch(updateCountryStatusAction(payload));
            toast('Great! Info updated successfully.');
            return;
        }
    }

    const DeleteRecord = async (payload: {}) => {
        let check = await deleteType(payload);

        if (check) {
            toast('Great! Info updated successfully.');
            dispatch(deleteCountryAction(payload));
        } else {
            toast('Oops! Failed to delete, please try again.');
        }
    }

    return (
        <div className="container_full tbcont1-main">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid mb-5">
                    <BreadCrum title={'Interested Zipcode'} page={breadCrums} />                                     
                    <DataTable
                        title="Interested Zipcode"
                        columns={columns}
                        data={zipcodeData}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressComponent={<LoaderBullet />}
                    />
                </div>
            </div>
        </div>
    )
}

export default InterestedZipcodeList