import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
//import { updateCountryStatusAction } from '../../redux/actions/country.actions';
import { updateStatus } from '../../redux/fetchService/Status.service';

const UpdateStatus = (payload: {}) => {

    let check = updateStatus(payload);
    //dispatch(updateCountryStatusAction({id: 1, status: "0"}));
    toast('Great! Info updated successfully.');
    return;
}


export { UpdateStatus };