import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import SideBar from "../../layout/sidebar/SideBar";
import BreadCrum from "../../layout";
import TextInput from "../../../common/components/TextInput";
import { OnChangeModel } from "../../../common/types/Form.types";
import { StateListAction } from "../../../redux/actions/state.actions";
import { CountryListAction } from "../../../redux/actions/country.actions";
import { getCountryListData } from "../../../redux/reducers/country.reducer";
import { countryItem } from "../../../redux/interfaces/CountryInterface";
import { getStateListData } from "../../../redux/reducers/State.reducer";
import { getCityListData } from "../../../redux/reducers/city.reducer";
import { StateItem } from "../../../redux/interfaces/StateInterface";
import { cityItem } from "../../../redux/interfaces/CityInterface";
import { CityListAction } from "../../../redux/actions/city.actions";
import { ZipcodeListAction } from "../../../redux/actions/zipcode.actions";
import { getZipcodeListData } from "../../../redux/reducers/zipcode.reducer";
import { zipcodeItem } from "../../../redux/interfaces/ZipcodeInterface";
import { updateAreaData } from "../../../redux/actions/area.action";
import SelectMulti from "../../../common/components/MultiSelect";


const AddArea = () => {

    const breadCrums = [{ link: 'areas', title: 'Areas' }];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [areaName, setAreaName] = useState("");
    const countries = useSelector(getCountryListData);
    const states = useSelector(getStateListData);
    const cities = useSelector(getCityListData);
    const zipcodes = useSelector(getZipcodeListData);
    const [countryData, setCountryData] = useState<countryItem[]>([]);
    const [state, setState] = useState<StateItem[]>([]);
    const [city, setCity] = useState<cityItem[]>([]);
    const [zipcode, setZipcode] = useState<zipcodeItem[]>([]);
    const [zipcodeId, setZipcodeId] = useState<{id:any}[]>();
    const [selected, setSelected] = useState<any>([]);
    const [countryId, setCountryId] = useState("");
    const [stateId, setStateId] = useState("");
    const [cityId, setCityId] = useState("");
    let location: any = useLocation();
    const { areaInfo } = location.state;    
    console.log('areaInfo', areaInfo)
    const [formState, setFormState] = useState({
        areaName: { error: "", value: areaInfo.area_name },
    });
    console.log('asdasodasd0asd0-a0sd-a0sd-', areaInfo)
    useEffect(() => {
        setCityId(areaInfo.city.id);
        setStateId(areaInfo.state.id);
        setCountryId(areaInfo.state.country.id);
        const selectedZipCode = areaInfo.zipcodes.map((item:any) => {
            return {
                label: item.zipcodes.locality_zipcode,
                value: item.zipcodes.id
            }
        })
        console.log("areaInfo.zipcodes ===============",  selectedZipCode)
        areaInfo.zipcodes && setSelected(selectedZipCode)
    }, [])
    useEffect(() => {
        dispatch(CountryListAction());
        return () => { };
    }, [dispatch]);


    useEffect(() => {
        let data: any = [];
        countries.map((item: any) => {
            data.push(item);
        });
        setCountryData(data);
    }, [countries]);

    useEffect(() => {
        let data: any = [];
        states.map((item: any) => {
            data.push(item);
        });
        setState(data);
    }, [states]);

    useEffect(() => {
        let data: any = [];
        zipcodes.map((item: any) => {
            data.push(item);
        });
        setZipcode(data);
    }, [zipcodes, cityId]);

    useEffect(() => {
        let payload = {
            country_id: countryId
        }
        dispatch(StateListAction(payload));
        return () => { };
    }, [dispatch, countryId]);

    useEffect(() => {
        let payload = {
            state_id: stateId
        }
        dispatch(CityListAction(payload));
        return () => { };
    }, [dispatch, stateId]);

    useEffect(() => {
        let payload = {
            page: 1
        };
        dispatch(ZipcodeListAction(payload));
        return () => { };
    }, [dispatch]);

    useEffect(() => {
        let data: any = [];
        cities.map((item: any) => {
            data.push(item);
        });
        setCity(data);
    }, [cities, stateId]);

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({
            ...formState,
            [model.field]: { error: model.error, value: model.value },
        });
        setAreaName(formState.areaName.value);
    }
    const submit = async () => {
        const payload = {
            id: areaInfo.id,
            area_name: formState.areaName.value,
            city_id: cityId,
            state_id: stateId,
            zipcode_id: zipcodeId
        };

        dispatch(updateAreaData(payload, navigate));
    };

    function isFormInvalid() {
        return formState.areaName.error || !formState.areaName.value;
    }

    function getDisabledClass(): string {
        let isError: boolean = isFormInvalid() as boolean;
        return isError ? "disabled" : "";
    }

    const countryOptions = () => {
        return countryData.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.country_name}
                </option>
            );
        });
    };

    const handleSelect = (e: any) => {
        setCountryId(e.target.value);
    };

    const handleSelectState = (e: any) => {
        setStateId(e.target.value);
    };

    const handleSelectCity = (e: any) => {
        setCityId(e.target.value);
    };

    useEffect(() => {
        const selectedItems = selected?.map((item: any) => {
            return { id: item?.value }
        })
        console.log("selected =============================", selected)
        setZipcodeId(selectedItems)
    }, [selected])

    const handleSelectZipcode = (e: any) => {
        setZipcodeId(e);
    };

    const stateOptions = () => {
        return state.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.state_name}
                </option>
            );
        });
    };

    const cityOptions = () => {

        return city.map((item: any) => {
            return (
                <option value={item.id} key={item.id}>
                    {item.city_name}
                </option>
            );
        });
    };

    const zipcodeOptions = () => {

        return zipcode.map((item: any) => {
            return {
                label: item.locality_zipcode,
                value: item.id
            }
        });
    };

    return (
        <div className="container_full">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid">
                    <BreadCrum title={"Edit Area"} page={breadCrums} />

                    <div className="row">
                        <div className=" col-md-8 offset-2">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">Edit Area Info</div>
                                </div>
                                <div className="card-body">
                                    <form
                                        className="user"
                                        action="javascript:void(0)"
                                        onSubmit={submit}
                                    >
                                        <div className="form-group">
                                            <label htmlFor="countrySelect">Select Country</label>
                                            <select
                                                className="form-control"
                                                id="countrySelect"
                                                onChange={(e) => {
                                                    handleSelect(e);
                                                }}
                                                value={countryId}
                                            >
                                                <option value={''} key={''}>Select Country</option>
                                                {countryOptions()}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="stateSelect">Select State</label>
                                            <select
                                                className="form-control"
                                                id="stateSelect"
                                                onChange={(e) => {
                                                    handleSelectState(e);
                                                }}
                                                value={stateId}
                                            >
                                                <option value={''} key={''}>Select State</option>
                                                {stateOptions()}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="citySelect">Select City</label>
                                            <select
                                                className="form-control"
                                                id="citySelect"
                                                onChange={(e) => { handleSelectCity(e); }}
                                                value={cityId}
                                            >
                                                <option value={''} key={''}>Select City</option>
                                                {cityOptions()}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <SelectMulti 
                                                labelledBy="Select Zipcode" 
                                                options={zipcodeOptions}
                                                value={selected}
                                                onChange={setSelected}
                                            />
                                            {/* <label htmlFor="zipcodeSelect">Select Zipcode</label>
                                            <select
                                                className="form-control"
                                                id="zipcodeSelect"
                                                onChange={(e) => { handleSelectZipcode(e); }}
                                                value={zipcodeId}
                                            >
                                                <option value={''} key={''}>Select Zipcode</option>
                                                {zipcodeOptions()}
                                            </select> */}
                                        </div>

                                        <div className="form-group">
                                            <TextInput
                                                id="input_area_name"
                                                field="areaName"
                                                value={formState.areaName.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Area"
                                                placeholder="Area"
                                            />
                                        </div>
                                        <button
                                            className={`btn btn-primary btn-user btn-block ${getDisabledClass()}`}
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddArea;
