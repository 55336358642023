import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../layout/sidebar/SideBar';
import BreadCrum from '../../layout';
import Status from '../../../common/components/Status';
import { Link } from 'react-router-dom'
import { DateTime } from '../../../common/components/Date';
import DataTable from 'react-data-table-component';
import { LoaderBullet } from '../../../common/components/Loader';
import { toast } from 'react-toastify';
import { getLoading } from '../../../redux/reducers/loader.reducer'
import { deleteType, updateStatus } from '../../../redux/fetchService/Status.service';
import { deleteDriverAction } from '../../../redux/actions/driver.actions';
import { getUserListData } from '../../../redux/reducers/user.reducer';
import { userItem } from '../../../redux/interfaces/UserInterface';
import { updateUserStatusAction, UserListAction } from '../../../redux/actions/user.action';
import swal from "sweetalert";
import { CSVLink } from 'react-csv';

function UsersList() {

    const dispatch = useDispatch();
    const users = useSelector(getUserListData);
    const loader = useSelector(getLoading);
    const breadCrums = [{ link: 'users', title: 'Users' }];

    const [userData, setUserData] = useState<userItem[]>([]);

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);

console.log(perPage);



    const columns: any = [
        {
            name: 'S.No.',
            selector: (row: any, id: number) => (page - 1) * perPage + (id + 1),

        },

        {
            name: 'Name',
            selector: (row: any) => <Link to={`details/${row.id}`}
                state={{ userInfo: row }}> {row.full_name}</Link>
        },
        {
            name: 'Mobile Number',
            selector: (row: any) => row.mobile_number
        },
        {
            name: 'Email',
            selector: (row: any) => row.email
        },
        {
            name: 'Address',
            selector: (row: any) => row.user_address?.city.city_name
        },
        {
            name: 'Status',
            selector: (row: any) => row.status,
            cell: (row: any) => <Status status={row.status} />
        },
        {
            name: 'Created At',
            selector: (row: any) => row.created_at,
            cell: (row: any) => <DateTime date={row.created_at} />
        },
        {
            name: 'Action',
            cell: (row: any) => {
                let id = row.id;
                return (
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            Action
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link
                                className="dropdown-item" to={`edit/${row.id}`}
                                state={{ userInfo: row }}
                            >Edit</Link>
                            <Link
                                className="dropdown-item" to={`details/${row.id}`}
                                state={{ userInfo: row }}
                            >Details</Link>
                            <a
                                className="dropdown-item"
                                onClick={() => DeleteUser({ id: id, type: "1", delete_type: "1" })}
                            >Delete</a>
                            {
                                row.status === "1" ? <a href={void (0)} className="dropdown-item"
                                    onClick={() => UpdateStatus({ id: id, type: "1", status: "0" })}>Make Inactive</a> : <a href={void (0)} className="dropdown-item"
                                        onClick={() => UpdateStatus({ id: id, type: "1", status: "1" })}>Make Active</a>
                            }

                        </div>
                    </div>
                );
            },
        },
    ];


    useEffect(() => {
        setLoading(loader);
    }, [loader]);

    const fetchData = async (page = 1) => {
        let payload = {
            page: page,
            limit: perPage
        };
        dispatch(UserListAction(payload));
    };

    const handlePageChange = (page: number) => {
        setPage(page);
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage);
        setPage(page);
        fetchData(page);
    };

    useEffect(() => {
        fetchData();
        return () => { };
    }, [dispatch, perPage]);

    useEffect(() => {
        setUserData(users.rows);
        setTotalRows(users.count);
    }, [users]);


    /**
     * @description To change country status use this function
     * @param payload 
     * @returns 
     */

    const UpdateStatus = async (payload: {}) => {
        let check = await updateStatus(payload);
        if (check.success === true) {
            dispatch(updateUserStatusAction(payload));
            toast('Great! Info updated successfully.');
            return;
        } else {
            toast('Oops! Failed to update info.');
        }
    }

    /**
     * @description To delete driver use this function
     * @param payload 
     */
    const DeleteRecord = async (payload: any) => {

        let check = await deleteType(payload);

        if (check) {
            dispatch(deleteDriverAction(payload));
            fetchData();
        } else {
            toast('Oops! Failed to delete, please try again.');
        }
    }
    const DeleteUser = (payload: {}) => {
        swal("Are you sure?", "You want to delete user!", "warning", {
            buttons: ["CANCEL", "DELETE"],
            dangerMode: true,
        }).then((okay) => {
            if (okay) {
                DeleteRecord(payload);
            }
        });
    };
    return (
        <div className="container_full tbcont1-main">
            <div className="side_bar scroll_auto">
                <SideBar />
            </div>
            <div className="content_wrapper">
                <div className="container-fluid mb-5">
                    <BreadCrum title={'Users'} page={breadCrums} />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CSVLink data={userData} filename={'users.csv'}>Download CSV</CSVLink>
                        <div className='text-right mb-2'>
                            <Link className="btn btn-primary" to={'add'} >Add New User</Link>
                        </div>
                    </div>
                    <DataTable
                        title="Users"
                        columns={columns}
                        data={userData}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationRowsPerPageOptions={[10, 50, 100, 500]}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressComponent={<LoaderBullet />}
                    />
                </div>
            </div>
        </div>
    )
}

export default UsersList